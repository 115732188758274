import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    serialNoPosition: {
        marginTop: "2px",
    },
}));
const filter = createFilterOptions();

const SerialNumber = ({ field, product, serialNumbers, required, disable, onUpdate, ...props }) => {
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");

    const classes = useStyles();

    useEffect(() => {
        if (serialNumbers && serialNumbers.length > 0) {
            setVisible(true);
        }
    }, [serialNumbers]);

    useEffect(() => {
        setShow(Boolean(product.serialNo));
        setSerialNumber(product.serialNo);
    }, [product.serialNo]);

    const handleChange = () => {
        setShow(prev => {
			product.REQBYDATE = product.iREQBYDATE;
			product.REQBYTIME = product.iREQBYTIME;
			product.serialNo = "";
			product.MINREQBYDATE = product.iREQBYDATE;
            //if (prev) {
            setSerialNumber("");
			onUpdate(product);
            //}
            return !prev;
        });
    }

	const updateSerialNumber = (event, value) => {
		if (value && value.serialNumber) {
			setSerialNumber(value.serialNumber);
			product.serialNo = value.serialNumber;
			product.MINREQBYDATE = product.REQSTARTDATE;
		} else {
			product.REQBYDATE = product.iREQBYDATE;
			product.REQBYTIME = product.iREQBYTIME;
			product.MINREQBYDATE = product.iREQBYDATE;
		}
		onUpdate(product);
		
    }

    return visible && (
        <Grid
            container
            direction="row"
            item
            xs={12}
        >
            <Grid
                item
                xs={3}
            >
                <Tooltip                  
                    title={
                        <Typography
                            variant="body2"
                        >
                            {show ? "Clear serial number selection" : "Select serial number from consignment"}
                        </Typography>
                    }
                >
                <Switch
                    color="default"
                    checked={show}
                    onChange={handleChange}
                />
                </Tooltip>
            </Grid>

            <Grid
                item
                className={classes.serialNoPosition}
                xs={9}
            >
                {Boolean(show) && (
                    <Autocomplete
                        options={serialNumbers}
                        getOptionLabel={(option) => {
                            if (typeof option === "object") {
                                return option.serialNumber + " (" + option.storeCode + ")";
                            }
                            return option;
                        }}
                        defaultValue={product.serialNo && serialNumbers.find(x => x.serialNumber == product.serialNo)}
                        onChange={updateSerialNumber}
                        getOptionSelected={(option, value) => option.serialNumber === value }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                type="text"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disable={disable}
                                required={required}
                                error={Boolean(required && !product.serialNo)}
                                
                                value={SerialNumber}
                                focused
                            />
                        )}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default SerialNumber;