import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography } from '@material-ui/core';
import uuid from "uuid-random";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import shim from '../../assets/1x1.gif'; 

const useStyles = makeStyles((theme) => ({
    card: {
        borderTop: "0px solid " + theme.palette.primary.light,
        marginTop: "1.5rem",
        height: "100%",
        width: "100%",
    },
    cardContent: {
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        padding: '0',
    },
    cardContainer: {
        padding: '0',
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '350px',
        height: '100%',
    },
    pictureContainer: {
        textAlign: 'center',
        verticalAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    picture: {
        display: 'flex',
        width: 'auto',
        height: '100%',
        minHeight: '300px',
        maxHeight: '300px',
        verticalAlign: 'middle',
    },
    cardFooter: {
        backgroundColor: "#016895",
        color: '#FFFFFF',
        padding: '10px',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
    },
    cardTitle: {
        color: '#FFFFFF',
        fontSize: '15pt',
        fontWeight: '500',
    },
    cardDescription: {
        color: '#FFFFFF',
    },
    cardLink: {
        color: '#26A9E1',
        textDecoration: 'none',
        fontSize: '9pt',
        cursor: 'pointer',
        fontWeight: '500',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-end',
    },
    learnMore: {

    }
}));

const Promotion = ({ promotion }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            item
            alignItems="stretch"
        >
            {Boolean(promotion.title) && (
                <Card
                    square
                    className={classes.card}
                    elevation={0}

                >
                    

                    <CardContent
                        className={classes.cardContent}
                    >
                        <Grid
                            container
                            direction="column"
                            className={classes.cardContainer}
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                                className={classes.pictureContainer}
                            >
                                {promotion.picture !== "data:image/jpeg;base64," && (
                                    <CardMedia
                                        key={uuid()}
                                        component="img"
                                        image={promotion.picture}
                                        className={classes.picture}
                                    />
                                )}
                                {promotion.picture == "data:image/jpeg;base64," && (
                                    <CardMedia
                                        key={uuid()}
                                        component="img"
                                        image={shim}
                                        className={classes.picture}
                                    />
                                )}
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                className={classes.cardFooter}
                                spacing={1}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.cardTitle}
                                >
                                    {promotion.title}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.cardDescription}
                                >
                                    {promotion.text.map((text) => (
                                        <Typography
                                            key={uuid()}
                                            component="p"
                                            variant="body2"

                                        >
                                            {text}
                                        </Typography>
                                    ))}
                                </Grid>
                                {Boolean(promotion.hyperLink) && (
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.cardLink}
                                        onClick={() => window.open(promotion.hyperLink, "_blank")}
                                    >
                                        <Grid
                                            className={classes.learnMore}
                                            >
                                            + LEARN MORE
                                            </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Grid>
    )
}

export default Promotion;