import { useEffect, useState } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";

import Popover from "../../components/Popover";
import FieldGroup from "../../components/controls/FieldGroup";

const useStyles = makeStyles((theme) => ({}));

const ConfirmHighEnd = ({ show, onExited, message }) => {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState("");
    const classes = useStyles();

    useEffect(() => {
        setOpen(show);
    }, [show])

    const handleCancel = () => {
        setStatus("cancelled");
        setOpen(false);
    }

    const handleApprove = () => {
        setStatus("approved");
        setOpen(false);
    }

    const handleExited = () => {
        if (status === "cancelled") {
            onExited(status);
        }

        if (status === "not approved") {
            onExited(status);
        }

        if (status === "approved") {
            onExited(status);
        }
    }

    return (
        <Popover
            open={open}
            showTitle={true}
            onExited={handleExited}
            ignoreWidth
        >
            <Grid
                container
                spacing={1}
            >
                <FieldGroup
                    fields={[{ columnCaption: "Confirm High End Equipment Ordered" }]}
                    xs={12}
                    classes={classes}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography 
                                variant="body2"
                                color="secondary"
                            >
                                {message}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        />
                    </Grid> 


                    <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleApprove}
                        >
                            Accept
                        </Button>
                        <Button
                            onClick={handleCancel}
                            style={{ marginLeft: "auto" }}

                        >
                            Cancel
                        </Button>
                    </Grid>
                </FieldGroup>


                <Grid item xs={12} />
            </Grid>
        </Popover>
    )
}

export default ConfirmHighEnd;