import { Grid, makeStyles } from "@material-ui/core";
import PopoverMUI from "@material-ui/core/Popover";

import logo from "../assets/PEGASUS_PORTAL_LOGOS-01.png";
import usePopoverStyles, { usePopoverStylesWithScrollbar, usePopoverStylesWithNoWidth } from "./popover-styles";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#DDEAF0',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 10px',
    },
    title: {
        color: '#000000',
        height: '100px',
        width: '100%',
    },
    titleLogo: {
        width: '100%',
    },
    phoneNumber: {
        fontSize: '25pt',
        color: '#000000',
        fontWeight: '700',
    },
}));
const Popover = ({ onExited, onEscapeKeyDown, showTitle, children, showScrollbar, tall, ignoreWidth, ...props }) => {
    const styleWithoutScrollbars = usePopoverStyles();
    const styleWithScrollbars = usePopoverStylesWithScrollbar();
    const styleWithNoWidth = usePopoverStylesWithNoWidth();
    const classes = ignoreWidth ? styleWithNoWidth : showScrollbar ? styleWithScrollbars : styleWithoutScrollbars;
    const localClasses = useStyles();
    return (
        <PopoverMUI
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                marginTop: '50px',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            PaperProps={{
                style: { height: '100%' },
            }}
            transitionDuration="auto"
            disableBackdropClick={true}
            disableEscapeKeyDown={false}
            className={classes.root}
            BackdropProps={{
                classes: {
                    root: classes.backDrop,
                }
            }}
            onExited={onExited}
            onEscapeKeyDown={onEscapeKeyDown}
            {...props}
        >
            <Grid 
                container
                alignItems="flex-start"
                className={tall ? classes.contentTall : classes.content}
                style={(ignoreWidth ? { width: "min-width" } : {}, { height: '100%', alignContent: 'flex-start', flexFlow: 'column nowrap', backgroundColor: '#DDEAF0' })}
            >
                {showTitle && (
                    <Grid
                        container
                        direction="column"
                        className={localClasses.header}
                    >
                        <Grid
                            item
                            className={classes.title}
                            >
                            <img alt="Pegasus Health Group" height="80" className={classes.titleLogo} src={logo} />
                        </Grid>
                        <Grid
                            item
                            className={localClasses.phoneNumber}
                        >
                        </Grid>
                    </Grid>
                )}
                {children}
            </Grid>
        </PopoverMUI>
    )
}

export default Popover;