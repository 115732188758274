import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import useRedirect from '../../hooks/useRedirect';
import { Context } from '../../Portal';

const useStyles = makeStyles((theme) => ({}));

const Actions = ({ rows, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const { redirectToCreateRentalOrder } = useRedirect();

    const createRentalOrder = () => {
        dispatch({ type: "CONSIGNMENT-PRODUCTS-SELECTED", payload: { products: rows, customer: state.selectedCustomer } })
        redirectToCreateRentalOrder();
    }

    return (
        <Grid
            container
            direction="row"
        >
            <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "1em" }}
                onClick={createRentalOrder}
                disabled={rows.length === 0}
            >
                Create Rental Order
            </Button>
        </Grid>
    )
}

export default Actions;