import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTableCell } from './table-styling';
import { TableRow } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: "center",
        fontSize: "larger ",
        color: '#FFFFFF',
    },
}));

const FromToTitleRow = () => {
    const classes = useStyles();

    return (
        <TableRow>
            <StyledTableCell
                colSpan={2}
                className={classes.title}
            >
                From
            </StyledTableCell>
            <StyledTableCell
                colSpan={2}
                className={classes.title}
            >
                To
            </StyledTableCell>
            <StyledTableCell/>
        </TableRow>
    )
}

export default FromToTitleRow;