import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";

const BigtextField = ({ label, required, name, xs, maxlength, value: passedValue, ...rest }) => {
    const { control } = useFormContext();

    useEffect(() => {
        control.setValue(name, passedValue)
    }, [passedValue])

    return (
        <Controller
            control={control}
            name={name}
            label={label}
            defaultValue=""
            rules={{ required: Boolean(required) }}
            render={({ onChange, value }) => {
                return (
                    <Grid item xs={xs ? xs : 3}>
                        <TextField
                            name={name}
                            label={label}
                            fullWidth
                            multiline
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{ maxlength: maxlength > 0 ? maxlength : '' }}
                            required={Boolean(required)}
                            error={Boolean(required && !value)}
                            onChange={onChange}
                            value={value ? value : passedValue}
                            {...rest}
                        />
                    </Grid>
                )
            }}
        >

        </Controller>
    )
}

export default BigtextField;