import React, { useContext, useEffect, useState } from 'react';
import "date-fns";
import add from "date-fns/add";
import sub from "date-fns/sub";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parse from 'date-fns/parse';
import DateFNSUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Table, TableBody, TableContainer, TableRow, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Popover from '../../components/Popover';
import { LabelCell, StyledTableCell, StyledTableHeader, StyledTableRow } from './table-styling';
import TitleRow from './TitleRow';
import FromToTitleRow from './FromToTitleRow';
import DisabledTextField from './DisabledTextField';
import useSetTransferQuestions from "../../hooks/useSetTransferQuestions";
import { api, Context } from '../../Portal';
import NameSelectEntry from '../../components/controls/NameSelectEntry';
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";
import DropdownField from "../../components/controls/DropdownField";
import InputField from "../../components/controls/InputField"; 

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 24,
        padding: 24,
        ...theme.body,
        backgroundColor: 'transparent',
        border: '0',
    },
    table: {
        border: "0px solid " + theme.palette.primary.dark,
        marginBottom: 0,
    },
    tableRow: {
        background: "#fff",
    },
    buttonSpacing: {
        marginLeft: 14,
    },
    cancelSpacing: {
        marginLeft: 42,
    },
    required: {
        color: "red",
    },
    requiredBorder: {
        border: "1px solid red",
    },
    requiredSelectBorder: {
        "& > div > div": {
            border: "1px solid red",
        },
    },
    default: {
        color: theme.palette.text.primary
    },
    defaultBorder: {
        border: "1px solid rgb(118, 118, 118)"
    },
    defaultSelectBorder: {
        "& > div > div": {
            border: "1px solid rgba(0, 0, 0, 0.23)"
        },
    },
    tableHeader: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        fontSize: '16pt',
        lineHeight: '24pt',
    },
    tableTitleRow: {
        color: '#FFFFFF',
    },
    okButton: {
        width: '100%',
    },
    cancelButton: {
        backgroundColor: '#AEAEAE',
        color: '#2E2E2E',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#707070',
            color: '#FFFFFF',
        }
    },
    defaultBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
        "& input:disabled fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(1, 104, 149, 1)",
        },
        '& hover': {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
    },
    disabledBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(0, 0, 0, .5)",
        },
        '& hover': {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
    },
    tableCellStyle: {
        marginLeft: "-4px",
        display: "block",
    },
    datePicker: {
        width: "300px",
    },
    hideRow: {
        display: "none",
    },
    radioGroup: {
        flexFlow: 'row',
    }
}));

const url = "/api/v1/equipment/transfer";
const altUrl = "/api/v1/equipment/transferandstop";
const wardsUrl = "/api/v1/wards";

const TransferRow = ({ row }) => {
    return (
        <StyledTableRow>
            <StyledTableCell>{row.customer}</StyledTableCell>
            <StyledTableCell>{row.ward}</StyledTableCell>
            <StyledTableCell>{row.patientFirstName}</StyledTableCell>
            <StyledTableCell>{row.patientSurname}</StyledTableCell>
            <StyledTableCell>{row.patientNo}</StyledTableCell>
            <StyledTableCell>{row.startDate}</StyledTableCell>
            <StyledTableCell>{row.bed}</StyledTableCell>
            <StyledTableCell>{row.model}</StyledTableCell>
            <StyledTableCell>{row.serialNo}</StyledTableCell>
        </StyledTableRow>
    )
}

const TransferRental = ({ rows, show, onExited, onEscapeKeyDown, options, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const [currentRow, setCurrentRow] = useState({});
    const [waitingRows, setWaitingRows] = useState([]);
    const [details, completed, onAnswered, reset] = useSetTransferQuestions();
    const [wards, setWards] = useState([]);
    const [defaultWard, setDefaultWard] = useState({});
    const [staff, setStaff] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState({ approved: false, declined: false });
    const classes = useStyles();

    useEffect(() => {
        if (!show) {
            return;
        }

        let [firstRow, ...remainingRows] = rows;
        firstRow.daysSinceStart = differenceInCalendarDays(Date.now(), parse(firstRow.startDate, "dd/MM/yyyy", new Date()));

        setCurrentRow(firstRow);

        details.newBed = firstRow.bed;
        details.newPatientFirstName = firstRow.patientFirstName;
        details.newPatientSurname = firstRow.patientSurname;
        details.newPatientNo = firstRow.patientNo;

        onAnswered({
            currentWard: firstRow.ward
        })

        if (!options) {
            onAnswered({
                option: 'N/A',
            })
        }

        setWaitingRows(remainingRows);

        api.get(state.host + wardsUrl + `?remove=`, state.credentials)
            .then(({ data }) => {
                var tempWards = [];
                if (data.length > 0) {
                    tempWards.push(...data);
                }
                setWards(tempWards);
                if (state.selectedCustomer !== '' && state.selectedWard !== '') {
                    state.user.selectedCustomer = state.selectedCustomer;
                    state.user.selectedWard = state.selectedWard;
                    onAnswered({
                        newWard: state.user.selectedWard,
                    })
                } else {
                    onAnswered({
                        newWard: wards[0].name ? wards[0].name : '',
                    });
                }
            })
        api.get(state.host + wardsUrl + `/${firstRow.ward}/staff`, { ...state.credentials, headers: { ...state.credentials.headers, "acl-ward": firstRow.ward } })
            .then(({ data }) => {
                setStaff(data);
            })
    }, [rows, show])

    const showNewRows = (details) => {
        return true;
    }

    const showNewRowsRequired = (details) => {
        return true;
    }

    const handleOkClicked = () => {
        let equipmentDetails = {
            RENTALORDERNO: currentRow.rentalOrderNo,
            RENTALLINENO: currentRow.rentalLineNo,
            SERIALNO: currentRow.serialNo,
            NEWWARD: details.newWard,
            NEWBED: details.newBed,
            NEWPATIENTFIRSTNAME: details.newPatientFirstName,
            NEWPATIENTSURNAME: details.newPatientSurname,
            NEWPATIENTNO: details.newPatientNo,
            TRANSFERDATE: new Date(details.dateOfTransfer).toISOString().substring(0, 10),
            CONTACTFIRSTNAMES: details.contact.firstNames,
            CONTACTSURNAME: details.contact.surname,
            CUSTOMER: currentRow.Customer,
            WARD: currentRow.ward
        };

        if (currentRow.option === 'Stop') {
            api.post(state.host + altUrl, equipmentDetails, state.credentials)
                .then(({ data }) => {
                    if (data.success) {
                        onOk();
                    }
                });
        } else {
            api.post(state.host + url, equipmentDetails, state.credentials)
                .then(({ data }) => {
                    if (data.success) {
                        onOk();
                    }
                });
        }
    }

    const handleCancelClicked = () => {
        setShowConfirmation({ approved: false, declined: true });
    }

    const onOk = () => {
        let previousRow = currentRow;
        let previousDetails = details;

        let [firstRow, ...remainingRows] = waitingRows;

        if (!firstRow) {
            onExited();
            return;
        }

        reset();
        onAnswered({
            currentWard: firstRow.ward
        })
        setCurrentRow(firstRow);
			onAnswered({
				dateOfTransfer: firstRow.dateOfTransfer,
				newWard: defaultWard.name ? defaultWard.name : '',
				newBed: firstRow.bed,
				newPatientFirstName: firstRow.patientFirstName,
				newPatientSurname: firstRow.patientSurname,
				newPatientNo: firstRow.patientNo,
				option: '',
				contact: '',
			});
        if (!options) {
            onAnswered({
                option: 'N/A',
            })
        }
        setWaitingRows(remainingRows);
    }

    return show && Boolean(currentRow) && (
        <Popover
            open={show}
            showTitle={true}
            onExited={onExited}
            onEscapeKeyDown={onEscapeKeyDown}
            showScrollbar
        >
            <TableContainer
                variant="outlined"
                component={Paper}
                className={classes.paper}
            >
                <Table
                    size="small"
                    className={classes.table}
                >
                    <StyledTableHeader>
                        <TitleRow
                            colSpan={5}
                            className={classes.tableHeader}
                        >
                            Product Transfer
                        </TitleRow>
                        <FromToTitleRow />
                    </StyledTableHeader>

                    <TableBody>
                        <StyledTableRow>
                            <LabelCell width={180}>Model</LabelCell>
                            <DisabledTextField value={currentRow.model} fourColumn width={300} />
                            <StyledTableCell width={180} />
                            <StyledTableCell width={300} />
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Serial No</LabelCell>
                            <DisabledTextField value={currentRow.serialNo} fourColumn />
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Ward</LabelCell>
                            <DisabledTextField value={currentRow.ward} fourColumn />
                            <LabelCell className={Boolean(details.newWard) ? classes.default : classes.required}>Ward</LabelCell>
                            <StyledTableCell className={classes.tableCellStyle}>{ wards.length > 0 &&
                                <DropdownField
                                    name={"ward"}
                                    label={""}
                                    required={true}
                                    value={details.newWard}
                                    disabled={false}
                                    options={
                                        Boolean(wards.length > 0) ? wards.map(({ name }, index) => ({ key: `$ward-${index}`, value: name, description: name })) : { key: 'ward-0', value: '' }}
                                    className={classes.defaultBorder}
                                    onChange={(event) => { onAnswered({ newWard: event.target.value }); }}
                                />
                            }
                            </StyledTableCell>
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Bed</LabelCell>
                            <DisabledTextField value={currentRow.bed} fourColumn />
                            {showNewRows(details) && (
                                <>
                                    <LabelCell className={Boolean(showNewRowsRequired(details) && details.newBed !== "" || !showNewRowsRequired(details)) ? classes.default : classes.required}>Bed</LabelCell>
                                    <InputField
                                        name={"bed"}
                                        label={""}
                                        type="text"
                                        required={showNewRowsRequired(details)}
                                        onUpdate={(event) => { onAnswered({ newBed: event.target.value }); }}
                                        className={classes.defaultBorder} value={details.newBed} fourColumn />
                                </>
                            )}
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Patient First Name</LabelCell>
                            <DisabledTextField value={currentRow.patientFirstName} fourColumn />
                            {showNewRows(details) && (
                                <>
                                    <LabelCell className={Boolean(showNewRowsRequired(details) && details.newPatientFirstName !== "" || !showNewRowsRequired(details)) ? classes.default : classes.required}>Patient First Name</LabelCell>
                                    <InputField
                                        name={"patientFirstName"}
                                        label={""}
                                        type="text"
                                        required={showNewRowsRequired(details)}
                                        onUpdate={(event) => { onAnswered({ newPatientFirstName: event.target.value }); }}
                                        className={classes.defaultBorder} value={details.newPatientFirstName} fourColumn />
                                </>
                            )}
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Patient Surname</LabelCell>
                            <DisabledTextField value={currentRow.patientSurname} fourColumn />
                            {showNewRows(details) && (
                                <>
                                    <LabelCell className={Boolean(showNewRowsRequired(details) && details.newPatientSurname !== "" || !showNewRowsRequired(details)) ? classes.default : classes.required}>Patient Surname</LabelCell>
                                    <InputField
                                        name={"patientSurname"}
                                        label={""}
                                        type="text"
                                        required={showNewRowsRequired(details)}
                                        onUpdate={(event) => { onAnswered({ newPatientSurname: event.target.value }); }}
                                        className={classes.defaultBorder} value={details.newPatientSurname} fourColumn />
                                </>
                            )}
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Patient No</LabelCell>
                            <DisabledTextField value={currentRow.patientNo} fourColumn />
                            {showNewRows(details) && (
                                <>
                                    <LabelCell className={Boolean(showNewRowsRequired(details) && details.newPatientNo !== "" || !showNewRowsRequired(details)) ? classes.default : classes.required}>Patient No</LabelCell>
                                    <InputField
                                        name={"patientNo"}
                                        label={""}
                                        type="text"
                                        maxlength={30}
                                        required={showNewRowsRequired(details)}
                                        onUpdate={(event) => { onAnswered({ newPatientNo: event.target.value }); }}
                                            className={classes.defaultBorder} value={details.newPatientNo} fourColumn />
                                </>
                            )}
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell>Rental Start</LabelCell>
                            <DisabledTextField value={currentRow.startDate} fourColumn />
                            <LabelCell className={Boolean(details.dateOfTransfer) ? classes.default : classes.required}>Transfer Date</LabelCell>
                            <StyledTableCell>
                                <MuiPickersUtilsProvider utils={DateFNSUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        value={details.dateOfTransfer}
                                        disableToolbar
                                        className={classes.datePicker}
                                        autoOk
                                        required
                                        maxDate={add(Date.now(), { days: 1 })}
                                        minDate={sub(Date.now(), { days: currentRow.daysSinceStart > 30 ? 30 : currentRow.daysSinceStart })}
                                        inputVariant="outlined"
                                        onChange={(date) => onAnswered({ dateOfTransfer: date })}
                                        minDateMessage={"Date should not be before " + sub(Date.now(), { days: currentRow.daysSinceStart > 30 ? 30 : currentRow.daysSinceStart }).toDateString('dd/MM/YYYY')}
                                    />
                                </MuiPickersUtilsProvider>
                            </StyledTableCell>
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell className={Boolean(details.contact.name) ? classes.default : classes.required}>Contact Person</LabelCell>
                            <StyledTableCell>
                                <NameSelectEntry
                                    people={staff}
                                    label={"Contact Person"}
                                    hideLabel
                                    name={"contact"}
                                    value={details.contact}
                                    required
                                    onChange={(name) => onAnswered({ contact: name })}
                                    className={classes.defaultBorder}
                                    xs={12}
                                />
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        {options &&
                            <StyledTableRow>
                                <LabelCell className={Boolean(details.option) ? classes.default : classes.required}></LabelCell>
                                <StyledTableCell colSpan={5}>
                                    <RadioGroup className={classes.radioGroup} value={details.option} onChange={(event) => onAnswered({ option: event.target.value })}>
                                        <FormControlLabel value={"Continue"} control={
                                            <Radio
                                                size="small"
                                                className={Boolean(details.option) ? classes.defaultRadio : classes.required}
                                            />
                                        } label="Continue Renting" />
                                        <FormControlLabel value={"Stop"} control={
                                            <Radio
                                                size="small"
                                                className={Boolean(details.option) ? classes.defaultRadio : classes.required}
                                            />
                                        } label="Stop Rental" />
                                    </RadioGroup>
                                </StyledTableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>

                <Grid
                    container
                >
                    <Grid
                        item
                        xs={6}
                        >
                    <Button
                        disabled={!completed}
                            onClick={handleOkClicked}
                            className={classes.okButton}
                    >
                            Ok
                    </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                    <Button

                        className={classes.cancelButton}
                        onClick={handleCancelClicked}
                    >
                            Cancel
                    </Button>
                    </Grid>
                    <ConfirmationDialog
                        open={showConfirmation.approved}
                        onSubmit={onOk}
                        title={"TRANSFER ACTIONED"}
                        showOkButton={true}
                        okButtonText={"OK"}
                        showCancelButton={false}
                        cancelButtonText={""}
                        variant={"info"}
                        description={"Transfer has been actioned."}
                    />
                    <ConfirmationDialog
                        open={showConfirmation.declined}
                        onSubmit={onOk}
                        title={"TRANSFER CANCELLED"}
                        showOkButton={true}
                        okButtonText={"OK"}
                        showCancelButton={false}
                        cancelButtonText={""}
                        variant={"info"}
                        description={"Transfer has been cancelled."}
                    />

                </Grid>

            </TableContainer>

            {waitingRows.length > 0 && (
                <TableContainer
                    variant="outlined"
                    component={Paper}
                    className={classes.paper}
                >
                    <Table
                        size="small"
                        className={classes.table}
                    >
                        <StyledTableHeader>
                            <TitleRow colSpan={8}>
                                Pending Transfers Out
                            </TitleRow>
                            <TableRow>
                                <StyledTableCell>Customer</StyledTableCell>
                                <StyledTableCell>Ward</StyledTableCell>
                                <StyledTableCell>Patient</StyledTableCell>
                                <StyledTableCell>Patient No</StyledTableCell>
                                <StyledTableCell>Rental Start</StyledTableCell>
                                <StyledTableCell>Bed</StyledTableCell>
                                <StyledTableCell>Model</StyledTableCell>
                                <StyledTableCell>Serial No</StyledTableCell>
                            </TableRow>
                        </StyledTableHeader>

                        <TableBody>
                            {waitingRows.map(row => (
                                <TransferRow row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Popover>
    )
}

export default TransferRental;