import { useContext, useEffect } from "react";
import { useState } from "react";

import { api, Context } from "../../Portal";
import { Chip } from "@material-ui/core";
import BaseDataTable from "../../components/BaseDataTable";
import useRedirect from '../../hooks/useRedirect';
import { PermMedia } from "@material-ui/icons";


const url = "/api/v1/rentalorders/waiting-approval";

const ViewAuthorisations = () => {
    const [state, dispatch] = useContext(Context);
    const [rows, setRows] = useState([]);
    const [cells, setCells] = useState([]);
    const { redirectToApproval } = useRedirect();

    useEffect(() => {
        buildCells();
        get();
    }, []);

    const buildCells = () => {
        setCells([
            {
                name: 'Type',
                selector: 'approveTransactionType',
                sortable: true,
                cell: (row) => {
                    return (
                        <Chip
                            label={row.approveTransactionType}
                            size="small"
                            style={{ backgroundColor: "#B4E8D6", color: "#216B51" }}
                            onClick={() => handleRowClicked(row)}
                        />
                    )
                },
            },
            {
                name: 'Customer',
                selector: 'customer',
                sortable: true,
            },
            {
                name: 'Ward',
                selector: 'ward',
                sortable: true,
            },
            {
                name: 'Rental Order',
                selector: 'rentalOrderNo',
                sortable: true,
            },
            {
                name: 'Cost Center',
                selector: 'costCentreCode',
                sortable: true,
            },
            {
                name: 'Patient',
                selector: 'patientName',
                sortable: true,
            },
            {
                name: 'Patient No',
                selector: 'patientNo',
                sortable: true,
            },
            {
                name: 'Requested On',
                selector: 'creationDate',
                sortable: true,
            },
            {
                name: 'Bed',
                selector: 'bed',
                sortable: true,
            },
            {
                name: 'Contact',
                selector: 'contactPerson',
                sortable: true,
            },
            {
                name: 'Requesting Nurse',
                selector: 'requestingNurse',
                sortable: true,
            },
            {
                name: 'Delivery',
                selector: 'shipTo',
                sortable: true,
                cell: row => {
                    return (
                        <>
                            {`${row.deliveryAddress}, ${row.deliveryCity}`}
                        </>
                    )
                }
            },
        ]);
    }

    const get = () => {
        api.get(state.host + url, state.credentials)
            .then(({ data }) => {
                setRows(data.headers);
                if (data.headers.length !== state.authorisationCount) {
                    dispatch({ type: "UPDATE-AUTHORISATION-COUNT", payload: { authorisationCount: data.headers.length } });
                }
            });
    }

    const handleRowClicked = (row) => {
        let customerNo = state.user.customers.find(customer => customer.name === row.customer);

        dispatch({ type: "APPROVE-RENTAL", payload: { ...row } })
        dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: customerNo.no } })
        dispatch({ type: "WARD-SELECTED", payload: { selectedWard: row.ward } })

        redirectToApproval(row.approveTransactionType);
    }

    return (
        <BaseDataTable
            cells={cells}
            rows={rows}
            onRowClicked={handleRowClicked}
            selectableRows={false}
        />
    )
}

export default ViewAuthorisations;