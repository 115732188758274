import React, { useContext, useEffect, useState } from 'react';
import "date-fns";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, CardMedia, Paper, Table, TableBody, TableContainer } from '@material-ui/core';

import Popover from '../../components/Popover';
import { StyledTableCell, StyledTableHeader, StyledTableRow } from './table-styling';
import TitleRow from './TitleRow';
import { api, Context } from '../../Portal';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 24,
        padding: 24,
        ...theme.body,
        backgroundColor: 'transparent',
        border: '0',
    },
    table: {
        border: "0px solid " + theme.palette.primary.dark,
        marginBottom: 0,
    },
    tableRow: {
        background: "#fff",
    },
    buttonSpacing: {
        marginLeft: 14,
    },
    cancelSpacing: {
        marginLeft: 42,
    },
    required: {
        color: "red",
    },
    requiredBorder: {
        border: "1px solid red",
    },
    requiredSelectBorder: {
        "& > div > div": {
            border: "1px solid red",
        },
    },
    default: {
        color: theme.palette.text.primary
    },
    defaultBorder: {
        border: "1px solid rgb(118, 118, 118)"
    },
    defaultSelectBorder: {
        "& > div > div": {
            border: "1px solid rgba(0, 0, 0, 0.23)"
        },
    },
    tableHeader: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        fontSize: '16pt',
        lineHeight: '24pt',
    },
    tableTitleRow: {
        color: '#FFFFFF',
    },
    okButton: {
        width: '100%',
    },
    cancelButton: {
        backgroundColor: '#AEAEAE',
        color: '#2E2E2E',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        '&:hover': {
            backgroundColor: '#707070',
            color: '#FFFFFF',
        }
    },
    defaultBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
        "& input:disabled fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(1, 104, 149, 1)",
        },
        '& hover': {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
    },
    disabledBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(0, 0, 0, .5)",
        },
        '& hover': {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
    },
    tableCellLabelStyle: {
        textAlign: 'right',
        width: '300px',
        fontWeight: '900',
        float: 'left',
    },
    tableCellStyle: {
        marginLeft: "-4px",
        display: "block",
    },
    tableCellValueStyle: {
        textAlign: 'left',
        width: '300px',
        fontWeight: '500',
        float: 'left',
    },
    datePicker: {
        width: "300px",
    },
    hideRow: {
        display: "none",
    },
    signatureCell: {
        width: "200px",
        height: "auto",
        border: '1px solid #000000',
        padding: '0px !important',
    },
    buttonRow: {
        backgroundColor: '#ffffff',
        paddingTop: '10px',
    },
}));

const url = "/api/v1/equipment/orderdetails";

const RentalDetails = ({ row, show, onExited, onEscapeKeyDown, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const [currentRow, setCurrentRow] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (!show) {
            return;
        }

        api.get(state.host + `${url}/${row.rentalOrderNo}`, state.credentials)
            .then(({ data }) => {
                setCurrentRow(data[0]);
            })
    }, [row, show])

    const sizeOfBase64String = (base64String) => {
        if (!base64String) return 0;
        const padding = (base64String.match(/(=*)$/) || [])[1].length;
        return 4 * Math.ceil((base64String.length / 3)) - padding;
    }


    const handleOkClicked = () => {
        setCurrentRow(null);
        onExited(false);
    }

    return show && Boolean(currentRow) && (
        <Popover
            open={show}
            showTitle={true}
            onExited={onExited}
            onEscapeKeyDown={onEscapeKeyDown}
            showScrollbar
        >
            <TableContainer
                variant="outlined"
                component={Paper}
                className={classes.paper}
            >
                <Table
                    size="small"
                    className={classes.table}
                >
                    <StyledTableHeader>
                        <TitleRow
                            colSpan={5}
                            className={classes.tableHeader}
                        >
                            Rental Details
                        </TitleRow>
                    </StyledTableHeader>

                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Customer Name</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.customerName}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Customer No</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.customerNo}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Delivery Address</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{(currentRow.deliveryAddress.length > 0 ? currentRow.deliveryAddress + ", " : "") + (currentRow.deliveryAddress2.length > 0 ? currentRow.deliveryAddress2 + ", " : "") + (currentRow.deliveryCity.length > 0 ? currentRow.deliveryCity + ", " : "") + (currentRow.deliveryPostCode.length > 0 ? currentRow.deliveryPostCode + ", " : "") + (currentRow.deliveryCounty.length > 0 ? currentRow.deliveryCounty + ", " : "") + currentRow.deliveryCountryRegionCode}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Delivery By</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.deliveryBy}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Delivery By Name</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.deliveryByName}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Creation Date & Time</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.creationDateTime}</StyledTableCell>
												</StyledTableRow>
												<StyledTableRow>
													<StyledTableCell className={classes.tableCellLabelStyle}>Delivery Date & Time</StyledTableCell>
													<StyledTableCell className={classes.tableCellValueStyle}>{currentRow.deliveryDateTime}</StyledTableCell>
												</StyledTableRow>
												<StyledTableRow>
													<StyledTableCell className={classes.tableCellLabelStyle}>Stopped Date & Time</StyledTableCell>
													<StyledTableCell className={classes.tableCellValueStyle}>{currentRow.stoppedDateTime}</StyledTableCell>
												</StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Delivery EMail</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.deliveryEMail}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Delivery Phone No</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.deliveryPhoneNo}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>No</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.no}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Rental Order Line No</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.rentalOrderLineNo}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Rental Order No</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.rentalOrderNo}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Ship To Code</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.shipToCode}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Ship To Name</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.shipToName}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Ward</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.ward}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>

                <Table
                    size="small"
                    className={classes.table}
                >
                    <StyledTableHeader>
                        <TitleRow
                            colSpan={5}
                            className={classes.tableHeader}
                        >
                            Job Proof
                        </TitleRow>
                    </StyledTableHeader>

                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Delivered</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.deliveryDateTime}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Signee</StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{currentRow.signedBy}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell className={classes.tableCellLabelStyle}>Signature </StyledTableCell>
                            <StyledTableCell className={classes.tableCellValueStyle}>{sizeOfBase64String(currentRow.signaturePOD[0]) > 0 &&
                                <div>
                                <div>Signature is recorded ({sizeOfBase64String(currentRow.signaturePOD[0])} bytes)</div>
                                <div className={sizeOfBase64String(currentRow.signaturePOD[0]) > 0 ? classes.signatureCell : ''}>
                                    <CardMedia
                                    component="img"
                                    image={"data:image/png;base64," + currentRow.signaturePOD[0]}
                                    title={currentRow.signedBy}
                                    />
                                </div>
                                </div>}
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>

                <Grid
                    container
                    className={classes.buttonRow}
                >
                    <Grid
                        item
                        xs={6}
                        >
                    <Button
                        onClick={handleOkClicked}
                        className={classes.okButton}
                    >
                        Ok
                    </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6} 
                    >
                        <Button
                            onClick={handleOkClicked}
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    

                </Grid>

            </TableContainer>
        </Popover>
    )
}

export default RentalDetails;