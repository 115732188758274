import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse, Container, Grid } from '@material-ui/core';
import { ExpandMoreTwoTone } from '@material-ui/icons';
import uuid from "uuid-random";
import moment from 'moment';
import { api, Context } from '../../Portal';
import Section from '../../components/controls/Section';
import DropdownField from '../../components/controls/DropdownField';
import { extractControl, buildDeliveryForm } from '../../components/controls/buildForm';
import ProductGrid from '../../components/controls/ProductGrid';
import FieldGroup from '../../components/controls/FieldGroup';
import useCustomerInformation from '../../hooks/useCustomerInformation';
import useRedirect from '../../hooks/useRedirect';

const viewUrl = "/views/v1/approve";
const url = "/api/v1/rentalorders";

const useStyles = makeStyles((theme) => ({
    delivery: {
        borderBottom: "1px solid " + theme.palette.primary.dark,
        borderLeft: "1px solid " + theme.palette.primary.dark,
        borderRight: "1px solid " + theme.palette.primary.dark,
        width: "100%",
        margin: "0",
        maxWidth: "100%",
        padding: "1rem"
    },
    deliveryBar: {
        ...theme.titleBar,
        border: "1px solid " + theme.palette.primary.dark,
        marginLeft: "4px",
        marginRight: "4px",
    },
    deliveryOpening: {
        ...theme.titleBar,
        borderTop: "1px solid " + theme.palette.primary.dark,
        borderLeft: "1px solid " + theme.palette.primary.dark,
        borderRight: "1px solid " + theme.palette.primary.dark,
        marginLeft: "4px",
        marginRight: "4px",
    },
    collapse: {
        width: "100%",
        maxWidth: "100%",
        marginLeft: "4px",
        marginRight: "4px",
    },
    button: {
        marginRight: "2em",
    },
    defaultBorder: {
        "& fieldset": {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
        "& input:disabled fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(1, 104, 149, 1)",
        },
        '& hover': {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
    },
    disabledBorder: {
        "& fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(0, 0, 0, .5)",
        },
        '& hover': {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
    },
}));

const transformModels = (models) => {
    return models.map(categories => (
        categories.map(product => ({
            category: product.category,
            MODEL: product.model,
            MODELDESCRIPTION: product.description,
            SHORTDESCRIPTION: product.shortDescription,
            REQBYDATE: "",
            REQBYTIME: "",
            image: product.image,
            highEnd: product.highEnd
        }))
    ));
}

const ApproveRental = () => {
    const [state, dispatch] = useContext(Context);
    const [information, costCentreRequired, customers, wards, , onCustomerSelected, onWardSelected, onCostCentreSelected] = useCustomerInformation();

    const [costCentres, setCostCentres] = useState([]);
    const [communityDelivery, setCommunityDelivery] = useState(false);
    const [row1, setRow1] = useState({ height: 0, groups: [] });
    const [row2, setRow2] = useState({ height: 0, groups: [] });

    const [delivery, setDelivery] = useState([]);
    const [comment, setComment] = useState([]);
    const [contactDetails, setContactDetails] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [models, setModels] = useState([]);

    const [postCodes, setPostCodes] = useState([]);

    const [productsOrdered, setProductsOrdered] = useState([]);
    const [showAddress, setShowAddress] = useState(false);
    const [staff, setStaff] = useState({ authorisers: [], requestors: [], contacts: [] });
    const [customerInformation, setCustomerInformation] = useState([]);
    const [contact, setContact] = useState({
        name: '',
        surname: '',
        firstNames: ''
    });
    const [requestor, setRequestor] = useState({
        name: '',
        surname: '',
        firstNames: ''
    });
    const { redirectToViewAuthorisations } = useRedirect();

    const formMethods = useForm();
    const { handleSubmit, reset } = formMethods;
    const classes = useStyles();

    let today = moment();
    if (today.hour() < 2) {
        today.hour(10);
        today.minute(0);
        today.second(0);
    } else if (today.hour() >= 14) {
        today.date(today.date() + 1);
        today.hour(10);
        today.minute(0);
        today.second(0);
    } else {
        today.hour(today.hour() + 4);
    }

    useEffect(() => {
        getExistingRental();
        return () => {
            dispatch({ type: "CLEAR-CUSTOMER-SELECTION" });
        }
    }, []);

    const getExistingRental = () => {
        const utcOffset = Math.ceil(moment().utcOffset() / 60);
        api.get(state.host + viewUrl + `/${state.toApprove.rentalOrderNo}/${utcOffset}`, state.credentials)
            .then(({ data }) => {
                setCommunityDelivery(data.communityDelivery);
                setStaff(data.staff);
                setRow1({ height: data.row1Height, groups: data.groupsRow1 });
                setRow2({ height: data.row2Height, groups: data.groupsRow2 });
                setDelivery(data.delivery);
                setComment(data.comment);
                setContactDetails(data.contactDetails);
                setModels(transformModels(data.models));
                setPostCodes(data.postCodes.map(({ postCode1, city, county }) => ({ value: postCode1, description: `${postCode1} ${city} ${county}` })));
                setShowAddress(data.communityDelivery);
                setGridColumns(data.grid);
                setCustomerInformation(data.customerInformation);
                setCostCentres(data.costCentres);
                setProductsOrdered(data.products.map(x => ({
                    MODEL: x.model,
                    MODELDESCRIPTION: x.description,
                    highEnd: x.highEnd,
                    serialNo: x.serialNo,
                    REQBYDATE: x.requiredByDate,
                    REQBYTIME: x.requiredByTime,
                    iREQBYDATE: x.requiredByDate,
                    iREQBYTIME: x.requiredByTime,
                    guid: uuid()
                })));
            });
    }

    const onProductsSelected = (products) => {
        setProductsOrdered(products);
    }

    const onProductUpdated = (products) => {

    }

    const handleAddressCollapse = () => {
        setShowAddress(!showAddress);
    }

    const onSubmit = (data) => {
        if (productsOrdered.length === 0) {
            dispatch({ type: "SHOW-ERROR", payload: { heading: "No equipment", message: "Please select equipment for this rental order" } });
            return;
        }

        let { CUSTOMER, WARD, COSTCENTRE, REQBYDATE, REQBYTIME, ...rest } = data;

        let approveRental = {
            USEREMAIL: state.user.username,
            CUSTOMER: CUSTOMER,
            SHIPTOCODE: state.selectedShipTo?.shipToCode ?? "",
            WARD: WARD,
            COSTCENTRE: COSTCENTRE,
            ...rest,
            PRODUCTS: productsOrdered.map((product) => {
                let { category, highEnd, serialNo, onChange, image, ...rest } = product;
                return {
                    ...rest,
                    SERIALNO: serialNo ?? ""
                }
            }),
            APPROVED: true,
        };

        api.put(state.host + url + `/${state.toApprove.rentalOrderNo}`, approveRental, state.credentials)
            .then(({ data }) => {
                redirectToViewAuthorisations();
            });
    }

    const handleDecline = () => {
        api.delete(state.host + url + `/${state.toApprove.rentalOrderNo}`, state.credentials)
            .then(({ data }) => {
                redirectToViewAuthorisations();
            });
    }

    return (
        <FormProvider {...formMethods}>
            <form>
                <Grid
                    container
                    direction="row"
                    item
                    spacing={1}
                >
                    {row1.groups.length > 0 && (
                        <FieldGroup
                            fields={[{ columnCaption: "Customer Information" }]}
                            classes={classes}
                            id={uuid()}
                        >
                            <DropdownField
                                id="select-customer"
                                label="Customer"
                                onChange={onCustomerSelected}
                                name="CUSTOMER"
                                required
                                disabled
                                value={information.CUSTOMER}
                                options={customers.map(customer => ({ value: customer.no, description: customer.name }))}
                                xs={12}
                            />
                            <DropdownField
                                id="select-ward"
                                label="Ward"
                                onChange={onWardSelected}
                                name="WARD"
                                required
                                disabled={customerInformation[1]?.editable === "No"}
                                value={customerInformation[1]?.fieldValueAsText ?? ""}
                                options={wards.map(ward => ({ value: ward.description, description: ward.description }))}
                                xs={12}
                            />
                            {(costCentreRequired || (costCentres.length > 0 && !costCentreRequired)) && (
                                <DropdownField
                                    id="select-costcentre"
                                    label="Cost Center"
                                    onChange={onCostCentreSelected}
                                    name="COSTCENTRE"
                                    required={costCentreRequired}
                                    disabled={customerInformation[2]?.editable === "No" ?? false}
                                    value={customerInformation[2]?.fieldValueAsText ?? ""}
                                    options={costCentres.map(costCentre => ({ value: costCentre.costCentreCode, description: costCentre.costCentreCode }))}
                                    xs={12}
                                />
                            )}
                        </FieldGroup>
                    )}

                    {row1.groups.length > 0 && row1.groups.map(group => (
                        <FieldGroup
                            fields={group}
                            height={row1.height}
                            staff={staff}
                            contact={contact}
                            requestor={requestor}
                            onContactChanged={setContact}
                            onRequestorChanged={setRequestor}
                            classes={classes}
                            id={uuid()}
                        />
                    ))}
                    {row2.groups.length > 0 && row2.groups.map(group => (
                        <FieldGroup
                            fields={group}
                            height={row2.height}
                            staff={staff}
                            contact={contact}
                            requestor={requestor}
                            onContactChanged={setContact}
                            onRequestorChanged={setRequestor}
                            classes={classes}
                            id={uuid()}
                        />
                    ))}

                    {gridColumns.length > 0 && (
                        <ProductGrid columns={gridColumns} data={productsOrdered} models={models} onChange={onProductsSelected} onUpdate={onProductUpdated} editable={true} />
                    )}

                    {delivery.length > 0 && (
                        <Section
                            title={delivery[0].caption}
                            onClick={handleAddressCollapse}
                            iconRight={<ExpandMoreTwoTone />}
                            className={showAddress ? classes.deliveryOpening : classes.deliveryBar}
                        >
                            <Collapse
                                in={showAddress}
                                className={classes.collapse}
                            // todo: use onExit to set the section border-bottom to show
                            >
                                <Container
                                    className={classes.delivery}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                    >
                                        {delivery.map(field => (buildDeliveryForm(field, communityDelivery, postCodes, "", classes)))}

                                        {contactDetails.map(field => (extractControl(field, classes)))}
                                    </Grid>
                                </Container>
                            </Collapse>
                        </Section>
                    )}

                    {comment.length > 0 && (
                        <FieldGroup
                            fields={function () {
                                let field = comment[1];
                                field.caption = "";
                                field.columnCaption = "Comments";
                                return [field];
                            }()}
                            xs={12}
                            style={{ marginTop: 5 }}
                            classes={classes}
                        />
                    )}

                    {row1.groups.length > 0 && (
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit(onSubmit)}
                                className={classes.button}
                            >
                                Approve Rental
                            </Button>                            
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleDecline}
                                className={classes.button}
                            >
                                Decline Rental
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </form >
        </FormProvider >
    )
}

export default ApproveRental;