import React, { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';

import { Context } from '../../Portal';
import useGetConsignmentStock from '../../hooks/useGetConsignmentStock';
import FieldGroup from '../../components/controls/FieldGroup';
import useCustomerInformation from '../../hooks/useCustomerInformation';
import DropdownField from '../../components/controls/DropdownField';
import Actions from './Actions';
import BaseDataTable from '../../components/BaseDataTable';

const useStyles = makeStyles((theme) => ({}));

const ConsignmentStock = () => {
    const [state, dispatch] = useContext(Context);
    const [information, costCentreRequired, customers, wards, costCentres, onCustomerSelected, onWardSelected, onCostCentreSelected] = useCustomerInformation();
    const [cells, setCells] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const stock = useGetConsignmentStock();
    const formMethods = useForm();

    const classes = useStyles();

    useEffect(() => {
        setCells(buildCells());
        setSelectedRows([]);
        setRows([])

        return () => {
            dispatch({ type: "CLEAR-CUSTOMER-SELECTION" });
            setRows([]);
        }
    }, [])

    useEffect(() => {
        if (state.selectedCustomer === null) {
            return;
        }
        if (Boolean(state.selectedCustomer)) {
            dispatch({ type: "LOAD-CONSIGNED-STOCK" });
        }
    }, [state.selectedCustomer])

    useEffect(() => {
        transformStock();
    }, [stock])

    const buildCells = () => {
        return [
            {
                name: "Store",
                selector: "storeCode",
                sortable: true
            },
            {
                name: "Ward",
                selector: "ward",
                sortable: true
            },
            {
                name: "Model",
                selector: "model",
                sortable: true
            },
            {
                name: "Description",
                selector: "description",
                sortable: true
            },
            {
                name: "Serial Number",
                selector: "serialNumber",
                sortable: true
            },
        ]
    }

    const transformStock = () => {
        let allProducts = [];

        for (const [key, value] of Object.entries(stock)) {
            allProducts = allProducts.concat(value);
        }

        setRows(allProducts);
    }

    const handleSelectedRows = (state) => {
        setSelectedRows(state.selectedRows);
    }

    return (
        <FormProvider {...formMethods}>
            <FieldGroup
                fields={[{ columnCaption: "Customer Information" }]}
                style={{ marginBottom: 14 }}
                classes={classes}
            >
                <DropdownField
                    id="select-customer"
                    label="Customer"
                    onChange={onCustomerSelected}
                    name="CUSTOMER"
                    required
                    value={information.CUSTOMER}
                    options={customers.map(customer => ({ value: customer.no, description: customer.name }))}
                    xs={12}
                />
            </FieldGroup>
            
            <BaseDataTable 
                cells={cells} 
                rows={rows}
                actions={<Actions rows={selectedRows} />}
                onRowsSelected={handleSelectedRows}
            />

        </FormProvider>
    )
}

export default ConsignmentStock;