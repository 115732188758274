import { useReducer } from "react";
import { useContext, useEffect, useState } from "react";
import { api, Context } from "../Portal";

const generalSectionUrl = "/views/v1/create-order/general";

const reducer = (state, action) => {
    switch (action.type) {
        case "set-wards":
            return {
                ...state,
                wards: action.payload,
            }

        case "preselect-ward": 
            return {
                ...state,
                preselectedWard: action.payload,
            }
    }
}

const useCustomerInformation = () => {
    const [state, dispatch] = useContext(Context);
    const [local, dispatchLocal] = useReducer(reducer, { wards: [] });

    const [values, setValues] = useState({ CUSTOMER: "", WARD: "", COSTCENTRE: "" });
    const [customers, setCustomers] = useState([]);
    const [costCentres, setCostCentres] = useState([]);
    const [costCentreRequired, setCostCentreRequired] = useState(false);

    useEffect(() => {
        setValues({ CUSTOMER: "", WARD: "", COSTCENTRE: "" });
        setCustomers(state.user.customers);

        if (state.user.customers.length > 0) {
            let detail = state.user.customers.find(customer => customer.no === state.user.customers[0].no);
            dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: state.user.customers[0].no, customerDetail: detail  } });
            onCustomerSelected({ target: { value: state.user.customers[0].no } });
        }

        //return () => {
        //    dispatch({ type: "CLEAR-CUSTOMER-SELECTION" });
        //    setValues({ CUSTOMER: "", WARD: "", COSTCENTRE: "" });
        //}
    }, [state.user.customers, dispatch]);

    useEffect(() => {
        if (local.wards.length > 0) {
            onWardSelected({ target: { value: local.wards[0].description } });
            return;
        }

        if (Boolean(local.preselectedWard)) {
            onWardSelected({ target: { value: local.preselectedWard } })
        }
    }, [local.wards.length, local.preselectedWard]);

    const onCustomerSelected = (event, ward) => {
        let value = event.target.value;
        let detail = state.user.customers.find(customer => customer.no === value);

        setValues({ CUSTOMER: value, WARD: "", COSTCENTRE: "" });

        setCostCentreRequired(detail?.costCentreMandatory === "Yes");
        api.get(state.host + generalSectionUrl, { ...state.credentials, headers: { ...state.credentials.headers, "acl-customer": value } })
            .then(({ data }) => {
                    dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: value, customerDetail: detail } });
                    dispatchLocal({ type: "set-wards", payload: data});
                    dispatchLocal({ type: "preselect-ward", payload: ward });
                });
    }

    const onWardSelected = (event) => {
        let value = event.target.value;

        setValues((prev) => ({ ...prev, WARD: value, COSTCENTRE: "" }));

        let ward = local.wards.find(ward => ward.description === value);

        setCostCentres(ward.costCentres);
        dispatch({ type: "WARD-SELECTED", payload: { selectedWard: value } });

        if (ward.costCentres.length > 0) {
            let defaultCostCentre = ward.costCentres.find(costCentre => costCentre.default === "Yes");

            setValues((prev) => ({ ...prev, COSTCENTRE: defaultCostCentre.costCentreCode }));
            
            return;
        }

        if (costCentreRequired) {
            dispatch({ type: "SHOW-ERROR", payload: { heading: "Missing Cost Centers", message: `${value} Ward has no cost centers` } });
        }
    }

    const onCostCentreSelected = (event) => {
        setValues((prev) => ({ ...prev, COSTCENTRE: event.target.value }));
    }

    return [values, costCentreRequired, customers, local.wards, costCentres, onCustomerSelected, onWardSelected, onCostCentreSelected];
}

export default useCustomerInformation;