import { useContext, useEffect, useState } from "react";
import { Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";

import { api, Context } from "../../Portal";
import Popover from "../../components/Popover";
import DropdownField from "../../components/controls/DropdownField";
import InputField from "../../components/controls/InputField";
import FieldGroup from "../../components/controls/FieldGroup";

const url = "/api/v1/users/authorise";

const useStyles = makeStyles((theme) => ({
    disabled: {
        "& input": {
            color: "#545454",
            fontWeight: "bold",
        }
    },
    button: {
        marginLeft: "8px",
    }
}))
const ApproveOrder = ({ show, onExited, staff, contact, requestor, message, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState("");
    const [people, setPeople] = useState({ authorisers: [], requestors: [], contacts: [] });
    const [scopedRequestor, setRequestor] = useState({});
    const [scopedContact, setContact] = useState({});
    const [approver, setApprover] = useState("");
    const [approverPassword, setApproverPassword] = useState("");
    const [error, setError] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        setOpen(show);
        if (Boolean(staff)) {
            setPeople(staff);
        }
        setApproverPassword("");
        setApprover("");
        setStatus("");
        setError("");
        setRequestor(requestor);
        setContact(contact);
    }, [show, staff, requestor, contact])

    const handleCancel = () => {
        setStatus("cancelled");
        setOpen(false);
    }

    const handleHold = () => {
        setStatus("not approved");
        setOpen(false);
    }

    const handleApprove = () => {
        setError(false);
        api.post(state.host + url, { userName: approver, password: approverPassword }, state.credentials)
            .then(response => {
                let result = response.data;
                switch (result.authorised) {
                    case true:
                        setStatus("approved");
                        setApproverPassword("");
                        setApprover("");
                        setOpen(false);
                        break;

                    case false:
                        setError(true);
                        setApproverPassword("");
                        break;
                }
            })
            .catch((error) => {
                setStatus("cancelled");
                setApproverPassword("");
                onExited(status);
            })
    }

    const handleExited = () => {
        if (status === "cancelled") {
            onExited(status);
            setApproverPassword("");
            setApprover("");
        }

        if (status === "not approved") {
            onExited(status, { CONTACTPERSON: scopedContact, REQNURSE: scopedRequestor });
            setApproverPassword("");
            setApprover("");
        }

        if (status === "approved") {
            onExited(status, { CONTACTPERSON: scopedContact, REQNURSE: scopedRequestor, APPROVER: approver });
            setApproverPassword("");
            setApprover("");
        }
    }

    return (
        <Popover
            open={open}
            showTitle={true}
            onExited={handleExited}
            ignoreWidth
        >
            <Grid
                container
                spacing={1}
            >
                <FieldGroup
                    fields={[{ columnCaption: "Instant Approval" }]}
                    xs={12}
                    classes={classes}
                >
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography 
                                variant="body2"
                                color="secondary"
                            >
                                {message}
                            </Typography>
                        </Grid>
                        {scopedContact.name &&
                            <InputField
                                label="Contact"
                                name="CONTACTPERSON"
                                value={scopedContact.name}
                                disabled
                                className={classes.disabled}
                                xs={12}
                            />
                        }
                        {scopedRequestor.name &&
                            <InputField
                                label="Requesting Nurse"
                                name="REQNURSE"
                                value={scopedRequestor.name}
                                disabled
                                className={classes.disabled}
                                xs={12}
                            />
                        }
                        <DropdownField
                            name="APPROVER"
                            label="Approver"
                            value={approver}
                            options={people.authorisers.map(person => ({ value: person.portalUserEmail, description: person.name }))}
                            onChange={(event) => setApprover(event.target.value)}
                            xs={12}
                        />
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                label="Password"
                                color="primary"
                                variant="outlined"
                                fullWidth
                                type="password"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={error}
                                value={approverPassword}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(event) => setApproverPassword(event.target.value)}
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            justify="center"
                        >
                            {error && (
                                <Typography color="error" variant="caption">
                                    Invalid credentials supplied
                                </Typography>
                            )}
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={approverPassword.length === 0}
                            onClick={handleApprove}
                        >
                            Approve
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleHold}
                            className={classes.button}
                        >
                            Hold
                        </Button>
                        <Button
                            onClick={handleCancel}
                            style={{ marginLeft: "auto" }}

                        >
                            Cancel
                        </Button>
                    </Grid>
                </FieldGroup>


                <Grid item xs={12} />
            </Grid>
        </Popover>
    )
}

export default ApproveOrder;