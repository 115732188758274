import { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

import { Context } from "../../Portal";

const AuthorisedRoute = ({ children, permission, ...rest }) => {
    const [state] = useContext(Context);
    const history = useHistory();

    useEffect(() => {
        if (!state.authenticated) {
            history.push("/login");
        }
    }, [state.authenticated, history])

    let authorised = false;
    if (state.user) {
        if (permission) {
            authorised = state.user.menuItemPermissions.includes(permission);
        }
        else {
            authorised = true;
        }
    }

    if (!authorised) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={() => children}
        />
    );
}

export default AuthorisedRoute;