import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';

import Navigator from './Navigator';
import Header from './Header';
import { CircularProgress, makeStyles, Snackbar } from '@material-ui/core';
import { Context } from '../../Portal';
import useWhyDidYouUpdate from '../../hooks/useWhyDidYouUpdate';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://pegasushealth.com.au/">
        Pegasus Health Group
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 1,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.body.backgroundColor, //"rgba(255,255,255,0.7)"
  },
  wrapper: {
    margin: "auto",
    position: 'relative',
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <CircularProgress color="primary" />
      </div>
    </div>
  )
}

const drawerWidth = 256;

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(2, 2),
    background: theme.body.backgroundColor,
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
});

const drawerStyle = {
  style: {
    width: drawerWidth
  }
}

function Paperbase({children, ...props}) {
  useWhyDidYouUpdate("Paperbase", props);

  const [state, dispatch] = useContext(Context);
  const { classes, navigation } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setShowError(Boolean(state.error));
    setShowSuccess(Boolean(state.success));
  }, [state.loading, state.error, state.success])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    
    setShowError(false);
    dispatch({ type: "CLEAR-ALERTS" });
  }

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    
    setShowSuccess(false);
    dispatch({ type: "CLEAR-ALERTS" });
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={drawerStyle}
            categories={navigation}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator
            PaperProps={drawerStyle}
            categories={navigation}
          />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>
          {children}
        </main>
        {showError && (
          <Snackbar open={showError} autoHideDuration={6000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" variant="filled">
              <AlertTitle>Error</AlertTitle>
              <Typography variant="subtitle1">
                {state.error.heading}
              </Typography>
              <Typography variant="body2">
                {state.error.message}
              </Typography>
            </Alert>
          </Snackbar>
        )}
        {showSuccess && (
          <Snackbar open={showSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
            <Alert onClose={handleCloseSuccess} severity="success" variant="filled">
              <AlertTitle>Success</AlertTitle>
              <Typography variant="subtitle1">
                {state.success}
              </Typography>
            </Alert>
          </Snackbar>
        )}
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </div>
    </div>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
