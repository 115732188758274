import { makeStyles } from "@material-ui/core";

const usePopoverStyles = makeStyles((theme) => ({
    root: {
        "& > div": {
            overflow: "hidden",
        },
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
    content: {
        width: "75vw",
        padding: "1em",
        alignContent: 'flex-start',
        padding: '0',
    },
    contentTall: {
        // height: "95vh",
        width: "75vw",
        padding: "1em"
    },
    title: {
        height: "5em",
    }
}));

const usePopoverStylesWithNoWidth = makeStyles((theme) => ({
    root: {
        "& > div": {
            overflow: "hidden",
        },
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
    content: {
        // height: "75vh",
        width: "432px",
        padding: "2em"
    },
    contentTall: {
        // height: "95vh",
        // width: "75vw",
        padding: "1em"
    },
    title: {
        height: "5em",
    }
}));

const usePopoverStylesWithScrollbar = makeStyles((theme) => ({
    root: {
        "& > div": {
            overflow: "auto",
        },
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
    content: {
        // height: "75vh",
        width: "75vw",
        padding: "1em"
    },
    contentTall: {
        // height: "95vh",
        width: "75vw",
        padding: "1em"
    },
    title: {
        height: "5em",
    }
}));
export { usePopoverStylesWithScrollbar, usePopoverStylesWithNoWidth }
export default usePopoverStyles;