import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { buildRentalForm } from './buildForm';

const useStyles = makeStyles((theme) => ({
    card: {
        ...theme.body,
        border: "0px solid " + theme.palette.primary.dark,
        backgroundColor: '#F4F4F6',
    },
    header: {
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        ...theme.titleBar,
        backgroundColor: "#E3E4E6",
        color: "#212F41",
    }
}));

const FieldGroup = ({ fields, height, children, staff, contact, requestor, onContactChanged, onRequestorChanged, classes, ...props }) => {
    const localClasses = useStyles();

    const padFields = () => {
        let groupHeight = 0;
        if (Boolean(children)) {
            groupHeight = height - children.length
        }
        else{
            groupHeight = height - fields.length;        
        }

        let emptyFields = [];

        if (groupHeight > 0) {
            emptyFields = Array.from({ length: groupHeight }, (v, i) => i);
        }

        return (
            <>
                {Boolean(children) ? children : fields.map(field => (buildRentalForm(field, 12, staff, contact, onContactChanged, requestor, onRequestorChanged, classes)))}
                {emptyFields.map(() => (
                    <Grid
                        container
                        item
                        xs={12}
                        style={{
                            lineHeight: "21px",
                            marginBottom: "8.3px",
                            marginTop: "6.5px",
                        }}>
                        &nbsp;
                    </Grid>
                ))}
            </>
        );
    }

    return (
        <Grid
            item
            xs={3}
            {...props}
        >
            <Card
                className={localClasses.card}
                variant="outlined"
            >
                <CardHeader
                    className={localClasses.header}
                    title={fields[0].columnCaption}
                    titleTypographyProps={{ variant: "subtitle2" }}
                />
                <CardContent>
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                    >
                        {padFields()}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default FieldGroup;