import { useEffect, useState } from "react";
import { AppBar, Container, FormControlLabel, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import { LinkTwoTone } from "@material-ui/icons";
import uuid from "uuid-random";

import Popover from "../../components/Popover";
import ProductCard from "../../components/ProductCard";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: ".75rem",
        paddingLeft: ".75rem",
        paddingRight: ".75rem",
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    selectProductModal: {
        backgroundColor: '#DEEBF1',
    },
    categoryRow: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderTop: '1px solid #016895',
        borderBottom: '1px solid #016895',
        backgroundColor: 'transparent',
        height: '50px',
    },
    categoryList: {
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        fontSize: '15pt',
        paddingRight: '30px',
    },
    link: {
        color: '#016895',
        fontWeight: '700',
        fontSize: '11pt',
        paddingLeft: "1rem",
        paddingRight: "1rem",
        cursor: 'pointer',
        "&:link": {
            color: '#016895',
            textDecoration: "none",
        },
        "&:hover": {
            color: "#26A9E1",
            textDecoration: "none",
        },
        "&:visited": {
            color: '#016895',
            textDecoration: "none",
        },
        "&:active": {
            color: "#26A9E1",
            textDecoration: "none",
        },
    },
    linkActive: {
        color: '#26A9E1',
        fontWeight: '700',
        fontSize: '11pt',
        paddingLeft: "1rem",
        paddingRight: "1rem",
        "&:hover": {
            color: "#26A9E1",
            textDecoration: "none",
        },
    },
    activeCategory: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        marginBottom: '20px',
    },
    inactiveCategory: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        marginBottom: '20px',
    },
    products: {
        display: "flex",
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: "100%",
    },
    left: {
        paddingTop: "2em",
        paddingBottom: "1em",
        height: "70vh",
        width: '70%',
        overflowY: "auto",
    },
    dividerColumn: {
        width: '5%',
    },
    right: {
        paddingTop: "2em",
        paddingBottom: "1em",
        width: '30%'
    },
    productItem: {
        paddingRight: '10px',
        paddingBottom: '10px',
    },
    list: {
        border: "0px solid " + theme.palette.primary.dark

    },
    buttonContainer: {
        padding: "0",
        width: '100%',
    },
    confirmButton: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        backgroundColor: '#26A9E1',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    deleteIcon: {
        color: '#016895',
        cursor: 'pointer',
        width: '20px',
    },
    categoryDivider: {
        width: '100%',
        marginBottom: '8px',
        color: '#FFFFFF',
        backgroundColor: '#016895',
        padding: '5px 0',
    },
}));

const SelectProducts = ({ models, show, onExited, ...props }) => {
    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState({});
    const [cells, setCells] = useState([]);
    const [activeCategory, setActiveCategory] = useState('');
    const classes = useStyles();

    useEffect(() => {
        setOpen(show);
    }, [show]);

    const onProductSelected = (model) => {
        setProduct(model);
        setOpen(false);
    }

    return (
        <Popover
            open={open}
            onExited={() => onExited(product)}
            onEscapeKeyDown={() => onExited(product)}
            showTitle={true}
            tall
            {...props}
        >
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                justify="flex-start"
                item
                xs={12}
            >
                <AppBar
                    position="static"
                    elevation={0}
                >
                    <Typography
                        className={classes.root}
                    >
                        {models.map((categories, index) => {
                            return (
                                <Link
                                    href={`#${categories[0].category}`}
                                    // onClick={(event) => event.preventDefault()}
                                    className={classes.link}
                                    key={uuid()}
                                >
                                    {categories[0].category}
                                </Link>
                            )
                        })}
                    </Typography>
                </AppBar>

                <Container className={classes.products}>
                        <Grid
                            container
                            spacing={2}
                            style={{ width: "100%" }}
                            className= {classes.left}
                        >
                            {models.map((categories) => {
                                return (
                                    <>
                                        <div
                                            style={{ width: "100%", marginBottom: "8px" }}
                                            id={`${categories[0].category}`}
                                            key={uuid()}
                                        >
                                            <FormControlLabel
                                                id={categories[0].category}
                                                control={<LinkTwoTone style={{ marginLeft: 12 }} />}
                                                labelPlacement="start"
                                                label={
                                                    <Typography
                                                        variant="subtitle2"
                                                    >
                                                        {categories[0].category}
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                        {categories.map((product, index) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={3}
                                                >
                                                    <ProductCard
                                                        product={product}
                                                        index={index}
                                                        onChange={onProductSelected}
                                                        key={uuid()}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </>
                                )
                            })}
                            <Grid
                                item
                                xs={12}
                            />
                        </Grid>
                </Container>
            </Grid>
        </Popover>
    )
}

export default SelectProducts;