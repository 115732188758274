import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PersonOutlineSharpIcon from '@material-ui/icons/PersonOutlineSharp';

import { Context } from "../../Portal";

const useStyles = makeStyles(() => ({
    iconButtonAvatar: {
        padding: '4px 2 4px 0',
        marginLeft: '-15px',
    },
}));

const LoginButton = () => {
    const classes = useStyles();

    let history = useHistory();

    const redirectToLogin = (event) => {
        history.push("/login");
        event.stopPropagation();
    }

    return (
        <IconButton
            color="inherit"
            className={classes.iconButtonAvatar}
            onClick={redirectToLogin}
        >
            <Avatar>
                <PersonOutlineSharpIcon />
            </Avatar>
        </IconButton>
    );
}

const ProfileButton = ({ user }) => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();

    useEffect(() => { }, [state.selectedCustomer])
    let history = useHistory();

    const { fullName } = user;

    const handleClick = (event) => {
        if (history.location.pathname !== "/profile") {
            history.push("/profile");
        }
        event.stopPropagation();
    }

    return (
        <IconButton
            color="inherit"
            className={classes.iconButtonAvatar}
        >
            <Avatar>
                {`${fullName.charAt(0)}`}
            </Avatar>
        </IconButton>
    );
}

const Profile = (props) => {
    const [state] = useContext(Context);
    useEffect(() => { }, [state.authenticated]);

    let { user } = state;

    return state.authenticated ? <ProfileButton {...props} user={user} /> : <LoginButton  {...props} />
}

export default Profile;