import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme({
    body: {
        backgroundColor: "#FFFFFF",
    },
    titleBar: {
        backgroundColor: "#B4D7E8",
        color: "#212F41",
    },
    formHeading: {
        color: "#212F41",
    },
    palette: {
        background: {
            default: '#FFFFFF'
        },
        primary: {
            light: '#26A9E1',
            main: '#016895',
            dark: '#4E6E98',
        },
        secondary: {
            light: '#DDEAF0',
            main: '#67A4C0',
            dark: '#418DAF',
            badge: '#f44336',
        },
        otherbutton: {
            main: '#26A9E1',
        },
        navigation: {
            item: {
                color: '#FFFFFF',
                fontSize: '10pt',
                letterSpacing: 0.5,
                lineHeight: "2rem",
                textTransform: 'none',
                '&:hover': {
                    color: '#26A9E1',
                },
                textDecoration: 'none',
                fontWeight: '500',
            },
            itemActive: {
                color: '#26A9E1',
                fontSize: '10pt',
                letterSpacing: 0.5,
                lineHeight: "2rem",
                textTransform: 'none',
                fontWeight: '500',
            },
            heading: {
                color: '#A2A2AE',
                fontSize: '11pt',
                letterSpacing: 0.5,
                lineHeight: "1rem",
                textTransform: 'uppercase',
            }
        }
    },
    typography: {
        h5: {
            // fontWeight: 500,
            fontSize: "1.5rem",
            letterSpacing: 0.5,
            lineHeight: "3rem"
        },
    },
    shape: {
        borderRadius: 0,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
        gradient: {
            background: "#4FA1C9",
            // background: "linear-gradient(90deg, rgba(0,109,179,.7) 0%, rgba(0,124,229,.8) 6%, rgba(0,140,229,.9) 18%, rgba(0,155,229,1) 100%)",
        }
    },
});

theme = {
    ...theme,
    title: {
        ...theme.mixins.gradient,
        color: theme.palette.primary.contrastText,
    },
    header: {
        ...theme.mixins.gradient,
        backgroundColor: '#016895',
    },
    overrides: {
        Mui: {
            selected: {
                backgroundColor: "red"
            },
            acive: {}
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#505261',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
                
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: '#26A9E1',
                },
            },
            containedSecondary: {
                '&:hover': {
                    backgroundColor: '#016895',
                },
            },
            root: {
                backgroundColor: '#016895',
                color: '#FFFFFF',
                cursor: 'pointer',
                '& Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26)',
                    boxShadow: 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.26)',
                    }
                },
                '&:hover': {
                    backgroundColor: '#26A9E1',
                    color: '#FFFFFF',
                }
            },
        },
        MuiBadge: {
            colorPrimary: {
                color: '#fff',
                backgroundColor: '#EB0014',
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 36,
                height: 36,
            },
        },
        MuiMenu: {
            paper: {
                backgroundColor: '#016895',
                color: '#FFFFFF',
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: 1,
                paddingBottom: 1,
                fontSize: "10pt",
                color: theme.palette.common.white,
                '&:hover': {
                    backgroundColor: '#26A9E1 !important',
                },
                '&:focus': {
                    backgroundColor: '#26A9E1',
                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': { 
                        color: theme.palette.common.white,
                    },
                },
                '&Mui': {
                    '&selected': {
                        backgroundColor: 'rgb(0, 0, 0, 0.08)',
                        '&:hover': {
                            backgroundColor: '#26A9E1',
                        },
                    },
                    
                },
            }
        },
        MuiSelect: {
            root: {
                fontSize: "10pt",
            },
        },
        MuiInputBase: {
            root: {
                lineHeight: "0.85em",
                background: "#fff",
            },
            input: {
                fontSize: "10pt",
            }
        },
        MuiFormLabel: {
            root: {
                lineHeight: ".85em",
            },
        },
        MuiFormLabel: {
            root: {
                color: '#016895',
            },
        },
        MuiInputLabel: {
            outlined: {
                color: '#016895',
                borderColor: '#016895',
            },
            shrink: {
                color: '#016895',
                borderColor: '#016895',
            },
            filled: {
                color: '#016895',
                borderColor: '#016895',
            },
        },
        MuiOutlinedInput: {
            input: {
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "10pt",
            },
            disabled: {
                backgroundColor: theme.palette.grey[50]
            },
            notchedOutline: {
                border: "1px solid rgba(1, 104, 149, .5)",
                borderColor: "rgba(1, 104, 149, .5)",
            }
        },
        MuiContainer: {
            root: {
                display: "flex"
            }
        },
        Mui: {
            disabled: {
                '& MuiOutlinedInput-notchedOutline': {
                    border: "1px solid rgba(0, 0, 0, .38)",
                },
            },
        },

        MuiCardActions: {
            root: {
                background: theme.palette.grey[200]
            }
        },
        MuiCardContent: {
            root: {
                "&:last-child": {
                    paddingBottom: "16px"
                }
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                "&[class*='MuiOutlinedInput-root']": {
                    paddingBottom: 1,
                    paddingTop: 0,
                }
            }
        },
        MuiTableCell: {
            sizeSmall: {
                paddingTop: 0,
                paddingBottom: 0,
                fontSize: "10pt"
            }
        },
        MuiRadio:{
            colorSecondary: {
                '&$checked': {
                    color: 'green',
                  },
            },
        },
        PrivateNotchedOutline: {
            root: {
                borderWidth: '0px',
            },
        },
    }
};

const PortalTheme = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default PortalTheme;