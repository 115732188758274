import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    makeStyles,
    Grid,
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import uuid from "uuid-random";

const useStyles = makeStyles((theme) => ({
    dialogBody: {
        padding: '0',
        zIndex: 10001,
        minWidth: '500px',
        minHeight: '200px',
    },
    paper: { minWidth: "500px", minHeight: '250px' },
    container: { zIndex: 100000 },
    title: {
        color: '#016895',
    },
    okButton: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        width: '100%',
        minWidth: '100px',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        backgroundColor: '#26A9E1',
        color: '#FFFFFF',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    actionItems: {
        flexFlow: 'column',
        alignItems: 'flex-start',
    },
    actionItem: {
        display: 'flex',
        flexFlow: 'row',
        maxWidth: '100%',
        marginLeft: '0px !important',
    },
    actionButtonWrap: {
        padding: '10px',
    },
    actionCommentWrap: {
        padding: '10px',
    },

}));
export const ConfirmationDialog = ({
    open,
    title,
    variant,
    description,
    showOkButton,
    okButtonText,
    showCancelButton,
    cancelButtonText,
    onSubmit,
    onCancel,
    onActionExchange,
    onActionTransfer,
    onActionStop,
    onActionCancel,
    dialogActions
}) => {
    const classes = useStyles();
    if (variant.toLowerCase() !== "error" && variant.toLowerCase() !== "warning" && variant.toLowerCase() !== "info" && variant.toLowerCase() !== "success") {
        variant = 'info';
    }
    const renderDialogAction = (action) => {
        switch (action.actionname) {
            case 'Exchange':
                return (<Button className={classes.okButton} onClick={onActionExchange}>{action.buttontext}</Button>);
            case 'Transfer':
                return (<Button className={classes.okButton} onClick={onActionTransfer}>{action.buttontext}</Button>);
            case 'Stop':
                return (<Button className={classes.okButton} onClick={onActionStop}>{action.buttontext}</Button>);
            case 'Cancel':
                return (<Button className={classes.okButton} onClick={onActionCancel}>{action.buttontext}</Button>);
            default:
                return '';
        }
    }
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.dialogBody}
            classes={{ paper: classes.paper, container: classes.container }}
        >

            <Alert id="alert-dialog-title" severity={variant.toLowerCase()}><AlertTitle>{title}</AlertTitle></Alert>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                {dialogActions && dialogActions.length > 0 &&
                    <DialogActions className={classes.actionItems} >
                        {dialogActions.map((action, index) => (
                            <Grid key={index} className={classes.actionItem} item xs={12 / dialogActions.length} >
                                <div className={classes.actionButtonWrap}>{action.button === 'Yes' && renderDialogAction(action)}</div>
                                <div className={classes.actionCommentWrap}>{action.comment}</div>
                            </Grid>
                        ))}
                    </DialogActions>
                }
            </DialogContent>
            <DialogActions>
                {showOkButton &&
                    <Grid item xs={6} >
                        <Button className={classes.okButton} onClick={onSubmit}>
                        {okButtonText}
                    </Button>
                    </Grid>
                }
                {showCancelButton &&
                    <Grid item xs={6} >
                    <Button className={classes.cancelButton} onClick={onCancel} autoFocus>
                        {cancelButtonText}
                     </Button>
                    </Grid>
                }
            </DialogActions>
        </Dialog>
    );
};