import { HotelTwoTone, MoveToInboxTwoTone, ReceiptTwoTone } from '@material-ui/icons';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faExclamationCircle, faRetweet, faSignOutAlt, faLock, faFileAlt, faWrench, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faPencilAlt, faExclamationCircle, faRetweet, faSignOutAlt, faLock, faFileAlt, faWrench, faFolderOpen)

const navigation = [
        {
            id: 'Customer Service',
            children: [
                { id: 'Start a rental', icon: "pencil-alt",  type: "start", route: "/rental/start", permission: "Create Rental Order" },
                { id: 'Stop a rental', icon: "exclamation-circle", type: "stop", route: "/rental/stop", permission: "Stop Rental Order" },
                { id: 'Transfers', icon: "retweet", type: "transfer", route: "/rental/transfer", permission: "Transfer Equipment" },
                { id: 'Exchanges', icon: "sign-out-alt", type: "exchange", route: "/rental/exchange", permission: "Exchange Equipment" },
                {
                    id: 'View Authorisations',
                    icon: "lock",
                    type: "authorizations",
                    route: "/rental/authorisations",
                    permission: "Authorize Rental Transaction",
                    children: [
                        {
                            id: "Transfer In",
                            route: "/rental/rental-transfer-in",
                            permission: "Transfer Equipment"
                        },
                        {
                            id: "Approve Exchange",
                            route: "/rental/rental-exchange-approve",
                            permission: "Exchange Equipment"
                        }
                    ],
                },
            ],
        },
        {
            id: "Equipment",
            children: [
                { id: 'Consignment Stock', icon: "wrench", type: "consignment", route: "/equipment/consignment", permission: "View Consignment Stock" },
                { id: 'View equipment on hire', icon: "folder-open", type: "hire", route: "/" },
                { id: 'View Invoices', icon: "file-alt", type: "invoices", route: "/invoices/approve", permission: "Authorize Rental Invoice" },
            ],
        },
    ];

  export default navigation;