/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Grid, makeStyles } from '@material-ui/core';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
		required: {
				color: "red",
		},
		requiredBorder: {
				"& fieldset": {
						border: "1px solid red",
				},
				"& label": {
						color: "red",
				},
		},
		default: {
				color: theme.palette.text.primary
		},
		defaultBorder: {
				"& fieldset": {
						border: "1px solid rgba(1, 104, 149, 0.23)"
				},
				"& label": {
						color: "rgba(1, 104, 149, 0.5)",
				},
		},
		submitButton: {
				color: theme.palette.text.secondary,
		},
}));

const NameSelectEntry = ({ people, label, name, required, xs, value, onChange, hideLabel }) => {
		const [localValue, setValue] = useState(null);
		const [open, toggleOpen] = useState(false);
		const classes = useStyles();

		const handleClose = () => {
				setDialogValue({
						name: '',
						surname: '',
						firstNames: ''
			 });

				toggleOpen(false);
		};

		const [dialogValue, setDialogValue] = useState({ name: '', surname: '', firstNames: '' });

	const handleSubmit = (event) => {
		event.stopPropagation();
				event.preventDefault();
				setValue({ ...dialogValue });
				onChange({ ...dialogValue });

				handleClose();
		};

		return (
				<Grid
						item
						xs={xs ? xs : 3}
				>
						<Autocomplete
								value={value}
								autoComplete="new-password"
								onChange={(event, newValue) => {
										if (typeof newValue === 'string') {
												// timeout to avoid instant validation of the dialog's form.
												setTimeout(() => {
														toggleOpen(true);
														setDialogValue({
																surname: newValue,
																firstNames: '',
																name: ''
														});
												});
										} else if (newValue && (newValue.inputValue || (newValue.name && newValue.name.substring(0, 4) === 'Add '))) {
												toggleOpen(true);
												setDialogValue({
														surname: newValue.inputValue,
														firstNames: '',
														name: ''
												});
										} else {
												setValue(newValue);
												onChange({ ...newValue });
										}
								}}
								filterOptions={(options, params) => {
										const filtered = filter(options, params);

										filtered.push({
												inputValue: params.inputValue,
												name: `Add ${label}`,
										});

										return filtered;
								}}
								id={name}
								options={people}
								getOptionLabel={(option) => {
										// e.g value selected with enter, right from the input
										if (typeof option === 'object') {
												return option.name ?? "";
										}
										if (option.inputValue) {
												return option.inputValue;
										}
										return option.newValue;
								}}
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								renderOption={(option) => option.name}
								freeSolo
								fullWidth

								renderInput={(params) => (
										<TextField
												{...params}
												label={hideLabel ? "" : label}
												variant="outlined"
												InputLabelProps={{
														shrink: true
												}}
												inputProps={{
														...params.inputProps,
														autoComplete: "new-password"
												}}
												required={required}
												className={required ? (value.name ? classes.defaultBorder : classes.requiredBorder) : classes.defaultBorder}
										/>
								)}
						/>

						<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
								<form onSubmit={handleSubmit}>
										<DialogTitle id="form-dialog-title">Add a new person</DialogTitle>
										<DialogContent>
												<DialogContentText>
														Enter person's details
												</DialogContentText>
												<TextField
														autoFocus
														margin="dense"
														id="surname"
														value={dialogValue.surname}
														onChange={(event) => setDialogValue({
																...dialogValue,
																surname: event.target.value,
																name: `${event.target.value} ${dialogValue.firstNames}`
														})}
														label="Surname"
														type="text"
												/>
												<TextField
														margin="dense"
														id="firstnames"
														value={dialogValue.firstNames}
														onChange={(event) => setDialogValue({
																...dialogValue,
																firstNames: event.target.value,
																name: `${dialogValue.surname} ${event.target.value}`
														})}
														label="First names"
														type="text"
												/>
										</DialogContent>
										<DialogActions>
												<Button variant="contained" onClick={handleClose} color="primary">
														Cancel
												</Button>
												<Button type="submit" variant="contained" color="primary">
														Add
												</Button>
										</DialogActions>
								</form>
						</Dialog>
				</Grid>
		);
}

export default NameSelectEntry;