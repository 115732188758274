import React, { useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { manuallyIncrementPromiseCounter, manuallyDecrementPromiseCounter } from 'react-promise-tracker';
import GlobalReducer from "./GlobalReducer";
import useWhyDidYouUpdate from "./hooks/useWhyDidYouUpdate";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import { ConfirmationDialog } from './components/controls/ConfirmationDialog';

const initialState = {
    authenticated: false,
    credentials: {
        headers: {}
    },
    selectedCustomer: {},
    error: null,
    success: null,
    host: window.location.protocol + "//" + window.location.host,
}

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div
            style={{ width: "100%", height: "100%", display: "flex", position: "absolute", zIndex: '10000', backgroundColor: 'rgba(255, 255, 255 0.5)', top: 0, left: 0, justifyContent: "center", alignItems: "center" }}
        >
            <Loader type="ThreeDots" color="#016895" height="100" width="100" />
        </div>
    );
}

export const api = axios.create();

let notInitialised = true;

const Portal = (props) => {
    useWhyDidYouUpdate("Portal", props);

    const { children } = props;
    const [state, dispatch] = useReducer(GlobalReducer, initialState);
    const history = useHistory();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState('');
    const [confirmationVariant, setConfirmationVariant] = useState('');
    const [showOkButton, setShowOkButton] = useState(true);
    const [okButtonText, setOkButtonText] = useState('');
    const [okButtonAction, setOkButtonAction] = useState('');
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [cancelButtonText, setCancelButtonText] = useState('');
    const [cancelButtonAction, setCancelButtonAction] = useState('');
    const [confirmationDescription, setConfirmationDescription] = useState('');

    const handleOkClick = () => {
        if (okButtonAction.length > 0) {
            setConfirmationOpen(false);
            history.replace(okButtonAction);
        } else {
            setConfirmationOpen(false);
        }
    }
    const handleCancelClick = () => {
        if (cancelButtonAction.length > 0) {
            setConfirmationOpen(false);
            history.replace(cancelButtonAction);
        } else {
            setConfirmationOpen(false);
        }
    }

    useEffect(() => {
        if (notInitialised) {          
            api.interceptors.request.use(
                request => {
                    dispatch({ type: "API-CALLED", payload: request });
                    manuallyIncrementPromiseCounter();
                    return request;
                }
            );
            api.interceptors.response.use(
                response => {
                    dispatch({ type: "API-SUCCESS", payload: response });
                    manuallyDecrementPromiseCounter();
                    if (response.data.dialogMessage && response.data.dialogMessage.message.length > 0 && !response.data.dialogMessage.actions) {
                        setConfirmationTitle(response.data.dialogMessage.title ?? response.data.dialogMessage.title);
                        setConfirmationVariant(response.data.dialogMessage.dialogType ?? response.data.dialogMessage.dialogType.toLowerCase());
                        setShowOkButton(response.data.dialogMessage.okButtonText ?? response.data.dialogMessage.okButtonText.length > 0);
                        setOkButtonText(response.data.dialogMessage.okButtonText ?? response.data.dialogMessage.okButtonText);
                        setOkButtonAction(response.data.dialogMessage.okButtonAction ?? response.data.dialogMessage.okButtonAction);
                        setShowCancelButton(response.data.dialogMessage.cancelButtonText ?? response.data.dialogMessage.cancelButtonText.length > 0);
                        setCancelButtonText(response.data.dialogMessage.cancelButtonText ?? response.data.dialogMessage.cancelButtonText);
                        setCancelButtonAction(response.data.dialogMessage.cancelButtonAction ?? response.data.dialogMessage.cancelButtonAction);
                        setConfirmationDescription(response.data.dialogMessage.message ?? response.data.dialogMessage.message);
                        setConfirmationOpen(true); 
                    }
                    return response;
                },
                error => {
                    const { status } = error.response;

                    switch (parseInt(status)) {
                        case 401:
                            dispatch({ type: "LOGGED-OUT" });
                            history.push("/");
                            break;
                        default:
                            dispatch({ type: "API-ERROR", payload: error });
                            break;
                    }
                    manuallyDecrementPromiseCounter();
                    return Promise.reject(error);
                }
            )
            
            notInitialised = false;
        }
    }, [history])

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
            <LoadingIndicator />
            <ConfirmationDialog
                open={confirmationOpen}
                title={confirmationTitle}
                variant={confirmationVariant}
                showOkButton={showOkButton}
                okButtonText={okButtonText}
                showCancelButton={showCancelButton}
                cancelButtonText={cancelButtonText}
                description={confirmationDescription}
                onSubmit={handleOkClick}
                onCancel={handleCancelClick}
            />
        </Context.Provider>
    );
}

export const Context = React.createContext(initialState);

export default Portal;