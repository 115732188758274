import { useState } from "react";

const initial = {
    dateOfTransfer: "",
    currentWard: "",
    newWard: "",
    newBed: "",
    newPatientFirstName: "",
    newPatientSurname: "",
    newPatientNo: "",
    option: "",
    contact: {
        name: "",
        surname: "",
        firstNames: "",
    }
}

const useSetExchangeQuestions = () => {
    const [details, setDetails] = useState(initial);
    const [completed, setCompleted] = useState(false);

    const onChange = (question) => {
        setDetails(prev => {
            let newValue = { ...prev, ...question };
            setCompleted(
                (((newValue.newBed !== "" && newValue.newPatientFirstName !== "" && newValue.newPatientSurname !== "" && newValue.newPatientNo !== "")) && newValue.contact.surname !== '' && newValue.contact.name !== '' && newValue.dateOfTransfer !== '' && newValue.option !== '')
            );

            return newValue;
        });
    }

    const reset = () => {
        initial.dateOfTransfer = Date.now();
        setDetails(initial);
        setCompleted(false);
    }

    return [details, completed, onChange, reset];
}

export default useSetExchangeQuestions;