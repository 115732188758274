import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import { Button, Chip, Link, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import datatableStyles from '../../static/datatables-styles';
import { ContactSupportTwoTone, Warning, WarningTwoTone } from '@material-ui/icons';
import { api, Context } from '../../Portal';
import Popover from '../../components/Popover';
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: "8px"
    }
}));

var url = "/api/v1/invoices";
// var queryUrl = "/api/v1/invoices";

const DivisionInvoiceApproval = () => {
    const [state, dispatch] = useContext(Context);
    const [invoices, setInvoices] = useState([]);
    const [open, setOpen] = useState(false);
    const [queryRow, setQueryRow] = useState({});
    const [resolved, setResolved] = useState("No");
    const [message, setMessage] = useState("");
    const [history, setHistory] = useState("");
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [verifyError, setVerifyError] = useState("")
    const [user, setUser] = useState(state.user);

    const browserHistory = useHistory();
    const classes = useStyles();

    const columns = [
        {
            name: 'Month',
            sortable: true,
            selector: "month",
        },
        {
            name: 'Division',
            sortable: false,
            selector: "divisionCode",
        },
        {
            cell: row => (
                <>
                    {row.invoiceNo + " Summary"}
                    {row.overDue && <Chip
                        label="Overdue"
                        color="secondary"
                        variant="default"
                        size="small"
                        style={{ marginLeft: "8px" }}
                    />}
                </>
            ),
            name: "Invoice Number"
        },
        {
            name: 'Total',
            selector: 'total'
        },
        {
            cell: row => (
                <>
                    {!row.verified &&
                        <Button
                            type="button"
                            onClick={() => onVerify(row)}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={!row.canVerify}
                        >
                        Approve
                        </Button>
                    }
                </>
            )
        }
    ];

    const lineColumns = [
        {
            cell: row => {
                if (row.openQueries) {
                    return (
                        <Warning style={{ color: "#ffba08" }} />
                    );
                }

                if (row.status === "Queried") {
                    return (
                        <ContactSupportTwoTone style={{ color: "#4361ee" }} />
                    )
                }
            },
            width: "40px"
        },
        {
            name: 'Cost Centre',
            sortable: true,
            selector: "subCode",
            center: false
        },
        {
            name: 'Ward',
            sortable: true,
            selector: "code",
            center: false,
            compact: true,
        },
        {
            name: 'Verified',
            sortable: true,
            selector: "verified",
            center: false,
            right: true,
            compact: true,
        },
        {
            name: 'Unverified',
            sortable: true,
            selector: "unverified",
            center: false,
            right: true,
            cell: (row) => (
                <StyledCell className={getCssClass(row)}>
                    {row.unverified}
                </StyledCell>
            )
        },
        {
            name: 'Due Date',
            sortable: true,
            selector: "verifyByDate",
            // width: "4em",
            center: false,
            compact: true,
        },
        {
            name: 'Verified By',
            sortable: true,
            selector: "verifier",
            center: false,
            compact: true,
        },
        {
            name: 'Phone',
            sortable: true,
            selector: "phone",
            // width: "4em",
            center: false,
            compact: true,
        },
        {
            name: 'Email',
            sortable: true,
            selector: "email",
            right: false,
            center: false,
            compact: true,
        },
    ]
    const ExpandableComponent = ({ data }) => {
        return (
            <DataTable
                persistTableHead
                dense
                pointerOnHover
                highlightOnHover
                noHeader
                striped
                customStyles={datatableStyles}
                columns={lineColumns}
                data={data.details}
                style={{ width: "97.2%", maxWidth: "97.2%", float: "right", marginBottom: "2em" }}
                onRowClicked={(data) => {
                    onDrillDown(data);
                }}
                conditionalRowStyles={conditionalRowStyles}
            />
        );
    }

    const StyledCell = styled.div`
      &.warning {
        color: #cb3837 !important;
      }
      &.danger {
        color: #680000 !important;
      }
    `;

    const getCssClass = (row) => {
        return rowStatus(row);
    }

    const conditionalRowStyles = [
        {
            when: row => rowStatus(row) === 'warning', //ORANGE
            style: {
                backgroundColor: 'rgba(255, 150, 50, 0.8)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(255, 150, 50, 1)',
                    color: 'white',
                    outlineColor: 'rgba(255, 150, 50, 1)',
                    borderBottomColor: 'rgba(255, 150, 50, 0.8)',
                },
            },
        },
        {
            when: row => rowStatus(row) === 'danger', //RED
            style: {
                backgroundColor: 'rgba(203, 56, 55, 0.8)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(203, 56, 55, 1)',
                    color: 'white',
                    outlineColor: 'rgba(203, 56, 55, 1)',
                    borderBottomColor: 'rgba(203, 56, 55, 0.8)',
                },
            },
        },
        {
            when: row => rowStatus(row) === 'ok', //GREEN
            style: {
                backgroundColor: 'rgba(44, 151, 75, 0.8)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: 'rgba(44, 151, 75, 0.9)',
                    color: 'white',
                    outlineColor: 'rgba(44, 151, 75, 1)',
                    borderBottomColor: 'rgba(44, 151, 75, 0.8)',
                },
            },
        },
    ];

    const rowStatus = (row) => {
        if (showAsWarning(row)) { return "warning" }
        if (showAsDanger(row)) { return "danger" }
        if (showAsOk(row)) { return "ok" }
        return "";
    }

    const showAsWarning = (row) => {
        return row.unverifiedAmount > 0 && row.isPastDue && row.verifiedAmount > 0;
    }

    const showAsDanger = (row) => {
        return row.isPastDue && row.unverifiedAmount > 0 && row.verifiedAmount === 0;
    }

    const showAsOk = (row) => {
        return row.isVerified && row.unverifiedAmount === 0;
    }

    const ResetViewButton = () => {
        return (
            <Button
                onClick={() => onResetView()}
                type="button"
                variant="contained"
                color="primary"
                size="small"
            >
                Back to top</Button>
        );
    }

    const NavigateUpButton = () => {
        return (
            <Button
                onClick={() => onNavigateUp()}
                type="button"
                variant="contained"
                color="primary"
                size="small"
            >
                Up One level</Button>
        );
    }

    const onDrillDown = (data) => {
        dispatch({
            type: "INVOICESUMMARY-LINE-SELECTED",
            payload: {
                selectedInvoiceLevel: "CostCentre",
                selectedInvoiceLineNo: data.invoiceLineNo,
                selectedInvoiceNo: data.invoiceNo,
                selectedInvoiceDistrict: data.districtCode,
                selectedInvoiceFacility: data.facilityCode,
                selectedInvoiceDivision: data.divisionCode,
                selectedInvoiceWard: data.code,
                selectedInvoiceCostCentre: data.subCode
            }
        });
        browserHistory.push('/invoices/approve');
    }

    const isDrillDown = () => {
        return state.user.invoiceApproval === "Facility" || state.user.invoiceApproval === "District" || state.user.invoiceApproval === "Customer";
    }

    const isUserAuthLevel = () => {
        return state.user.invoiceApproval === "Division";
    }

    const onResetView = () => {
        var payload = defaultPayload();
        dispatch({
            type: "INVOICESUMMARY-LINE-SELECTED",
            payload: payload
        });
        browserHistory.push('/invoices/approve');
    }

    const onNavigateUp = () => {
        var payload = browseUpPayload();
        if (state.user.invoiceApproval === "Facility") {
            payload = defaultPayload();
        }
        dispatch({
            type: "INVOICESUMMARY-LINE-SELECTED",
            payload: payload
        });
        browserHistory.push('/invoices/approve');
    }

    const browseUpPayload = () => {
        return {
            selectedInvoiceLevel: "Facility",
            selectedInvoiceNo: state.user.selectedInvoiceNo,
            selectedInvoiceDistrict: state.user.selectedInvoiceDistrict,
            selectedInvoiceFacility: state.user.selectedInvoiceFacility,
            selectedInvoiceDivision: "", 
            selectedInvoiceWard: "",
            selectedInvoiceCostCentre: "" 
        }
    }
    const defaultPayload = () => {
        return {
            selectedInvoiceLevel: "",
            selectedInvoiceNo: "",
            selectedInvoiceDistrict: "",
            selectedInvoiceFacility: "",
            selectedInvoiceDivision: "",
            selectedInvoiceWard: "",
            selectedInvoiceCostCentre: ""
        }
    }

    const handleOkClick = () => {
        setVerifyOpen(false);
    }

    useEffect(() => {
        console.log("state...", state)
        api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${state.user.selectedInvoiceNo}`, state.credentials)
            .then(({ data }) => {
                console.log("data...", data)
                setInvoices(data);
            });
    }, []);

    const refreshData = () => {
        console.log("state...", state)
        api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${state.user.selectedInvoiceNo}`, state.credentials)
            .then(({ data }) => {
                console.log("data...", data)
                setInvoices(data);
            });
    }

    const onCreateQuery = (row) => {
        setOpen(true);
        api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${row.invoiceNo}&invoiceLineNo=${row.invoiceLineNo}&user=${state.user}`, state.credentials)
            .then(({ data }) => {
                setHistory(data.history);
            });

        setQueryRow(row);
        setResolved("No");
        setMessage("");
    }

    const onVerify = (row) => {
        let post = {
            invoiceNo: row.invoiceNo,
            level: "Division",
            district: row.districtCode,
            facility: row.facilityCode,
            division: row.divisionCode,
            ward: "",
            costcentre: ""
        };

        api.post(state.host + url + "/verify", post, state.credentials)
            .then(({ data }) => {
                console.log("verify...", data)
                refreshData();
            });
    }

    const onQuery = () => {
        console.log("Row...", queryRow)
        let post = {
            invoiceNo: queryRow.invoiceNo,
            invoiceLineNo: queryRow.invoiceLineNo,
            message: message,
            resolved: resolved
        };

        api.post(state.host + url + "/query", post, state.credentials)
            .then(() => {
                setOpen(false);
            });
    }

    const onQueryCancelled = () => {
        setOpen(false);
    }

    const handleResolved = (event) => {
        setResolved(event.target.value);
    }

    return (
        <>
            <DataTable
                persistTableHead
                dense
                pointerOnHover
                highlightOnHover
                noHeader={!isDrillDown()}
                title={<><ResetViewButton /> <NavigateUpButton  /></>}
                striped
                customStyles={datatableStyles}
                columns={columns}
                data={invoices}
                expandableRows
                expandableRowDisabled={row => row.disabled}
                expandableRowsComponent={<ExpandableComponent />}
								expandableRowExpanded={row => row.expanded || isDrillDown()}
            />
            <ConfirmationDialog
                open={verifyOpen}
                title={"ERROR VERIFYING"}
                variant={"info"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                description={verifyError}
                onSubmit={handleOkClick}
            />
        </>
    );
}

export default DivisionInvoiceApproval;