import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import logo from "../../assets/PEGASUS_PORTAL_LOGOS-02.png";
import CurrentPage from "../CurrentPage"
import navigation from "../../static/navigation";

import { Context } from '../../Portal';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
    header: theme.header,
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    logo: {
        height: '45px',
        width: 'auto',
    },
    phoneNumber: {
    textDecoration: 'none',
    color: lightColor,
    fontSize: '20pt',
    '&:hover': {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
  },
  button: {
    borderColor: lightColor,
  },
    breadcrumb: {
        backgroundColor: '#26A9E1',
        padding: '6px 50px',
        fontSize: '11pt',
        width: '100%',
    },
});

function Header(props) {
  const [state, dispatch] = useContext(Context);
    
  const { classes, onDrawerToggle } = props;

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        className={classes.header}
      >
        <Toolbar >
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xs>
                          <img alt="Pegasus Health Group" src={logo} className={classes.logo}  />
            </Grid>
            <Grid item
                className={classes.phoneNumber} variant="body2"
            >
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            
                  </Grid>
              </Toolbar>
              <Grid
                  container
              >
                    <Grid
                        item
                        className={classes.breadcrumb}
                    >
                      <CurrentPage navigation={navigation} />
                    </Grid>
              </Grid>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
