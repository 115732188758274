import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles"
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Grid, TextField } from '@material-ui/core';

import LoginError from './login-error';
import { api, Context } from '../../Portal';

const url = "/api/v1/users/login";

const useStyles = makeStyles((theme) => ({
    loginCard: {
        backgroundColor: "#016895",
        maxWidth: '300px',
        marginTop: '65px',
        marginBottom: '10px',
    },
    loginButton: {
        backgroundColor: '#FFFFFF',
        color: '#016895',
        textTransform: 'uppercase',
        width: '100%',
        height: '50px',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    inputStyle: {
        backgroundColor: "#016895",
        border: '1px solid #FFFFFF',
        width: '100%',
        height: '50px',
        color: '#FFFFFF',
        padding: '5px 15px',
        '&::before': {
            borderBottom: '0px',
        },
        '&:hover::before': {
            borderBottom: '0px !important',
        },
        '&:-webkit-autofill': {
            color: 'rgb(255, 255, 255) !important'
        }
    },
    input: {
        WebkitBoxShadow: '0 0 0 1000px #016895 inset',
        '&:-webkit-autofill': {
            color: 'rgb(255, 255, 255) !important',
        },
        fontSize: '13pt',
    },
    passwordInput: {
        WebkitBoxShadow: '0 0 0 1000px #016895 inset',
        '&:-webkit-autofill': {
            color: 'rgb(255, 255, 255) !important',
        },
        WebkitTextSecurity: 'disc',
        fontSize: '25pt',
        padding: '0',
        '&::-webkit-input-placeholder': {
            fontSize: '13pt',
            padding: '0',
            position: 'absolute',
            top: '10px',
        }
    },
    hintStyle: {
        zIndex: '1'
    },
    forgotPassword: {
        color: '#FFFFFF',
        textDecoration: 'none',
        fontSize: '9pt'
    }
}));

const Credentials = () => {
    const [state, dispatch] = useContext(Context);
    const usernameElement = useRef(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        setShow(true);
    }, []);

    useEffect(() => {
        setDisabled(!(username && password) || state.authenticated);
    }, [username, password, state.authenticated])

    useEffect(() => {
        if (state.loginPageLoaded) {
            if (usernameElement.current && usernameElement.current.children[1] && usernameElement.current.children[1].children[0].matches(':-internal-autofill-selected')) {
                setDisabled(false);
            }
        }
    }, [state.loginPageLoaded])

    const handleLogin = () => {
        setError(false);

        api.post(state.host + url, { userName: username, password: password })
            .then(response => {
                let result = response.data;
                console.log("logged in...", result)

                switch (result.authenticated) {
                    case true:
                        result.user.menuItemPermissions.push("View Equipment On Hire");
                        if (result.user.invoiceApproval && result.user.invoiceApproval != "None") {
                            if (!result.user.menuItemPermissions.includes("Authorize Rental Invoice")) {
                                result.user.menuItemPermissions.push("Authorize Rental Invoice");
                            }
                        } else {
                            result.user.menuItemPermissions = result.user.menuItemPermissions.filter(value => { return value !== "Authorize Rental Invoice"; })
                        }

                        dispatch({
                            type: "LOGGED-IN",
                            payload: {
                                headers: {
                                    Authorization: `Bearer ${result.token}`
                                },
                                user: {
                                    ...result.user
                                }
                            }
                        });
                        break;

                    case false:
                        setError(true);
                        break;
                }

            });
    }

    return (
        <Collapse
            in={show}
            {...(show ? { timeout: 1000 } : {})}
            // onEntered={() => usernameElement.current.click()}
            collapsedHeight={50}
        >
            <Grid
                container
                spacing={1}
                className={classes.loginCard}
            >
                <Grid
                    item
                    xs={12}
                >
                    <TextField
                        InputProps={
                            {
                                autoComplete: "new-password"
                            }
                        }
                        InputProps={{ classes: { root: classes.inputStyle } }}
                        inputProps={{className: classes.input}}
                        placeholder="USERNAME"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                        ref={usernameElement}
                        hintStyle={classes.hintStyle}
                        autoFocus
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <TextField
                        InputProps={{ classes: { root: classes.inputStyle } }}
                        inputProps={{className : classes.passwordInput }}
                        placeholder="PASSWORD"
                        type="password"react 
                        fullWidth
                        value={password}
                        hintStyle={classes.hintStyle}
                        onChange={event => setPassword(event.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                <Button
                    type="submit"
                    disabled={disabled}
                    onClick={handleLogin}
                    className={classes.loginButton}
                >
                    {state.authenticated ? "LOGGED IN" : "LOGIN"}
                    </Button>
                </Grid>
                <LoginError show={error} />
            </Grid>

            
        </Collapse>
    )
}

export default Credentials;