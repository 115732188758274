import { useState } from "react";

const initial = {
    cleaned: null,
    pickup: null,
    biohazard: null,
    notes: "",
    dateOfExchange: Date.now(),
    newModel: "",
    newSerialNo: null,
    reason: ""
}

const useSetExchangeQuestions = () => {
    const [details, setDetails] = useState(initial);
    const [completed, setCompleted] = useState(false);

    const onChange = (question) => {
        setDetails(prev => {
            let newValue = { ...prev, ...question };

            if (newValue.biohazard && newValue.dateOfExchange && newValue.newModel && newValue.reason) {
                if (newValue.biohazard === "Yes") {
                    if (newValue.notes) {
                        setCompleted(true);
                    }
                    else {
                        setCompleted(false);
                    }
                    return newValue;
                }

                setCompleted(true);
            }
            else{
                setCompleted(false);
            }

            return newValue;
        });
    }

    const reset = () => {
        initial.dateOfExchange = Date.now();
        setDetails(initial);
        setCompleted(false);
    }

    return [details, completed, onChange, reset];
}

export default useSetExchangeQuestions;