import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from './table-styling';

const useStyles = makeStyles((theme) => ({
    title: {
        height: 36,
        fontSize: "12pt",
    },
}));

const TitleRow = ({children, ...props}) => {
    const classes = useStyles();

    return (
        <TableRow>
            <StyledTableCell
                align="center"
                className={classes.title}
                {...props}
            >
                {children}
            </StyledTableCell>
        </TableRow>
    )
}

export default TitleRow;