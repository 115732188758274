import { FormControlLabel, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    section: {
        // borderBottom: "1px solid " + theme.formHeading.color,
        //marginBottom: ".5rem"
    },
    heading: {
        ...theme.formHeading,
    }
}))

const Section = ({ title, onClick, iconRight, className, children }) => {
    const classes = useStyles();

    const noOp = () => { };

    return (
        <>
            <Grid
                container
                item
                xs={12}
                className={className}
                alignItems="center"
                onClick={() => onClick ? onClick() : noOp()}
            >
                {!Boolean(iconRight) && (
                    <Typography variant="subtitle2">
                        {title}
                    </Typography>
                )}

                {Boolean(iconRight) && (
                    <FormControlLabel
                        control={iconRight}
                        labelPlacement="start"
                        label={<Typography variant="subtitle2">
                            {title}
                        </Typography>}
                    />
                )}
            </Grid>
            
            {Boolean(children) && children}
        </>
    )
}

export default Section;