import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { Context } from '../../Portal'

const SetCustomer = ({ show, onExit, xs }) => {
    const [state, dispatch] = useContext(Context);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [selectedShipTo, setSelectedShipTo] = useState({});

    useEffect(() => {
        setSelectedCustomer(state.selectedCustomer);
        setSelectedShipTo(state.selectedShipTo);
    }, [state.selectedCustomer, state.selectedShipTo])

    const handleCustomerSelected = (customer) => {
        setSelectedCustomer(customer);

        if (customer.shippingAddresses.length > 0) {
            setSelectedShipTo(customer.shippingAddresses[0]);
        }
        else {
            setSelectedShipTo({});
        }
    }

    const handleShipToSelected = (customer, shipTo) => {
        setSelectedCustomer(customer);
        setSelectedShipTo(shipTo);
    }

    const handleClick = (event) => {
        event.stopPropagation();
        dispatch({ type: "PROFILE-CUSTOMER-SELECTED", payload: { selectedCustomer: selectedCustomer, selectedShipTo: selectedShipTo } });
        onExit();
    }

    return (
        show && state.user && (
            <Grid
                container
                spacing={3}
                justify="center"
                alignContent="center"
                style={{ marginTop: "2em" }}
            >

                {state.user.customers.map(customer => {
                    return (
                        <Grid
                            item
                            xs={8}
                            key={customer.no}
                        >
                            <FormControlLabel
                                label={customer.name}
                                control={
                                    <Checkbox
                                        checked={customer.no === selectedCustomer.no}
                                        onChange={() => handleCustomerSelected(customer)}
                                    />}
                            />
                            {customer.shippingAddresses.map(shipTo => {
                                return (
                                    <Grid
                                        container
                                        item
                                        xs={Boolean(xs) ?  xs : 6}
                                        justify="flex-end"
                                        key={shipTo.shipToCode}
                                    >
                                        <FormControlLabel
                                            label={shipTo.name}
                                            control={
                                                <Checkbox
                                                    checked={customer.no === selectedCustomer.no && shipTo.shipToCode === selectedShipTo.shipToCode}
                                                    onChange={() => handleShipToSelected(customer, shipTo)}
                                                />}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })}

                <Grid
                    item
                    xs={8}
                >
                    <Button
                        type="submit"
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={handleClick}
                    >
                        Select customer
                    </Button>
                </Grid>
            </Grid>
        )
    )
}

export default SetCustomer
