import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import { Button, Chip, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import datatableStyles from '../../static/datatables-styles';
import { ContactSupportTwoTone, Warning, WarningTwoTone } from '@material-ui/icons';
import { api, Context } from '../../Portal';
import Popover from '../../components/Popover';
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
    queryForm: {
        height: '100%',
        justifyContent: 'space-between',
        flexFlow: 'column nowrap',
    },
    queryFormContent: {
        height: '100%',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
    },
    queryFormFields: {
        padding: '10px',
    },
    buttonRow: {
        justifyContent: 'flex-end',

    },
    saveButton: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        backgroundColor: '#26A9E1',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
}));

var url = "/api/v1/invoices";
// var queryUrl = "/api/v1/invoices";

function CostCentreInvoiceApproval() {
    const [state, dispatch] = useContext(Context);
    const [invoices, setInvoices] = useState([]);
    const [open, setOpen] = useState(false);
    const [queryRow, setQueryRow] = useState({});
    const [resolved, setResolved] = useState("No");
    const [message, setMessage] = useState("");
    const [history, setHistory] = useState("");
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [verifyError, setVerifyError] = useState("")
    const [popupMode, setPopupMode] = useState("full");

    const browserHistory = useHistory();
    const classes = useStyles();

    const columns = [
        {
            name: 'Month',
            sortable: true,
            selector: "month",
        },
        {
            name: 'Ward',
            sortable: false,
            selector: "ward",
        },
        {
            name: 'Cost Centre',
            sortable: false,
            selector: "costCentre",
        },
        {
            cell: row => (
                <>
                    {row.invoiceNo}
                    {row.overDue && <Chip
                        label="Overdue"
                        color="secondary"
                        variant="default"
                        size="small"
                        style={{ marginLeft: "8px" }} />}
                </>
            ),
            name: "Invoice Number"
        },
        {
            name: 'Total',
            selector: 'total'
        },
        {
            cell: row => (
                <>
                    {!row.verified &&
                        <Button
                            type="button"
                            onClick={() => onVerify(row)}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={row.canVerify}
                        >
                            Approve
                        </Button>}
                </>
            )
        }
    ];

    const lineColumns = [
        {
            cell: row => {

                if (row.status === "Query") {
                    return (
                        <Warning style={{ color: "#ffba08" }} />
                    );
                }

                if (!row.openQueries && (row.status === "Open" || row.status == "Verified")) {
                    return (
                        <ContactSupportTwoTone style={{ color: "#4361ee" }} />
                    );
                }

                if (row.status === "Open") {
                    return null;
                }

            },
            width: "40px"
        },
        {
            name: 'Cost Centre',
            sortable: true,
            selector: "costCentre",
            center: false,
        },
        {
            name: 'Rental Order',
            sortable: true,
            selector: "rentalOrder",
            center: false,
            compact: true,
        },
        {
            name: 'Model',
            sortable: true,
            selector: "model",
            center: false,
				},
				{
            name: 'Serial No.',
            sortable: true,
            selector: "serialNo",
            center: false,
            compact: true,
        },
        {
            name: 'Patient',
            sortable: true,
            selector: "patientFullName",
            center: false,
        },
        {
            name: 'Patient No.',
            sortable: true,
            selector: "patientNo",
            center: false,
        },
        {
            name: 'From',
            sortable: true,
            selector: "dateFrom",
            center: false,
            compact: true,
        },
        {
            name: 'To',
            sortable: true,
            selector: "dateTo",
            center: false,
            compact: true,
        },
        {
            name: 'Days',
            sortable: true,
            selector: "days",
            width: "4em",
            center: true,
            compact: true,
        },
        {
            name: 'Rate',
            sortable: true,
            selector: "rate",
            right: true,
            center: false,
            compact: true,
        },
        {
            name: 'Total',
            sortable: true,
            selector: "total",
            right: true,
            center: false,
            compact: true,
        },
        {
            name: 'Status',
            sortable: true,
            selector: "status",
            center: true,
        },
        {
            cell: row => {
                if (row.status === "Verified") {
                    return (
                        <>
                            <Button
                                type="button"
                                onClick={() => onViewHistory(row)}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                                               History
                            </Button>
                        </>
                    );
                }

                return (
                    <>
                        <Button
                            type="button"
                            onClick={() => onCreateQuery(row)}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Query
                        </Button>
                    </>
                );
            },
            grow: 1.25,
        },
    ];
    const ExpandableComponent = ({ data }) => {
        return (
            <DataTable
                persistTableHead
                dense
                pointerOnHover
                highlightOnHover
                noHeader
                striped
                customStyles={datatableStyles}
                columns={lineColumns}
                data={data.details}
                style={{ width: "97.2%", maxWidth: "97.2%", float: "right", marginBottom: "2em" }}
            />
        );
    };

    const ResetViewButton = () => {
        return (
            <Button
                onClick={() => onResetView()}
                type="button"
                variant="contained"
                color="primary"
                size="small"
            >
                Back to top</Button>
        );
    }

    const NavigateUpButton = () => {
        return (
            <Button
                onClick={() => onNavigateUp()}
                type="button"
                variant="contained"
                color="primary"
                size="small"
            >
                Up one level</Button>
        );
    }

    const isDrillDown = () => {
        return state.user.invoiceApproval === "Facility" || state.user.invoiceApproval === "District" || state.user.invoiceApproval == "Division" || state.user.invoiceApproval === "Customer";
    }

    const onResetView = () => {
        var payload = defaultPayload();
        dispatch({
            type: "INVOICESUMMARY-LINE-SELECTED",
            payload: payload
        });
        browserHistory.push('/invoices/approve');
    }

    const handleOkClick = () => {
        setVerifyOpen(false);
    }


    const onNavigateUp = () => {
        var payload = browseUpPayload();
        if (state.user.invoiceApproval === "Division") {
            payload = defaultPayload();
        }
        dispatch({
            type: "INVOICESUMMARY-LINE-SELECTED",
            payload: payload
        });
        browserHistory.push('/invoices/approve');
    }

    const browseUpPayload = () => {
        if (state.user.selectedInvoiceDivision !== "") {
            return {
                selectedInvoiceLevel: "Division",
                selectedInvoiceNo: state.user.selectedInvoiceNo,
                selectedInvoiceDistrict: state.user.selectedInvoiceDistrict,
                selectedInvoiceFacility: state.user.selectedInvoiceFacility,
                selectedInvoiceDivision: state.user.selectedInvoiceDivision,
                selectedInvoiceWard: "",
                selectedInvoiceCostCentre: ""
            }
        } else {
            return {
                selectedInvoiceLevel: "Facility",
                selectedInvoiceNo: state.user.selectedInvoiceNo,
                selectedInvoiceDistrict: state.user.selectedInvoiceDistrict,
                selectedInvoiceFacility: state.user.selectedInvoiceFacility,
                selectedInvoiceDivision: "",
                selectedInvoiceWard: "",
                selectedInvoiceCostCentre: ""
            }
        }
    }
    const defaultPayload = () => {
        return {
            selectedInvoiceLevel: "",
            selectedInvoiceNo: "",
            selectedInvoiceDistrict: "",
            selectedInvoiceFacility: "",
            selectedInvoiceDivision: "",
            selectedInvoiceWard: "",
            selectedInvoiceCostCentre: ""
        }
    }

    useEffect(() => {
        console.log("state...", state);
			api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${state.user.selectedInvoiceNo}`, state.credentials)
				.then(({ data }) => {
					console.log("data...", data);
					setInvoices(data);
				})
				.catch((error) => {
					console.log("error...", error);
				});
    }, []);

    const refreshData = () => {
        console.log("state...", state);
        api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${state.user.selectedInvoiceNo}`, state.credentials)
            .then(({ data }) => {
                console.log("data...", data);
                setInvoices(data);
						})
					.catch((error) => {
						console.log("error...", error);
					});
    }

    const onCreateQuery = (row) => {
        setOpen(true);
        api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${row.invoiceNo}&invoiceLineNo=${row.invoiceLineNo}`, state.credentials)
            .then(({ data }) => {
                setHistory(data.history);
            });

        setQueryRow(row);
        setPopupMode("full");
    };

    const onViewHistory = (row) => {
        setOpen(true);
        api.get(state.host + url + `?level=${state.user.selectedInvoiceLevel}&district=${state.user.selectedInvoiceDistrict}&facility=${state.user.selectedInvoiceFacility}&division=${state.user.selectedInvoiceDivision}&ward=${state.user.selectedInvoiceWard}&costcentre=${state.user.selectedInvoiceCostCentre}&invoiceNo=${row.invoiceNo}&invoiceLineNo=${row.invoiceLineNo}`, state.credentials)
            .then(({ data }) => {
                setHistory(data.history);
            });

        setQueryRow(row);
        setPopupMode("history");
    };

    const onVerify = (row) => {
        let post = {
            invoiceNo: row.invoiceNo,
            level: "CostCentre",
            district: state.user.selectedInvoiceDistrict,
            facility: state.user.selectedInvoiceFacility,
            division: state.user.selectedInvoiceDivision,
            ward: row.ward,
            costcentre: row.costCentre
        };

        api.post(state.host + url + "/verify", post, state.credentials)
            .then(({ data }) => {
                console.log("verify...", data);
                refreshData();
            });
    };

    const onQuery = () => {
        console.log("Row...", queryRow);
        let post = {
            invoiceNo: queryRow.invoiceNo,
            invoiceLineNo: queryRow.invoiceLineNo,
            message: message,
            resolved: resolved
        };

        api.post(state.host + url + "/query", post, state.credentials)
            .then((res) => {
                var data = JSON.parse(res.config.data);
                setOpen(false);
                setResolved(data.resolved);
                setMessage("");
                refreshData();
            });
    };

    const onQueryCancelled = () => {
        setOpen(false);
        setResolved("No");
        setMessage("");
    };

    const handleResolved = (event) => {
        setResolved(event.target.value);
    };

    return (
        <>
            <DataTable
                persistTableHead
                dense
                pointerOnHover
                highlightOnHover
                noHeader={!isDrillDown()}
                title={<><ResetViewButton /> <NavigateUpButton /></>}
                striped
                customStyles={datatableStyles}
                columns={columns}
                data={invoices}
                expandableRows
                expandableRowDisabled={row => row.disabled}
                expandableRowsComponent={<ExpandableComponent />}
								expandableRowExpanded={row => row.expanded || isDrillDown()}
            />
            <ConfirmationDialog
                open={verifyOpen}
                title={"ERROR VERIFYING"}
                variant={"info"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                description={verifyError}
                onSubmit={handleOkClick}
            />
            <Popover
                open={open}
                showTitle={true}
                onExited={() => { } }
            >
                <Grid
                    container
                    className={classes.queryForm}
                >
                    <Grid
                        container
                    >
                        <DataTable
                            persistTableHead
                            dense
                            noHeader
                            striped
                            customStyles={datatableStyles}
                            columns={[...lineColumns].splice(0, lineColumns.length - 1)}
                            data={[queryRow]}
                            style={{ marginBottom: "2em" }} />
                    </Grid>
                    <Grid
                        container
                        className={classes.queryFormContent}
                    >
                        <Grid
                            container
                            spacing={2}
                            className={classes.queryFormFields}
                        >
                            {popupMode === "full" && (
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <TextField
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        label="New Message"
                                        multiline
                                        rows={20}
                                        fullWidth
                                        value={message}
                                        onChange={event => setMessage(event.target.value)} />
                                    <FormLabel
                                        component="legend"
                                        style={{ marginTop: "1em" }}
                                    >
                                                            Resolved
                                    </FormLabel>
                                    <RadioGroup
                                        value={resolved}
                                        onChange={handleResolved}
                                        row
                                    >
                                        <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={6}
                            >
                                <TextField
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    label="History"
                                    multiline
                                    rows={20}
                                    fullWidth
                                    value={history}
                                    disabled />
                            </Grid>
                        </Grid>
                            {popupMode === "full" && (
                                <Grid
                                    container
                                    className={classes.buttonRow}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                    <Button
                                        onClick={onQuery}
                                            disabled={message.length === 0}
                                            className={classes.saveButton}
                                    >
                                            Save
                                    </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                    <Button
                                        onClick={onQueryCancelled}
                                        className={classes.cancelButton}
                                    >
                                            Cancel
                                    </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {popupMode === "history" && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={onQueryCancelled}
                                    style={{ marginLeft: "8px" }}
                                >
                                    Ok
                                </Button>
                            )}
                    </Grid>
                </Grid>

            </Popover>
        </>
    );
}

export default CostCentreInvoiceApproval;