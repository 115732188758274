import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from '../../Portal';
import ConfirmHighEnd from './ConfirmHighEnd';
import ApproveOrder from './ApproveOrder';

const useStyles = makeStyles((theme) => ({}));

const Authorisation = ({show, onExited, staff, contact, requestor }) => {
    const [state, dispatch] = useContext(Context);

    switch (state.customerDetail?.rentalAuthorizationType) {
        case "High-End - Question Only":
            return (
                <ConfirmHighEnd
                    show={show}
                    message={"High end equipment is included in this rental order and will affect the total cost for this rental agreement. Please review and accept below."}
                    onExited={onExited}
                />
            );

        case "High-End - Authorization":
            return (
                <ApproveOrder
                    show={show}
                    staff={staff}
                    contact={contact}
                    requestor={requestor}
                    message={"High end equipment is included in this rental order and will affect the total cost for this rental agreement. Please review and authorise below."}
                    onExited={onExited}
                />
            );

        case "All Authorization":
            return (
                <ApproveOrder
                    show={show}
                    staff={staff}
                    contact={contact}
                    requestor={requestor}
                    message={"Please review and authorise below."}
                    onExited={onExited}
                />
            );

        default:
            return null;
    }
}

export default Authorisation;