import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { Checkbox, makeStyles, Button } from "@material-ui/core";
import { AllInclusive, DeleteForeverTwoTone } from "@material-ui/icons";
import { api, Context } from "../../Portal";
import FieldGroup from '../../components/controls/FieldGroup';
import useCustomerInformation from '../../hooks/useCustomerInformation';
import DropdownField from '../../components/controls/DropdownField';
import BaseDataTable from "../../components/BaseDataTable";
import Actions from "./Actions";
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const url = "/api/v1/equipment/";

const useStyles = makeStyles((theme) => ({
    deleteIcon: {
        color: '#016895',
        paddingLeft: '0px',
        marginLeft: '-10px',
        cursor: 'pointer',
    },
}));

const ViewEquipmentOnHire = () => {
    const [state, dispatch] = useContext(Context);
    const [information, costCentreRequired, customers, wards, costCentres, onCustomerSelected] = useCustomerInformation();
    const [showConfirmation, setShowConfirmation] = useState({ rentalCancelled: false, exchangeCancelled: false, transferCancelled: false, approvalCancelled: false, genericCancelled: false });
    const [rows, setRows] = useState([]);
    const [cells, setCells] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
	const [toggleClearRows, setToggleClearRows] = useState(false);

    const formMethods = useForm();
    const classes = useStyles();

    useEffect(() => {
        buildCells();
        setSelectedRows([]);
        setRows([])

        return () => {
            dispatch({ type: "CLEAR-CUSTOMER-SELECTION" });
            setRows([]);
        }
    }, []);

    useEffect(() => {
		if (Boolean(state.selectedCustomer)) {
			get();
        }
    }, [state.selectedCustomer]);

    const get = () => {
        setSelectedRow([]);
        setToggleClearRows(prev => !prev);
        api.get(state.host + url, state.credentials)
            .then(({ data }) => {
                setRows(data.rentalLines);
            });
    }

    const delayGet = () => {
			setTimeout(function () {
			if (Boolean(state.selectedCustomer)) {
				get();
			}
        }, 200);
    }

    const handleSelectedRows = (state) => {
        setSelectedRows(state.selectedRows);
    }

    const buildCells = () => {
        setCells([
            {
                name: 'Ward',
                selector: 'ward',
                sortable: true,
            },
            {
                name: 'Patient',
                selector: 'patientName',
                sortable: true,
            },
            {
                name: 'Patient No',
                selector: 'patientNo',
                sortable: true,
            },
            {
                name: 'Started',
                selector: 'startDate',
								sortable: true,
								sortFunction: customColumnSort,
            },
            {
                name: 'Bed',
                selector: 'bed',
                sortable: true,
            },
            {
                name: 'Model',
                selector: 'model',
                sortable: true,
            },
            {
                name: 'Serial No',
                selector: 'serialNo',
                sortable: true,
						},
						{
                name: 'Rental Order No.',
								selector: 'rentalOrderNo',
                sortable: true,
            },
            {
                name: "Status",
                selector: "status",
                sortable: true,
            },
            {
                name: '',
                selector: '',
                sortable: false,
                width: '20px',
                cell: (row) => {
                    if (canCancelRow(row)) {
                        return (
                            <div
                                className={classes.deleteIcon}
                                title="Cancel Item"
                            >
                                <FontAwesomeIcon icon={faTimesCircle}
                                    color="primary"
                                    onClick={() => handleCancelButtonClicked(row)}
                                >
                                    <DeleteForeverTwoTone />
                                </FontAwesomeIcon>
                            </div>
                        )
                    }
                },
            },
        ]);
    };

	const customColumnSort = (rowA, rowB) => {
		var datePartsA = rowA.startDate.split("/");
		var datePartsB = rowB.startDate.split("/");
		if (rowA.startDate.trim().length == 0) {
			datePartsA = "01/01/1900".split("/");
		};
		if (rowB.startDate.trim().length == 0) {
			datePartsB = "01/01/1900".split("/");
		};
		
		return dates.compare(new Date(datePartsA[2], datePartsA[1] - 1, datePartsA[0]), new Date(datePartsB[2], datePartsB[1] - 1, datePartsB[0]));
	};

	var dates = {
		convert: function (d) {
			// Converts the date in d to a date-object. The input can be:
			//   a date object: returned without modification
			//  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
			//   a number     : Interpreted as number of milliseconds
			//                  since 1 Jan 1970 (a timestamp) 
			//   a string     : Any format supported by the javascript engine, like
			//                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
			//  an object     : Interpreted as an object with year, month and date
			//                  attributes.  **NOTE** month is 0-11.
			return (
				d.constructor === Date ? d :
					d.constructor === Array ? new Date(d[0], d[1], d[2]) :
						d.constructor === Number ? new Date(d) :
							d.constructor === String ? new Date(d) :
								typeof d === "object" ? new Date(d.year, d.month, d.date) :
									NaN
			);
		},
		compare: function (a, b) {
			// Compare two dates (could be of any type supported by the convert
			// function above) and returns:
			//  -1 : if a < b
			//   0 : if a = b
			//   1 : if a > b
			// NaN : if a or b is an illegal date
			// NOTE: The code inside isFinite does an assignment (=).
			return (
				isFinite(a = this.convert(a).valueOf()) &&
					isFinite(b = this.convert(b).valueOf()) ?
					(a > b) - (a < b) :
					NaN
			);
		},
		inRange: function (d, start, end) {
			// Checks if date in d is between dates in start and end.
			// Returns a boolean or NaN:
			//    true  : if d is between start and end (inclusive)
			//    false : if d is before start or after end
			//    NaN   : if one or more of the dates is illegal.
			// NOTE: The code inside isFinite does an assignment (=).
			return (
				isFinite(d = this.convert(d).valueOf()) &&
					isFinite(start = this.convert(start).valueOf()) &&
					isFinite(end = this.convert(end).valueOf()) ?
					start <= d && d <= end :
					NaN
			);
		}
	}
    const handleCancelButtonClicked = (row) => {
        if (row.status === "Pending Exchange In" || row.status === "Pending Exchange Out") {
            handleExchangeCancel(row);
        } else if (row.status === "Pending Transfer In" || row.status === "Pending Transfer Out") {
            handleTransferCancel(row);
        } else if (row.status === "Pending Approval") {
            handleApprovalCancel(row);
        } else if (row.status === "Ready to deliver") {
            handleRentalOrderCancel(row);
        } else {
            handleGenericCancel(row);
        }
    }

	const handleApprovalCancel = (row) => {
		dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: state.user.customers[0].no } });
        api.delete(state.host + url + `transaction/${row.rentalOrderNo}/${row.rentalLineNo}`, state.credentials)
            .then(({ data }) => {
                delayGet();
            });
    }

	const handleGenericCancel = (row) => {
		dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: state.user.customers[0].no } });
        api.delete(state.host + url + `transaction/${row.rentalOrderNo}/${row.rentalLineNo}`, state.credentials)
            .then(({ data }) => {
                delayGet();
            });
    }

	const handleTransferCancel = (row) => {
		dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: state.user.customers[0].no } });
        api.delete(state.host + url + `transaction/${row.rentalOrderNo}/${row.rentalLineNo}`, state.credentials)
            .then(({ data }) => {
                delayGet();
            });
    }

	const handleExchangeCancel = (row) => {
		dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: state.user.customers[0].no } });
        api.delete(state.host + url + `transaction/${row.rentalOrderNo}/${row.rentalLineNo}`, state.credentials)
            .then(({ data }) => {
                delayGet();
            });
    }

	const handleRentalOrderCancel = (row) => {
		dispatch({ type: "CUSTOMER-SELECTED", payload: { selectedCustomer: state.user.customers[0].no } });
        api.delete(state.host + url + `transaction/${row.rentalOrderNo}/${row.rentalLineNo}`, state.credentials)
            .then(({ data }) => {
                delayGet();
            });
    }

    const onOk = () => {
        delayGet();
    }

    const setSelectableRows = (row) => {
        return row.status !== "Rented";
    }

    const canCancelRow = (row) => {
        return row.status !== "Rented";
    }

    const conditionalRowStyles = [
        {
            when: row => row.selected,
            style: {
                backgroundColor: '#016895',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#016895',
                    color: 'white',
                },
                '& svg path': {
                    fill: '#ffffff',
                }
            },
        },
    ];

    const onRowClicked = (row) => {
        let hasSelection = false;
        const updatedRows = rows.map(item => {
            if (row.rentalOrderNo === item.rentalOrderNo && row.rentalLineNo === item.rentalLineNo) {
                let newRow = {
                    ...item,
                    selected: item.selected ? !item.selected : true
                };
                hasSelection = true;
                setSelectedRow(newRow);
                return newRow;
            }
            let newRow = {
                ...item,
                selected: false
            };
            return newRow;
            
        });
        if (!hasSelection) { setSelectedRow({}); }
        setRows(updatedRows);
    }

    const onRowDoubleClicked = (row) => {
        const updatedRows = rows.map(item => {
            if (row.rentalOrderNo === item.rentalOrderNo && row.rentalLineNo === item.rentalLineNo) {
                setSelectedRow(row);
                return {
                    ...item,
                    selected: true
                };
            }

            item.selected = false;
            return item;

        });

        setRows(updatedRows);
    }

    return (
        <FormProvider {...formMethods}>
            <FieldGroup
                fields={[{ columnCaption: "Customer Information" }]}
                style={{ marginBottom: 14 }}
                classes={classes}
            >
                <DropdownField
                    id="select-customer"
                    label="Customer"
                    onChange={onCustomerSelected}
                    name="CUSTOMER"
                    required
                    value={information.CUSTOMER}
                    options={customers.map(customer => ({ value: customer.no, description: customer.name }))}
                    xs={12}
                />
            </FieldGroup>

            <BaseDataTable
                cells={cells}
                rows={rows}
                actions={<Actions rows={selectedRows} row={selectedRow} onExited={delayGet} />}
                onRowClicked={onRowClicked}
                onRowDoubleClicked={onRowDoubleClicked}
                onRowsSelected={handleSelectedRows}
                clearSelectedRows={toggleClearRows}
                selectableRowDisabled={setSelectableRows}
                conditionalRowStyles={conditionalRowStyles}
                selectableRows
            />

            <ConfirmationDialog
                open={showConfirmation.rentalCancelled}
                onSubmit={onOk}
                title={"RENTAL ORDER CANCELLED"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                cancelButtonText={""}
                variant={"info"}
                description={"Rental order has been cancelled."}
            />
            <ConfirmationDialog
                open={showConfirmation.exchangeCancelled}
                onSubmit={onOk}
                title={"EXCHANGE RENTAL ORDER CANCELLED"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                cancelButtonText={""}
                variant={"info"}
                description={"Rental order exchange has been cancelled."}
            />
            <ConfirmationDialog
                open={showConfirmation.transferCancelled}
                onSubmit={onOk}
                title={"TRANSFER RENTAL ORDER CANCELLED"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                cancelButtonText={""}
                variant={"info"}
                description={"Rental order transfer has been cancelled."}
            />
            <ConfirmationDialog
                open={showConfirmation.approvalCancelled}
                onSubmit={onOk}
                title={"RENTAL ORDER CANCELLED"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                cancelButtonText={""}
                variant={"info"}
                description={"The rental order has been cancelled."}
            />
            <ConfirmationDialog
                open={showConfirmation.genericCancelled}
                onSubmit={onOk}
                title={"ITEM CANCELLED"}
                showOkButton={true}
                okButtonText={"OK"}
                showCancelButton={false}
                cancelButtonText={""}
                variant={"info"}
                description={"The Item has been cancelled."}
            />
        </FormProvider>
    )
}

export default ViewEquipmentOnHire;