import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
}));

const Banner = ({ item }) => {
    const classes = useStyles();

    return (
        <Card
            square
        >
            <CardMedia
                image={item.image}
                style={{ height: item.height }}
            />
        </Card>
    );
}

export default Banner;