import React from 'react';
import DataTable from 'react-data-table-component';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ArrowDownwardTwoTone } from '@material-ui/icons';

import datatableStyles from "../static/datatables-styles";

const useStyles = makeStyles((theme) => ({
    dataTable: {
        border: "1px solid " + theme.palette.primary.dark,
    },
}));


const BaseDataTable = ({ cells, rows, actions, onRowsSelected, onRowClicked, onRowDoubleClicked, ...props}) => {
    const classes = useStyles();

    return (
        <>
            <DataTable
                columns={cells}
                data={rows}
                onRowClicked={onRowClicked}
                onRowDoubleClicked={onRowDoubleClicked}
                sortIcon={<ArrowDownwardTwoTone />}
                persistTableHead
                dense
                pointerOnHover
                highlightOnHover
								noHeader
								enablePagination = {false}
                striped
                customStyles={datatableStyles}
                selectableRows
                selectableRowsNoSelectAll
                selectableRowsHighlight
                onSelectedRowsChange={onRowsSelected}
                className={classes.dataTable}
                {...props}
            />
            <Grid
                container
                direction="row"
                justify="flex-start"
                style={{ marginTop: 14 }}
            >
                {actions}
            </Grid>
        </>
    )
}

export default BaseDataTable;