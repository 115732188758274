import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '1px 2px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 4,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    requiredBorder: {
        margin: '5px',
        width: "300px",
        border: "1px solid #f44336",
    },
    defaultBorder: {
        margin: '5px',
        width: "300px",
        border: "1px solid rgba(1, 104, 149, .5)",
    },
    disabledBorder: {
        margin: '5px',
        width: "300px",
        border: "1px solid rgba(0, 0, 0, .38)",
    },
}));

const InputButton = ({ onClick, disabled, value, required, ...props }) => {
    const classes = useStyles();

    return (
        <Paper
            component="div"
            className={disabled ? [classes.disabledBorder, classes.root].join(' ') : (required && value === "") ? [classes.requiredBorder, classes.root].join(' ')  : [ classes.defaultBorder, classes.root ].join(' ') }
            elevation={0}
            variant="outlined"
            square
        >
            {/* <Divider className={classes.divider} orientation="vertical"/> */}
            <InputBase
                className={classes.input}
                disabled={disabled}
                value={value}

            // placeholder=""
            // inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton
                type="button"
                className={classes.iconButton}
                aria-label="search"
                onClick={onClick}
            >
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}

export default InputButton;