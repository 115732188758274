import { useHistory } from "react-router-dom";

const useRedirect = () => {
    const history = useHistory();

    const redirectToHome = () => {
        //TODO: redirect correctly based on user permissions
        history.replace("/");
    }

    const redirectToCreateRentalOrder = () => {
        history.push("/rental/start");
    }

    const redirectToApproval = (approveTransactionType) => {
        if (approveTransactionType === "Approve Transfer") {
            history.push("/rental/rental-transfer-in");
            return;
        }
        if (approveTransactionType === "Approve Exchange") {
            history.push("/rental/rental-exchange-approve");
            return;
        }

        history.push("/rental/approve");
    }

    const redirectToViewAuthorisations = () => {
        history.push("/rental/authorisations")
    }
    const redirectToInvoiceApproval = () => {
        history.push("/invoices/approve")
    }

    return {
        redirectToHome,
        redirectToCreateRentalOrder,
        redirectToApproval, 
        redirectToViewAuthorisations,
        redirectToInvoiceApproval,
    };
}

export default useRedirect;