import { makeStyles } from '@material-ui/core/styles';
import { Badge } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing.unit * 2
    },
    customBadge: {
        backgroundColor: "#00AFD7",
        color: "white"
    }
}));

const Notification = ({ count, icon }) => {
    const classes = useStyles();
    return (
        <Badge
            badgeContent={count}
            color="primary"
            max={20}
            >
            <FontAwesomeIcon icon={icon} />
        </Badge>
    )
}

export default Notification;