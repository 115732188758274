import { Switch, Route, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import AuthorisedRoute from "./authorisation/AuthorisedRoute";

import Paperbase from "./paperbase/Paperbase";
import Login from "../pages/login/login";

import CreateOrder from "../pages/create-order/create-order";
import Profile from "../pages/profile/profile";

import ViewAuthorisations from "../pages/view-authorisations/view-authorisations";
import ViewEquipmentOnHire from "../pages/view-eqipment-on-hire/view-equipment-on-hire";
import ConsignmentStock from "../pages/consignment-stock/consignment-stock";

import TransferIn from "../pages/rental-transfer-in/transfer-in";
import ApproveExchange from "../pages/rental-exchange-approve/exchange-approve";
import ApproveRental from "../pages/rental-approve/approve-rental";
import RentalStop from "../pages/rental-stop/rental-stop";
import RentalExchange from "../pages/rental-exchange/rental-exchange";
import RentalTransfer from "../pages/rental-transfer/rental-transfer";
import CostCentreInvoiceApproval from "../pages/invoice-approval/CostCentreInvoiceApproval";
import { Context } from "../Portal";
import { useContext } from "react";
import DivisionInvoiceApproval from "../pages/invoice-approval/DivisionInvoiceApproval";
import FacilityInvoiceApproval from "../pages/invoice-approval/FacilityInvoiceApproval";
import DistrictInvoiceApproval from "../pages/invoice-approval/DistrictInvoiceApproval";

let useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    }
}))

const ContentRoutes = ({ navigation }) => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const location = useLocation();
     
    return (
        <Switch>
            <Route exact path="/login">
                <Login className={classes.root} />
            </Route>

            <Route exact path={["/rental/:path?", "/profile", "/equipment/:path?", "/invoices/:path?", "/"]}>
                <Paperbase navigation={navigation}>
                    <AuthorisedRoute permission="Create Rental Order" exact path="/rental/start">
                        <CreateOrder className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Create Rental Order" exact path="/rental/stop">
                        <RentalStop className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Transfer Equipment" exact path="/rental/rental-transfer-in">
                        <TransferIn className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Exchange Equipment" exact path="/rental/rental-exchange-approve">
                        <ApproveExchange className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Transfer Equipment" exact path="/rental/transfer">
                        <RentalTransfer className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Create Rental Order" exact path="/rental/exchange">
                        <RentalExchange className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Authorize Rental Transaction" exact path="/rental/authorisations">
                        <ViewAuthorisations className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Create Rental Order" exact path="/equipment/consignment">
                        <ConsignmentStock className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute permission="Authorize Rental Transaction" exact path="/rental/approve">
                        <ApproveRental className={classes.root} />
                    </AuthorisedRoute>
                    <AuthorisedRoute exact path="/invoices/approve" >
                    {(state.user && (state.user.invoiceApproval === "Detailed" || state.user.invoiceApproval === "CostCentre" || state.user.selectedInvoiceLevel === "CostCentre")) && //Cost Center
                        <CostCentreInvoiceApproval className={classes.root} key={location.key} />
                    }
                        {(state.user && ((state.user.invoiceApproval === "Division" && state.user.selectedInvoiceLevel === "") || (state.user.selectedInvoiceLevel === "Division"))) && //Division
                        <DivisionInvoiceApproval classname={classes.root} key={location.key} />
                    }
                        {(state.user && ((state.user.invoiceApproval === "Facility" && state.user.selectedInvoiceLevel === "") || (state.user.selectedInvoiceLevel === "Facility"))) && //Facility
                        <FacilityInvoiceApproval classname={classes.root} key={location.key} />
                    }
                        {(state.user && ((state.user.invoiceApproval === "Customer" && state.user.selectedInvoiceLevel === "") || (state.user.selectedInvoiceLevel === "District"))) && //District
                        <DistrictInvoiceApproval classname={classes.root} key={location.key} />
                    }
                    </AuthorisedRoute>
                    <AuthorisedRoute exact path="/profile">
                        <Profile />
                    </AuthorisedRoute>
                    <AuthorisedRoute  exact path="/">
                        <ViewEquipmentOnHire />
                    </AuthorisedRoute>
                </Paperbase>
            </Route>
        </Switch>
    )
}

export { ContentRoutes };