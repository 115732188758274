import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardMedia, Grid, Typography } from '@material-ui/core';

import loremIpsum from "../static/lorem-ipsum";

const useStyles = makeStyles((theme) => ({
    actionArea: {
        width:"auto",
        height: '200px',
        transition: "all 0.2s linear",
        position: "relative",
        "& img": {
            transition: "all 0.2s linear",
            width: "auto",
        },
        "&:hover img": {
            transform: "scale(1.1)"
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modelDetails: {
        padding: '5px 15px',
    },
    mask: {
        width: "100%",
        height: "105%",
        position: "absolute",
        overflow: "hidden",
        top: 0,
        left: 0,
        opacity: 0,
        backgroundColor: "rgba(12, 19, 27, 0.6)",
        transition: "all 0.4s ease-in-out",
        "&:hover": {
            opacity: 1,
        },
        paddingTop: "10px",
        paddingBottom: "10px",
        "& button": {
            transform: "translateY(140px)",
            opacity: 0,
            transition: "all 0.4s linear",
            zIndex: 10000,
            color: "#fff",
            margin: "0 auto",
            border: "3px solid #fff",
            height: 36,
        },
        "&:hover button": {
            opacity: 1,
            transform: "translateY(45px)",
        },
    },
    cardAction: {
        backgroundColor: '#016895',
        padding: '5px 0 0 0',
    },
    modelName: {
        color: '#FFFFFF',
        fontSize: '13pt',
    },
    modelShortDescription: {
        color: '#FFFFFF',
        fontSize: '10pt',
    },
    buttonRow: {
        flexFlow: 'row nowrap',
    },
    selectProductButton: {
        backgroundColor: '#67A4C0',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        },
    },
    learnMoreButton: {
        backgroundColor: '#418DAF',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
}));

const ProductCard = ({ product, index, onChange, ...props }) => {
    const [description, setDescription] = useState("");
    const [model, setModel] = useState("");
    const [lorem, setLorem] = useState("");

    const classes = useStyles();

    let { MODEL, MODELDESCRIPTION, SHORTDESCRIPTION, image } = product;

    const handleHover = (model, description, index) => {
        setDescription(description);
        setModel(model);
        setLorem(loremIpsum[index % 5]);
    }

    return (
        <Card
            onMouseOver={() => handleHover(MODEL, MODELDESCRIPTION, index)}
            key={`${MODEL}-${index}`}
        >
            <div className={classes.actionArea} onClick={(event) => event.stopPropagation()}>
                <CardMedia
                    component="img"
                    image={image}
                    title={MODELDESCRIPTION}
                />
                <Grid
                    container
                    justify="center"
                    className={classes.mask}
                >
                </Grid>
            </div>
            <CardActions
                className={classes.cardAction}
            >
                <Grid
                    container
                    direction="column"
                >
                    <Grid
                        item
                        xs={12}
                        className={classes.modelDetails}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                component="p"
                                variant="body"
                                noWrap
                                className={classes.modelName}
                            >
                                {MODELDESCRIPTION}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                component="p"
                                variant="caption"
                                noWrap
                                className={classes.modelShortDescription}
                            >
                                {SHORTDESCRIPTION}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={classes.buttonRow}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <Button
                                className={classes.learnMoreButton}
                                >
                                LEARN MORE
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <Button
                                className={classes.selectProductButton}
                                onClick={(event) => {
                                    event.persist();
                                    if (event.defaultPrevented) {
                                        return;
                                    }
                                    event.stopPropagation();
                                    event.preventDefault();
                                    event.nativeEvent.stopPropagation();
                                    onChange(product);
                                }}
                            >
                                SELECT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default ProductCard;