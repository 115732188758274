import React from 'react';
import { Route } from 'react-router';

export default function CurrentPage({ navigation }) {
    return <Route>
        {({ location }) => {
            const children = [];
            navigation.forEach(x => children.push(...x.children));
            children.filter(x => x.children !== undefined).forEach(y => children.push(...y.children));
            const item = children.filter(x => x.route === location.pathname);
            const pageName = item && item.length > 0 ? item[0].id : '';
            return (
                <div>{pageName.toUpperCase()}</div>
            );
        }}
    </Route>
}
