import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles"

import { Avatar, Collapse, CssBaseline, FormControlLabel, Grid, Paper, Typography, Button, Link } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import Carousel from 'react-material-ui-carousel';
import uuid from "uuid-random";

import Header from './header';
import Banner from './Banner';
import Credentials from './Credentials';

import sideBanner from "../../assets/PEGAUS_HEALTH_ELEMENTS-01.png";
import pageFooterLogo from "../../assets/PEGASUS_PORTAL_LOGOS-02.png";
import pageFooterLogoLeft from "../../assets/Logo_2_1.png";
import pageFooterLogoRight from "../../assets/Logo_2_2.png";
import shim from "../../assets/1x1.gif";

import Promotion from './Promotion';
import { api, Context } from '../../Portal';
import useRedirect from '../../hooks/useRedirect';
import ScrollableAnchor from 'react-scrollable-anchor'


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
    },
    content: {
        width: "87.5%",
        marginLeft: "2.5%"
    },
    welcome: {
        padding: "1rem",
        borderTop: "4px solid #006db3",
    },
    loginRow: {
        backgroundColor: '#016895',
    },
    sideBanner: {
        backgroundImage: `url(${sideBanner})`,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
    },
    sideBannerContent: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        marginLeft: '15%',
        marginTop: '60px',
        verticalAlign: 'top',
        justifyContent: 'flex-start',
        color: '#FFFFFF',
    },
    sideBannerIntro: {
        fontSize: '20px',
        marginBottom: '30px',

    },
    sideBannerHeading: {
        fontSize: '26px',
        marginBottom: '20px',
    },
    sideBannerContentText: {
        fontSize: '20px',
    },
    sideBannerContentFineprint: {
        fontSize: '14px',
    },
    phone: {
        marginTop: "1rem",
    },
    callIcon: {
        margin: ".5rem"
    },
    infoTiles: {
        display: "flex",
        flex: 3,
        justifyContent: "space-between",
        "& > div:first-child": {
            marginRight: "10px",
        },
        "& > div + div": {
            marginRight: "6px",
            marginLeft: "6px",
        },
        "& > div:last-child": {
            marginRight: 0,
            marginLeft: "10px",
        },
    },
    middleBanner: {
        alignItems: 'center',
        padding: '50px 0',
    },
    bottomBanner: {
        alignItems: 'center',
        padding: '50px 0',
        backgroundColor: '#E7E8EA',
    },
    bannerHeading: {
        fontSize: '18pt',
        padding: '20px 0',
    },
    bannerContent: {
        width: '60%',
        fontSize: '14pt',
        textAlign: 'center',
        lineHeight: '2em',
        
    },
    bannerButton: {
        border: '1px solid #000000',
        fontSize: '14pt',
        height: '50px',
        width: '300px',
    },
    pageFooter: {
        backgroundColor: '#016895',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
    },
    pageFooterRows: {
        justifyContent: 'center',
        flexFlow: 'row nowrap',
        width: '70%',
    },
    pageFooterRow: {
        textAlign: 'left',
    },
    pageFooterRow1: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pageFooterLogoLeft: {
        textAlign: 'right',
    },
    pageFooterLogoRight: {
        textAlign: 'left',
    },
    pageFooterLogo: {
        height: '100px',
        marginLeft: '-280px',
    },
    pageFooterPhoneNumber: {
        fontSize: '30pt',
    },
    pageFooterRow2: {
        alignItems: 'center',
        padding: '10px 0',
        justifyContent: 'flex-start',
    },
    pageFooterRow3: {
        alignItems: 'center',
        padding: '10px 0',
        justifyContent: 'flex-start',
    },
    pageFooterAddress: {
        padding: '0 100px 0 0',
    },
    copyrightText: {
        fontSize: '11pt',
        marginLeft: '5px',
    },
    footerLoginLink: {
        color: '#ffffff',
        textDecoration: 'none',
    }
}));

const url = "/views/v1/login";
const approvalUrl = "/api/v1/rentalorders/waiting-approval";

const Login = () => {
    const [state, dispatch] = useContext(Context);
    const [promotions, setPromotions] = useState([]);
    const [banners, setBanners] = useState([]);
    const [loggingIn, setLoggingIn] = useState(false);
    const { redirectToHome } = useRedirect();
    const { redirectToInvoiceApproval } = useRedirect();
    const { redirectToViewAuthorisations } = useRedirect();
    const classes = useStyles();

    const Copyright = () => {
        return (
            <Typography className={classes.copyrightText} >
                &copy; { new Date().getFullYear()}  Pegasus Healthcare
            </Typography>
        )
    }


    useEffect(() => {
        api.get(state.host + url)
            .then(({ data }) => {
                setPromotions(data.tiles);
                setBanners(data.banners);
                dispatch({ type: "LOGINPAGE-LOADED" });
            })
    }, []);

    useEffect(() => {
        if (state.authenticated) {
            if (!loggingIn) {
                setLoggingIn(true);
                getAuthorisationCount();
            }
        }
    }, [state.authenticated, redirectToHome, redirectToViewAuthorisations, redirectToInvoiceApproval])

    const getAuthorisationCount = () => {

        api.get(state.host + approvalUrl, state.credentials)
            .then(({ data }) => {
                dispatch({ type: "UPDATE-AUTHORISATION-COUNT", payload: { authorisationCount: data.headers.length } });
                if (state.user.menuItemPermissions.indexOf("Authorize Rental Transaction") !== -1 && data.headers.length > 0) {
                    redirectToViewAuthorisations();
                } else if (state.user.menuItemPermissions.indexOf("Create Rental Order") !== -1) {
                    redirectToHome();
                } else if (state.user.menuItemPermissions.indexOf("Authorize Rental Invoice") !== -1) {
                    redirectToInvoiceApproval();
                } else if (state.user.menuItemPermissions.indexOf("Authorize Rental Transaction") !== -1) {
                    redirectToViewAuthorisations();
                } else {
                    redirectToHome();
                }
            })
            .catch((error) => {
                if (state.user.menuItemPermissions.indexOf("Create Rental Order") !== -1) {
                    redirectToHome();
                } else if (state.user.menuItemPermissions.indexOf("Authorize Rental Invoice") !== -1) {
                    redirectToInvoiceApproval();
                } else if (state.user.menuItemPermissions.indexOf("Authorize Rental Transaction") !== -1) {
                    redirectToViewAuthorisations();
                }
            });
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header />
				{banners && banners.length > 0 &&
					<Collapse
						in={banners.length > 0}
						{...(banners.length > 0 ? { timeout: 750 } : {})}
						collapsedHeight={50}
					>
						<Carousel
							indicatorIconButtonProps={{
								style: {
									padding: '1px',
									color: 'transparent',
									border: '1px solid #FFFFFF',
									margin: '5px',
								}
							}}
							activeIndicatorIconButtonProps={{
								style: {
									backgroundColor: 'rgb(1, 104, 149, 0.3)'
								}
							}}
							indicatorContainerProps={{
								style: {
									marginBottom: '50px',
									textAlign: 'left',
									bottom: '-20px',
									position: 'absolute',
									left: '250px'
								}
							}}
							navButtonsAlwaysInvisible={true}
						>
							{banners.map((banner) => (
								<Banner item={banner} key={uuid()} />
							))}
						</Carousel>
					</Collapse>
				}
            <ScrollableAnchor id={'login'}>
            <Grid
                className={classes.content}
                container
                direction="row"
                justify="space-between"
                className={classes.loginRow}
            >
                <Grid
                    item
                    xs={7}
                    className={classes.sideBanner}
                >
                    <div className={classes.sideBannerContent}>
                        <div className={classes.sideBannerIntro}>SECURE LOGIN</div>
                        <div className={classes.sideBannerHeading}>Online Rental Management System</div>
                        <div className={classes.sideBannerContentText}>Welcome to the Pegasus Online Management System</div>
                        <div className={classes.sideBannerContentFineprint}></div>
                    </div>
                            
                </Grid>
                <Grid
                    item
                    xs={4}

                >
                    <Credentials />
                </Grid>
                <Grid item xs={1} />
                </Grid>
				</ScrollableAnchor>
				{promotions && promotions.length > 0 &&
					<Grid
						container
						direction="column"
						justify="space-between"
						spacing={5}
						className={classes.middleBanner}
					>
						<Grid
							item
							xs={12}
							className={classes.infoTiles}
						>
							{promotions.map((promotion, index) => (<Promotion promotion={promotion} key={uuid()} />))}
						</Grid>
					</Grid>
				}
            <ScrollableAnchor id={'about'}>
            <Grid
                container
                direction="column"
                justify="space-between"
                spacing={5}
                className={classes.bottomBanner}
            >
                <Grid
                    item
                    xs={12}
                    className={classes.bannerHeading}
                    
                >
                    ABOUT US
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.bannerContent}
                    
                >
                    <div><strong>Pegasus Healthcare is Australia's leading supplier of high quality, clinically proven healthcare equipment solutions for the acute, community and residential care sectors.</strong></div>

                    <div>Our innovative healthcare equipment range includes solutions for pressure care, medical beds, falls prevention, mobility and bariatric care.</div>

                    <div>Pegasus is a trusted partner to health care providers, caregivers, and patients across Australia. Our high quality clinically proven products, first class service excellence and customer support ensures we are able to make a positive difference to the clinical outcomes for our customers.</div>

                </Grid>
                </Grid>
            </ScrollableAnchor>
            <ScrollableAnchor id={'contact'}>
            <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.pageFooter}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    className={classes.pageFooterRows}
                >
                    <Grid
                        item
                        xs={3}
                        className={classes.pageFooterLogoLeft}
                    >
                        <img src={shim} />
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        className={classes.pageFooterRow}
                    >
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            className={classes.pageFooterRow1}
                        >
                            <Grid
                                item
                                className={classes.pageFooterLogoRight}
                            >
                                <img className={classes.pageFooterLogo} src={pageFooterLogo} />
                            </Grid>
                            <Grid
                                item
                                className={classes.pageFooterPhoneNumber}
                            >
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            className={classes.pageFooterRow2}
                        >
                            <Grid
                                item
                                className={classes.pageFooterAddress}
                            >
                                Pegasus Health Group<br />
                                1/30 Heathcote Road, Moorebank, NSW 2170<br />
                                Telephone 1300 303 603
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            spacing={0}
                            className={classes.pageFooterRow3}
                            >
                                
                            <Grid
                                item
                                >
                                    <Link href='#login' className={classes.footerLoginLink}>Customer Login</Link> |
                            </Grid>
                            <Grid
                                item
                            >
                                <Copyright />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </ScrollableAnchor>
        </div>
    )
}

export default Login;
