import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Grid, Paper, Button, Link } from '@material-ui/core';

import logo from "../../assets/PEGASUS_PORTAL_LOGOS-01.png";
import background from "../../assets/pegasus-header-background.jpg";
import shim from "../../assets/1x1.gif";
import moment from 'moment';

const useStyles = makeStyles((theme) => (
    {
        image: {
            height: "6.5rem",
            width: "24rem",
            marginTop: "1rem",
            marginBottom: "1rem"
        },
        appbar: {
            background: "white",
            // backgroundImage: `url(${background})`,
        },
        welcomeBar: {
            background: theme.palette.primary.light,
        },
        welcomeBarLogo: {
            backgroundImage: `url(${logo})`,
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            height: '100px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        welcomeBarTop: {
            borderBottom: '3px solid #016895',
            height: '60px',
            flexFlow: 'row',
        },
        welcomeTime: {
            width: "100%",
            margin: "0 auto",
            color: '#000000',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            fontSize: '1.25rem',
            fontWeight: '500',
            lineHeight: '1.6',
            letterSpacing: '0.0075em',
        },
        welcomePhoneNumber: {
            width: "100%",
            margin: "0 auto",
            color: '#000000',
            paddingRight: '5pt',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
            fontSize: '1.25rem',
            fontWeight: '700',
            lineHeight: '1.6',
            letterSpacing: '0.0075em',
        },
        welcomeBarNavigation: {
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            paddingLeft: '10%',
            paddingRight: '10%',
        },
        welcomBarNavigationItem: {
            color: '#000000',
            textDecoration: 'none',
            textTransform: 'uppercase',
            fontSize: '14pt',
        },
        loginButtonTop: {
            height: '100%',
            width: '100%',
            margin: "0 auto",
            fontSize: '12pt',
            textTransform: 'uppercase',
        }
    }
));



const Header = () => {
    const classes = useStyles();
    return (
        <AppBar
            elevation={0}
            className={classes.appbar}
            position="static"
        >
            <Grid
                item
                xs={12}
            >
                    <Grid
                        container
                        className={classes.welcomeBarTop}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <Paper
                                elevation={0}
                                square
                                className={classes.welcomeTime}
                        >
                            {/*Sydney: Tue 7 September, 2021 | 03:12 PM*/}
                            {moment().format('LLLL')}
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <Paper
                                elevation={0}
                                square
                                className={classes.welcomePhoneNumber}
                            >
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                    >
                            <Link
                                href='#login'>
                                <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={classes.loginButtonTop}
                                >
                                    LOGIN
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={classes.welcomeBarNavigation}
                    >
                        <Grid
                        item
                        className={classes.welcomeBarLogo}
                        >
                        </Grid>
                    </Grid>
                </Grid>
        </AppBar>
    )
}

export default Header;