import React from 'react'
import { Grid, Typography } from '@material-ui/core'

export default function LoginError({ show }) {
    return (
        <Grid
            item
            xs={12}
        >
            {show &&
                <Typography variant="body2" color="error">
                    User Name or Password is incorrect
                </Typography>
            }
            {!show && <span>&nbsp;</span>}
        </Grid>
    )
}
