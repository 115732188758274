import datatableStyles from "../../static/datatables-styles";
import { TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';

export const StyledTableHeader = withStyles((theme) => ({
    root: {
        ...datatableStyles.header.style,
    }
}))(TableHead);

export const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "none",
        padding: 4,
    }
}))(TableCell);

export const LabelCell = withStyles((theme) => ({
    root: {
        textAlign: "right"
    }
}))(StyledTableCell);

export const StyledTableRow = withStyles((theme) => {
    return ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.common.white,
            },
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.common.white,
            },
        },
    });
})(TableRow);

