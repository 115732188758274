import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@material-ui/core";

const InputField = ({ label, type, guid, min, max, maxlength, minlength, required, model, onUpdate, onChange, name, xs, fullWidth, value: passedValue, ...rest }) => {
    const { control } = useFormContext();

    useEffect(() => {
        control.setValue(name, passedValue);
    }, [passedValue])

    return (
        <Controller
            control={control}
            name={name}
            label={label}
            defaultValue=""
            rules={{ required: Boolean(required) }}
            render={({ onChange, value }) => {
                return (
                    <Grid 
                        item 
                        xs={xs ? xs : 3}
                    >
                        <TextField
                            inputProps={{ min: min, max: max, maxlength: maxlength > 0 ? maxlength : '', minlength: minlength, model: model, id: guid, guid: guid, autoComplete: "new-password" }}
                            name={name}
                            label={label}
                            type={type}
                            fullWidth={fullWidth}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true
                            }} 
                            required={Boolean(required)}
                            error={Boolean(required && !value)}
                            onChange={onUpdate ? onUpdate : onChange}
                            value={value ? value : passedValue}
                            {...rest}
                        />
                    </Grid>
                )
            }}
        >

        </Controller>
    )
}

export default InputField;