import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import StopRental from './StopRental';
import ExchangeRental from './ExchangeRental';
import TransferRental from './TransferRental';
import RentalDetails from './RentalDetails';
import usePopover from '../../hooks/usePopover';
import { api, Context } from '../../Portal';
import { trackPromise } from 'react-promise-tracker';

const useStyles = makeStyles((theme) => ({}));

const url = "/api/v1/equipment/orderreport";

const Actions = ({ rows, row, onExited, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const [showStopRental, openStopRental, closeStopRental] = usePopover();
    const [showExchangeRental, openExchangeRental, closeExchangeRental] = usePopover();
    const [showTransferRental, openTransferRental, closeTransferRental] = usePopover();
    const [showRentalDetails, openRentalDetails, closeRentalDetails] = usePopover();

    const rowsSelected = (open) => {
        if (rows.length !== 0) {
            open();
        }
    }

    const rowSelected = (open) => {
        if (row.length !== 0) {
            open();
        }
    }

    const printDetails = () => {
        if (row.length !== 0) {
            getDetailsPrint();
        }
    }

    const reload = (close) => {
        close();
        onExited();
    }

    const getDetailsPrint = () => {
        trackPromise(
            api.get(state.host + `${url}/${row.rentalOrderNo}`, state.credentials)
                .then((response) => {
                    openDetailsPrint(response.data)
                }));
    }

    const openDetailsPrint = (base64) => {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        const fileData = bytes.buffer;
        const blob = new Blob([fileData], {
            type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=650,width=1000,scrollbars=yes,status=yes');
    }
	return (
		<div style={{ position: "fixed", bottom: 0, zIndex: 1000, background: "#ffffff", height: "52px", borderTop: "1px solid #4E6E98", left: 0, padding: "0 270px", display: "flex", alignContent: "center", width: "100%", flexWrap: "wrap" }} >
        <Grid
					container
					direction="row"
					style={{ height: "36px" }}
            >
					{state.user.menuItemPermissions.includes("Transfer Equipment") && (
						<Button
							variant="contained"
							color="primary"
							style={{ marginRight: "2em" }}
							disabled={rows.length === 0}
							onClick={() => rowsSelected(openTransferRental)}
						>
							Transfer
						</Button>
					)}
					{state.user.menuItemPermissions.includes("Exchange Equipment") && (
						<Button
							variant="contained"
							color="primary"
							style={{ marginRight: "2em" }}
							disabled={rows.length === 0}
							onClick={() => rowsSelected(openExchangeRental)}
						>
							Exchange
						</Button>
					)}
					{state.user.menuItemPermissions.includes("Create Rental Order") && (
						<Button
							variant="contained"
							color="primary"
							style={{ marginRight: "2em" }}
							disabled={rows.length === 0}
							onClick={() => rowsSelected(openStopRental)}
						>
							Stop Rental
						</Button>
					)}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "2em" }}
                    disabled={!row.selected}
                    onClick={() => rowSelected(openRentalDetails)}
                >
                    Rental Details
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!row.selected}
                    onClick={() => printDetails()}
                >
                    Print Rental Details
                </Button>
            </Grid>

            {rows.length > 0 && (
                <StopRental
                    rows={rows}
                    show={showStopRental}
                    onExited={() => reload(closeStopRental)}
                    onEscapeKeyDown={() => reload(closeStopRental)}
                />
            )}

            {rows.length > 0 && (
                <TransferRental
                    rows={rows}
                    show={showTransferRental}
                    onExited={() => reload(closeTransferRental)}
                    onEscapeKeyDown={() => reload(closeTransferRental)}
                />
            )}

            {rows.length > 0 && (
                <ExchangeRental
                    rows={rows}
                    show={showExchangeRental}
                    onExited={() => reload(closeExchangeRental)}
                    onEscapeKeyDown={() => reload(closeExchangeRental)}
                />
            )}

            {row.selected && (
                <RentalDetails
                    row={row}
                    show={showRentalDetails}
                    onExited={() => reload(closeRentalDetails)}
                    onEscapeKeyDown={() => reload(closeRentalDetails)}
                />
            )}
        </div>
    )
}


export default Actions;