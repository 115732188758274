import { useFormContext, Controller } from "react-hook-form";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { useEffect } from "react";

const DropdownField = (props) => {
    const { control } = useFormContext();
    const { name, label, onChange: toplevelOnChange, value: passedValue, xs, ...rest } = props;

    useEffect(() => {
        control.setValue(name, passedValue)
    }, [passedValue])

    return (
        <Controller
            render={({ onChange, value }) => {
                const { id, options, required, ...remainder } = rest;

                return (
                    <Grid
                        item
                        xs={xs ? xs : 3}
                    >
                        <TextField
                            id={id}
                            key={id}
                            border={0}
                            select
                            variant="outlined"
                            fullWidth
                            required={Boolean(required)}
                            label={label}
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={Boolean(required && !value)}
                            value={value ? value : passedValue}
                            onChange={(e) => {
                                onChange(e);
                                if (toplevelOnChange) {
                                    toplevelOnChange(e);
                                }
                            }}
                            name={name}
                            autoComplete="new-password"
                            {...remainder}
                        >
                            {options.map(({ value, description }, index) => {
                                return (
                                    <MenuItem key={`${id}-${index}`} value={value}>{description}</MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                )
            }}
            control={control}
            name={name}
            label={label}
            defaultValue={passedValue}
            rules={{ required: Boolean(props.required) }}
        />
    )
}

export default DropdownField;