const datatableStylesAlt = {
    header: {
        style: {
            backgroundColor: "#016895",
            color: "#FFFFFF",
            fontSize: '12pt',
            minHeight: '40px',
        }
    },
    subHeader: {
        style: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderBottom: '1px solid #016895',
            minHeight: '40px',
        }
    },
    headRow: {
        style: {
            backgroundColor: "#FFFFFF",
            color: '#000000',
            borderBottom: '1px solid #016895',
            minHeight: '40px',
        }
    },
    headCells: {
        style: {
            backgroundColor: "#FFFFFF",
            paddingLeft: '8px',
            paddingRight: '8px',
        }
    },
    rows: {
        style: {
            paddingLeft: '5px',

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    }
}

export default datatableStylesAlt;