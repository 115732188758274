import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse, Container, Grid, TextField, Typography } from '@material-ui/core';
import { ConfirmationDialog } from '../../components/controls/ConfirmationDialog';
import FieldGroup from '../../components/controls/FieldGroup';
import DropdownField from '../../components/controls/DropdownField';
import useCustomerInformation from '../../hooks/useCustomerInformation';
import { api, Context } from '../../Portal';
import usePopover from '../../hooks/usePopover';
import StopRental from '../view-eqipment-on-hire/StopRental';
import TransferRental from '../view-eqipment-on-hire/TransferRental';
import ExchangeRental from '../view-eqipment-on-hire/ExchangeRental';

const useStyles = makeStyles((theme) => ({
    actions: {
        marginTop: "2em",
    },
}));

var url = "/api/v1/equipment/exchange/serialnumbers";

const reducer = (state, action) => {
    switch (action.type) {
        case "set-wards":
            return {
                ...state,
                wards: action.payload,
            }

        case "preselect-ward":
            return {
                ...state,
                preselectedWard: action.payload,
            }
    }
}

const RentalExchange = () => {
    const [state, dispatch] = useContext(Context);
    const [information, costCentreRequired, customers, wards, costCentres, onCustomerSelected, onWardSelected, onCostCentreSelected] = useCustomerInformation();
    const [local, dispatchLocal] = useReducer(reducer, { wards: [] });
    const [serialNumber, setSerialNumber] = useState("");
    const [rows, setRows] = useState([]);
    const [values, setValues] = useState({ CUSTOMER: "", WARD: "", COSTCENTRE: "" });
    const [showNotFound, setShowNotFound] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState('');
    const [confirmationVariant, setConfirmationVariant] = useState('');
    const [showOkButton, setShowOkButton] = useState(true);
    const [okButtonText, setOkButtonText] = useState('');
    const [okButtonAction, setOkButtonAction] = useState('');
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [cancelButtonText, setCancelButtonText] = useState('');
    const [cancelButtonAction, setCancelButtonAction] = useState('');
    const [dialogActions, setDialogActions] = useState({});
    const [confirmationDescription, setConfirmationDescription] = useState('');
    const [showTransferRental, openTransferRental, closeTransferRental] = usePopover();
    const [showExchangeRental, openExchangeRental, closeExchangeRental] = usePopover();
    const [showStopRental, openStopRental, closeStopRental] = usePopover();
    const [options, setOptions] = useState(false);
    const formMethods = useForm();
    const classes = useStyles();

	useEffect(() => {
		return () => {
			dispatch({ type: "CLEAR-CUSTOMER-SELECTION" })
		}
    }, []);

    const handleFind = (event) => {
        event.stopPropagation();
        event.preventDefault();

        api.get(state.host + `${url}/${serialNumber}`, state.credentials)
            .then(({ data }) => {
                if (data.dialogMessage && data.dialogMessage.actions) {
                    setConfirmationTitle(data.dialogMessage.title ?? data.dialogMessage.title);
                    setConfirmationVariant(data.dialogMessage.dialogType ?? data.dialogMessage.dialogType.toLowerCase());
                    setShowOkButton(data.dialogMessage.okButtonText ?? data.dialogMessage.okButtonText.length > 0);
                    setOkButtonText(data.dialogMessage.okButtonText ?? data.dialogMessage.okButtonText);
                    setOkButtonAction(data.dialogMessage.okButtonAction ?? data.dialogMessage.okButtonAction);
                    setShowCancelButton(data.dialogMessage.cancelButtonText ?? data.dialogMessage.cancelButtonText.length > 0);
                    setCancelButtonText(data.dialogMessage.cancelButtonText ?? data.dialogMessage.cancelButtonText);
                    setCancelButtonAction(data.dialogMessage.cancelButtonAction ?? data.dialogMessage.cancelButtonAction);
                    setConfirmationDescription(data.dialogMessage.message ?? data.dialogMessage.message);
                    setDialogActions(data.dialogMessage.actions);
                    setRows(data.result);
                    setOptions(true);
                    setConfirmationOpen(true);
                } else if (data.result.length > 0 && !data.dialogMessage) {
                    setRows(data.result);
                    setOptions(false);
                    openExchangeRental();
                    return;
                }
                if (data.result.length === 0) {
                    setShowNotFound(true);
                }
            })
    }

    const handleActionButtonTransfer = () => {
        if (rows.length > 0) {
            setOptions(true);
            openTransferRental();
            setConfirmationOpen(false);
        }
    }

    const handleActionButtonExchange = () => {
        if (rows.length > 0) {
            setOptions(true);
            openExchangeRental();
            setConfirmationOpen(false);
        }
    }

    const handleActionButtonStop = () => {
        if (rows.length > 0) {
            setOptions(true);
            openStopRental();
            setConfirmationOpen(false);
        }
    }

    const handleActionButtonCancel = async () => {
        setRows([]);
        setConfirmationOpen(false);
    }

    const handleOkClick = () => {
        setConfirmationOpen(false);
    }
    const handleCancelClick = () => {
        setConfirmationOpen(false);
    }

    const reload = (close) => {
        close();
        setSerialNumber("");
        setRows([]);
    }

    const OnSerialNumberChanged = (event) => {
        setSerialNumber(event.target.value);
        setShowNotFound(false);
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleFind}>

                <Grid
                    container
                    direction="column"
                    item
                    spacing={1}
                >


                    <FieldGroup
                        fields={[{ columnCaption: "Customer Information" }]}
                        classes={classes}
                    >
                        <DropdownField
                            id="select-customer"
                            label="Customer"
                            onChange={onCustomerSelected}
                            name="CUSTOMER"
                            required
                            value={information.CUSTOMER}
                            options={customers.map(customer => ({ value: customer.no, description: customer.name }))}
                            xs={12}
                            className={classes.defaultBorder}
                        />
                        <DropdownField
                            id="select-ward"
                            label="Ward"
                            onChange={onWardSelected}
                            name="WARD"
                            required
                            value={information.WARD}
                            options={wards.map(ward => ({ value: ward.description, description: ward.description }))}
                            xs={12}
                            className={classes.defaultBorder}
                        />
                    </FieldGroup>

                    <FieldGroup
                        height={1}
                        fields={[{ columnCaption: "Search rental order by serial number" }]}
                        classes={classes}
                    >
                        <TextField
                            label={"Serial Number"}
                            type={"text"}
                            fullWidth
                            inputProps={{
                                autoComplete: "new-password"
                            }}
                            variant="outlined"
                            value={serialNumber}
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={OnSerialNumberChanged}
                        />

                        <Collapse
                            in={showNotFound}
                        >
                            <Container
                                style={{ marginTop: "1em" }}
                            >
                                <Typography
                                    color="error"
                                >
                                    Cannot find a rental order for {serialNumber}
                                </Typography>
                            </Container>
                        </Collapse>
                
                        <Grid
                            className={classes.actions}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={serialNumber.length === 0}
                            >
                                Find
                        </Button>
                        </Grid>
                    </FieldGroup>

                    {rows.length > 0 && (
                        <StopRental
                            rows={rows}
                            show={showStopRental}
                            onExited={() => reload(closeStopRental)}
                            onEscapeKeyDown={() => reload(closeStopRental)}
                            options={options}
                        />
                    )}
                    {rows.length > 0 && (
                        <ExchangeRental
                            rows={rows}
                            show={showExchangeRental}
                            onExited={() => reload(closeExchangeRental)}
                            onEscapeKeyDown={() => reload(closeExchangeRental)}
                            options={options}
                        />
                    )}
                    {rows.length > 0 && (
                        <TransferRental
                            rows={rows}
                            show={showTransferRental}
                            onExited={() => reload(closeTransferRental)}
                            onEscapeKeyDown={() => reload(closeTransferRental)}
                            options={options}
                        />
                    )}
                    <ConfirmationDialog
                        open={confirmationOpen}
                        title={confirmationTitle}
                        variant={confirmationVariant}
                        showOkButton={showOkButton}
                        okButtonText={okButtonText}
                        showCancelButton={showCancelButton}
                        cancelButtonText={cancelButtonText}
                        description={confirmationDescription}
                        onSubmit={handleOkClick}
                        onCancel={handleCancelClick}
                        onActionExchange={handleActionButtonExchange}
                        onActionTransfer={handleActionButtonTransfer}
                        onActionStop={handleActionButtonStop}
                        onActionCancel={handleActionButtonCancel}
                        dialogActions={dialogActions}
                    />
                </Grid>
            </form>
        </FormProvider>
    )
}

export default RentalExchange;