import React, { useContext, useEffect, useState } from 'react';
import "date-fns";
import add from "date-fns/add";
import DateFNSUtils from "@date-io/date-fns";
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableContainer, TableRow, TextareaAutosize, TextField, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Popover from '../../components/Popover';
import { LabelCell, StyledTableCell, StyledTableHeader, StyledTableRow } from './table-styling';
import TitleRow from './TitleRow';
import DisabledTextField from './DisabledTextField';
import FromToTitleRow from './FromToTitleRow';
import useSetExchangeQuestions from "../../hooks/useSetExchangeQuestions";
import { api, Context } from '../../Portal';
import SelectProducts from "./select-products";
import SerialNumber from '../../components/controls/SerialNumber';
import InputButton from "./InputButton";
import InputField from "../../components/controls/InputField";
import BigtextField from "../../components/controls/BigtextField";
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";
import Authorisation from '../create-order/Authorisation';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 24,
        padding: 24,
        ...theme.body,
        backgroundColor: 'transparent',
        border: '0',
    },
    table: {
        border: "0px solid " + theme.palette.primary.dark,
        marginBottom: 0,
    },
    tableRow: {
        background: "#fff",
    },
    cancel: {
        marginLeft: 42,
    },
    required: {
        color: "red",
    },
    requiredBorder: {
        border: "1px solid red",
    },
    default: {
        color: theme.palette.text.primary
    },
    defaultBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
        "& input:disabled fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(1, 104, 149, 1)",
        },
        '& hover': {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
    },
    defaultRequiredBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid red",
        },
        "& input:disabled fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        '& hover': {
            border: "1px solid red",
        },
    },
    disabledBorder: {
        margin: '5px',
        width: "300px",
        "& fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(0, 0, 0, .5)",
        },
        '& hover': {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
    },
    tableHeader: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        fontSize: '16pt',
        lineHeight: '24pt',
    },
    tableTitleRow: {
        color: '#FFFFFF',
    },
    okButton: {
        width: '100%',
    },
    cancelButton: {
        backgroundColor: '#AEAEAE',
        color: '#2E2E2E',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#707070',
            color: '#FFFFFF',
        }
    },
    styledRow: {
        padding: '5px',
    },
    tableCellStyle180: {
        maxWidth: '180px',
        width: '180px',
    },
    tableCellStyle300: {
        maxWidth: '300px',
        width: '300px', 
        padding: 0,
    },
}));

const ExchangeHeader = () => {
    return (
        <StyledTableHeader>
            <TitleRow colSpan={8}>
                Pending Exchange Product Orders
            </TitleRow>

            <TableRow>
                <StyledTableCell>Model</StyledTableCell>
                <StyledTableCell>Serial No</StyledTableCell>
                <StyledTableCell>Customer</StyledTableCell>
                <StyledTableCell>Ward</StyledTableCell>
                <StyledTableCell>Patient</StyledTableCell>
                <StyledTableCell>Patient No</StyledTableCell>
                <StyledTableCell>Rental Start</StyledTableCell>
                <StyledTableCell>Bed</StyledTableCell>
            </TableRow>
        </StyledTableHeader>
    )
}

const ExchangeRow = ({ row }) => {
    return (
        <StyledTableRow>
            <StyledTableCell>{row.model}</StyledTableCell>
            <StyledTableCell>{row.serialNo}</StyledTableCell>
            <StyledTableCell>{row.customer}</StyledTableCell>
            <StyledTableCell>{row.ward}</StyledTableCell>
            <StyledTableCell>{row.patient}</StyledTableCell>
            <StyledTableCell>{row.patientNo}</StyledTableCell>
            <StyledTableCell>{row.startDate}</StyledTableCell>
            <StyledTableCell>{row.bed}</StyledTableCell>
        </StyledTableRow>
    )
}

const url = "/api/v1/equipment/exchange";
const consignedUrl = "/api/v1/equipment/consigned-stock";
const productsUrl = "/api/v1/products";
const showCleaned = false;
const showImmediatePickup = false;

const transformModels = (models) => {
    return models.map(categories => (
        categories.map(product => ({
            category: product.category,
            MODEL: product.model,
            MODELDESCRIPTION: product.description,
            SHORTDESCRIPTION: product.shortDescription,
            REQBYDATE: "",
            REQBYTIME: "",
            image: product.image,
            highEnd: product.highEnd
        }))
    ));
}

const ExchangeRental = ({ rows, show, onExited, onEscapeKeyDown, options, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const [currentRow, setCurrentRow] = useState({});
    const [waitingRows, setWaitingRows] = useState([]);
    const [details, completed, onAnswered, reset] = useSetExchangeQuestions();
    const [consignedProduct, setConsignedProduct] = useState([]);
    const [models, setModels] = useState([]);
    const [openProductSelection, setOpenProductSelection] = useState(false);
    const [product, setProduct] = useState({});
    const [showConfirmation, setShowConfirmation] = useState({ approved: false, declined: false });
    const [showApproval, setShowApproval] = useState(false);
    const [staff, setStaff] = useState({ authorisers: [], requestors: [], contacts: [] });
    const [contact, setContact] = useState({
        name: '',
        surname: '',
        firstNames: ''
    });
    const [requestor, setRequestor] = useState({
        name: '',
        surname: '',
        firstNames: ''
    });
    const additionalSectionUrl = "/views/v1/create-order/additional";


    let credentials = { ...state.credentials };

    const classes = useStyles();

    useEffect(() => {
        if (!show) {
            return;
        }
        
        let [firstRow, ...remainingRows] = rows;
        let requestor = firstRow?.reqNurse ?? '';
        setDefaults(requestor);
        getFormData();
        advancePendingToCurrent(firstRow, remainingRows);

        api.get(state.host + consignedUrl, credentials)
            .then(({ data }) => {
                setConsignedProduct(data);
            });
    }, [show, rows, state.host]);

    const setDefaults = (requestor) => {
        setStaff({ authorisers: [], requestors: [], contacts: [] });
        setContact({
            name: '',
            surname: '',
            firstNames: ''
        });
        setRequestor({
            name: requestor,
            surname: '',
            firstNames: ''
        });
    }

    const getFormData = () => {
        api.get(state.host + additionalSectionUrl, state.credentials)
            .then(({ data }) => {
                setStaff(data.staff);
            });
    }

    const handleOkClicked = () => {

        let approvalRequired = Boolean(details.newModel !== currentRow.model);

        if (approvalRequired) {
            setShowApproval(true);
            return;
        }

        onApproval("auto-approve")
	}

    const onApproval = (status, people) => {
        setShowApproval(false);
        let requestApproved = false;
        if (status === "cancelled") {
            return false;
        }

        if (status === "not approved") {
            requestApproved = false;
        }

        if (status === "approved" || status === "auto-approve") {
            requestApproved = true;
        }

        let equipmentDetails = {
            RENTALORDERNO: currentRow.rentalOrderNo,
            RENTALLINENO: currentRow.rentalLineNo,
            SERIALNO: currentRow.serialNo,
            BIOHAZARD: details.biohazard,
            NOTES: details.notes,
            REASON: details.reason,
            NEWMODEL: details.newModel,
            NEWSERIALNO: "",
            EXCHANGEDATE: new Date(details.dateOfExchange).toISOString().substring(0, 10),
            APPROVED: requestApproved,
            APPROVER: people?.APPROVER ?? state.user.username,
            CUSTOMER: currentRow.Customer,
            WARD: currentRow.ward
        };

        api.post(state.host + url, equipmentDetails, credentials)
            .then(({ data }) => {
                if (data.success) {
                    onOk();
                }
            });
    }

    const onProductSelectionConfirmation = (selectedProducts) => {
        // TODO: add action parameter for "cancel" to not add selectedProducts - or copy from productsSelected as set in handleAddProducts
        setProduct(selectedProducts);
        onAnswered({ newModel: selectedProducts.MODEL })
        setOpenProductSelection(false);
    }

    const advancePendingToCurrent = (firstRow, remainingRows) => {
        reset();
        setCurrentRow(firstRow);
        setWaitingRows(remainingRows);

        let customer = state.user.customers.find(customer => customer.name === firstRow.customer);
        credentials = {
            ...state.credentials,
            headers: {
                ...state.credentials.headers,
                "acl-customer": customer.no, 
                "acl-ward": firstRow.ward
            }
        };

        api.get(productsUrl, credentials)
            .then(({ data }) => {
                setModels(transformModels(data));
            })
    }

    const handleCancelClicked = () => {
        setShowConfirmation({ approved: false, declined: true });
    }

    const onOk = () => {
        let [firstRow, ...remainingRows] = waitingRows;

        if (!firstRow) {
            onExited();
            return;
        }

        advancePendingToCurrent(firstRow, remainingRows);
    }

    return show && Boolean(currentRow) && (
        <Popover
            open={show}
            showTitle={true}
            onExited={onExited}
            onEscapeKeyDown={onEscapeKeyDown}
            showScrollbar
        >
            {openProductSelection && (
                <SelectProducts
                    models={models}
                    show={openProductSelection}
                    onExited={onProductSelectionConfirmation}
                />
            )}

            <TableContainer
                variant="outlined"
                component={Paper}
                className={classes.paper}
            >
                <Table
                    size="small"
                    className={classes.table}
                >
                    <StyledTableHeader>
                        <TitleRow
                            colSpan={5}
                            className={classes.tableHeader}
                        >
                            Exchange Product Order
                        </TitleRow>
                        <FromToTitleRow />
                    </StyledTableHeader>

                    <TableBody>
                        <StyledTableRow className={classes.styledRow}>
                            
                            <LabelCell className={classes.tableCellStyle180}>Model</LabelCell>
                            <StyledTableCell className={classes.tableCellStyle300} >
                            <InputField
                                name={"model"}
                                label={""}
                                type="text"
                                disabled
                                    className={classes.disabledBorder} value={currentRow.model} />
                            </StyledTableCell>
                            <LabelCell className={Boolean(details.newModel) ? [classes.defaultBorder, classes.tableCellStyle180].join(' ') : [classes.required, classes.tableCellStyle180].join(' ') }>Model</LabelCell>
                            <StyledTableCell className={classes.tableCellStyle300}>
                                <InputButton
                                    onClick={() => setOpenProductSelection(true)}
                                    value={details.newModel}
                                    required={true}
                                    disabled={false}
                                    className={classes.defaultBorder}
                                />
                            </StyledTableCell>
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow className={classes.styledRow}>
                            <LabelCell>Serial No</LabelCell>
                            <InputField
                                name={"serialNo"}
                                label={""}
                                type="text"
                                disabled
                                className={classes.disabledBorder} value={currentRow.serialNo} fourColumn />
                            {product.serialNo ? (
                                <>
                                    <LabelCell >Serial No</LabelCell>
                                    <StyledTableCell>
                                        <SerialNumber product={product} />
                                    </StyledTableCell>
                                </>
                            ) : (
                                    <>
                                        <LabelCell />
                                        <StyledTableCell />
                                    </>
                                )}
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow className={classes.styledRow}>
                            <LabelCell>Ward</LabelCell>
                            <InputField
                                name={"ward"}
                                label={""}
                                type="text"
                                disabled
                                className={classes.disabledBorder} value={currentRow.ward} fourColumn />
                            <StyledTableCell colSpan={2} />
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow className={classes.styledRow}>
                            <LabelCell>Bed</LabelCell>
                            <InputField
                                name={"bed"}
                                label={""}
                                type="text"
                                disabled
                                className={classes.disabledBorder} value={currentRow.bed} fourColumn />
                            <StyledTableCell colSpan={2} />
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow className={classes.styledRow}>
                            <LabelCell>Patient</LabelCell>
                            <InputField
                                name={"patientName"}
                                label={""}
                                type="text"
                                disabled
                                className={classes.disabledBorder} value={currentRow.patientName} fourColumn />
                            <StyledTableCell colSpan={2} />
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow className={classes.styledRow}>
                            <LabelCell>Patient No</LabelCell>
                            <InputField
                                name={"patientNo"}
                                label={""}
                                type="text"
                                disabled
                                className={classes.disabledBorder}
                                value={currentRow.patientNo} />
                            <StyledTableCell colSpan={2} />
                            <StyledTableCell />
                        </StyledTableRow>
                        <StyledTableRow className={classes.styledRow}>
                            <LabelCell>Rental Start</LabelCell>
                            <InputField
                                name={"startDate"}
                                label={""}
                                type="text"
                                disabled
                                className={classes.disabledBorder}value={currentRow.startDate} fourColumn />
                            <LabelCell className={Boolean(details.dateOfExchange) ? classes.default : classes.required}>Date of Exchange</LabelCell>
                            <StyledTableCell>
                                <MuiPickersUtilsProvider utils={DateFNSUtils}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        value={details.dateOfExchange}
                                        disableToolbar
                                        autoOk
                                        required={true}
                                        maxDate={add(Date.now(), { days: 10 })}
                                        inputVariant="outlined"
                                        className={classes.defaultBorder}
                                        onChange={(date) => onAnswered({ dateOfExchange: date })}
                                    />
                                </MuiPickersUtilsProvider>
                            </StyledTableCell>
                            <StyledTableCell />
                        </StyledTableRow>
                        {showCleaned &&
                            <StyledTableRow>
                                <LabelCell className={Boolean(details.cleaned) ? classes.default : classes.required}>Cleaned?</LabelCell>
                                <StyledTableCell>
                                    <RadioGroup
                                        value={details.cleaned}
                                        onChange={(event) => onAnswered({ cleaned: event.target.value })}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={
                                                <Radio
                                                    size="small"
                                                    className={Boolean(details.cleaned) ? classes.default : classes.required}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Yes
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} />
                            </StyledTableRow>
                        }
                        {showImmediatePickup &&
                            <StyledTableRow>
                                <LabelCell className={Boolean(details.pickup) ? classes.default : classes.required}>Immediate Pickup?</LabelCell>
                                <StyledTableCell>
                                    <RadioGroup
                                        value={details.pickup}
                                        onChange={(event) => onAnswered({ pickup: event.target.value })}
                                    >
                                        <FormControlLabel
                                            value="Yes"
                                            control={
                                                <Radio
                                                    size="small"
                                                    className={Boolean(details.pickup) ? classes.default : classes.required}
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Yes
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} />
                            </StyledTableRow>
                        }
                        <StyledTableRow>
                            <LabelCell className={Boolean(details.biohazard) ? classes.default : classes.required}>Biohazard?</LabelCell>
                            <StyledTableCell>
                                <RadioGroup
                                    value={details.biohazard}
                                    onChange={(event) => onAnswered({ biohazard: event.target.value })}
                                    row
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        control={
                                            <Radio
                                                size="small"
                                                className={Boolean(details.biohazard) ? classes.default : classes.required}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                Yes
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        value="No"
                                        control={
                                            <Radio
                                                size="small"
                                                className={Boolean(details.biohazard) ? classes.default : classes.required}
                                            />
                                        }
                                        label={
                                            <Typography variant="body2">
                                                No
                                            </Typography>
                                        }
                                    />
                                </RadioGroup>
                            </StyledTableCell>
                            <StyledTableCell colSpan={3} />
                        </StyledTableRow>
                        <StyledTableRow>
                            <LabelCell className={details.biohazard === "No" ? classes.default : Boolean(details.notes) ? classes.default : classes.required}>Biohazard Notes</LabelCell>
                            <StyledTableCell>
                                <BigtextField
                                    name={"notes"}
                                    label={""}
                                    defaultValue=""
                                    multiline
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    value={details.notes}
                                    xs={12}
                                    rows={4}
                                    disabled={details.biohazard === "No"}
                                    required={details.biohazard === "Yes" || !details.biohazard}
                                    onChange={(event) => onAnswered({ notes: event.target.value })}
                                    className={classes.defaultBorder}
                                />
                            </StyledTableCell>
                            <LabelCell className={details.reason === "No" ? classes.default : Boolean(details.reason) ? classes.default : classes.required}>Reason</LabelCell>
                            <StyledTableCell>
                                <BigtextField
                                    name={"reason"}
                                    label={""}
                                    defaultValue=""
                                    multiline
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    value={details.reason}
                                    xs={12}
                                    rows={4}
                                    onChange={(event) => onAnswered({ reason: event.target.value })}
                                    required={true}
                                    className={classes.defaultBorder}
                                />
                            </StyledTableCell>
                            <StyledTableCell />
                        </StyledTableRow>
                    </TableBody>
                </Table>

                <Grid
                    container
                >
                    <Grid
                        item
                        xs={6}
                    >
                    <Button
                        disabled={!completed}
                        onClick={handleOkClicked}
                        className={classes.okButton}
                    >
                            Ok
                    </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                    <Button
                        className={classes.cancelButton}
                        onClick={handleCancelClicked}
                    >
                            Cancel
                    </Button>
                    </Grid>

                    

                    <ConfirmationDialog
                        open={showConfirmation.approved}
                        onSubmit={onOk}
                        title={"EXCHANGE ACTIONED"}
                        showOkButton={true}
                        okButtonText={"OK"}
                        showCancelButton={false}
                        cancelButtonText={""}
                        variant={"info"}
                        description={"Exchange has been actioned."}
                    />
                    <ConfirmationDialog
                        open={showConfirmation.declined}
                        onSubmit={onOk}
                        title={"EXCHANGE CANCELLED"}
                        showOkButton={true}
                        okButtonText={"OK"}
                        showCancelButton={false}
                        cancelButtonText={""}
                        variant={"info"}
                        description={"Exchange has been cancelled."}
                    />
                </Grid>
            </TableContainer>
            <Authorisation
                show={showApproval}
                onExited={onApproval}
                staff={staff}
                contact={contact}
                requestor={requestor}
            />

            {waitingRows.length > 0 && (
                <TableContainer
                    variant="outlined"
                    component={Paper}
                    className={classes.paper}
                >
                    <Table
                        size="small"
                        className={classes.table}
                    >
                        <ExchangeHeader />
                        <TableBody>
                            {waitingRows.map(row => (
                                <ExchangeRow row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Popover>
    )
}

export default ExchangeRental;