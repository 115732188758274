import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RouterLink } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import AnonAvatar from "../../assets/avatar_anon.png"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Profile from "./Profile";
import { api } from '../../Portal';
import { Context } from '../../Portal';
import useWhyDidYouUpdate from '../../hooks/useWhyDidYouUpdate';
import Notification from '../controls/Notification';

const useStyles = makeStyles((theme) => ({
    userProfileHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: '#383A46',
        color: '#FFFFFF',
        fontSize: '9pt',
        height: '51px',
    },
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    userAvatar: {
        backgroundColor: '#FFFFFF',
        marginRight: theme.spacing(1),
    },
    categoryHeaderPrimary: theme.palette.navigation.heading,
    item: theme.palette.navigation.item,
    itemCategory: {
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: '30px',
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: theme.palette.navigation.itemActive,
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: { 
        minWidth: '40px',
    },
    divider: {
        marginTop: theme.spacing(2),
    },
}));

function Navigator(props) {
    useWhyDidYouUpdate("Navigator", props);

    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const { categories } = props;

    useEffect(() => {
    }, [state.user, state.authenticated]);

    const checkMenuItemPermissions = (permission) => {
        if (!state.authenticated) {
            return false;
        }

        if (!permission) {
            return true;
        }

        return state.user.menuItemPermissions.includes(permission);
    }

    return (
        <Drawer variant="permanent" {...props}>
            <List disablePadding>
                <ListItem className={classes.userProfileHeader}>
                    <Profile className={clsx(classes.userAvatar)} />
                    {state.user?.fullName}
                </ListItem>
                <ListItem className={clsx(classes.item, classes.itemCategory)}>
                    <ListItemIcon className={classes.itemIcon}>
                        <FontAwesomeIcon icon={faHome} />
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classes.itemPrimary,
                        }}
                    >
                        Project Overview
                    </ListItemText>
                </ListItem>
                {!state.authenticated ? null : categories.map(({ id, children }) => (
                    <React.Fragment key={id}>
                        <ListItem className={classes.categoryHeader}>
                            <ListItemText
                                classes={{
                                    primary: classes.categoryHeaderPrimary,
                                }}
                            >
                                {id}
                            </ListItemText>
                        </ListItem>

                        {children.map(({ id: childId, icon, type, route, permission }) => {
                            if (!checkMenuItemPermissions(permission)) { 
                                return null;
                            }
                            var count = 0;
                            if (type === 'authorizations') {
                                count = state.authorisationCount;
                            }
                            return (
                                <RouterLink key={childId} to={route} exact className={clsx(classes.item, classes.item)} activeClassName={clsx(classes.item, classes.itemActiveItem)} >
                                    <ListItem
                                        button
                                    >
                                        <ListItemIcon className={classes.itemIcon}>
                                            <Notification count={count} icon={icon} />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{
                                                primary: classes.itemPrimary,
                                            }}
                                        >
                                            {childId}
                                        </ListItemText>
                                    </ListItem>
                                </RouterLink>
                            );
                        })}

                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
            </List>
        </Drawer>
    );
}

export default React.memo(Navigator);
