const GlobalReducer = (state, action) => {
    let payload = action.payload;

    switch (action.type) {
        case "LOGGED-IN":
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    headers: {
                        ...state.credentials.headers,
                        ...payload.headers,
                        "acl-customer": "",
                        "acl-shipto": "",
                        "acl-ward": null,
                    }
                },
                authenticated: true,
                user: payload.user,
                selectedCustomer: "",
                selectedWard: "",
            }

        case "LOGGED-OUT":
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    headers: {}
                },
                authenticated: false,
                user: null
            }

        case "PROFILE-CUSTOMER-SELECTED":
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    headers: {
                        ...state.credentials.headers,
                        "acl-customer": payload.selectedCustomer.no,
                        "acl-shipto": payload.selectedShipTo?.shipToCode ?? "",
                        "acl-ward": null,
                    },
                },
                ...payload
            }

        case "CUSTOMER-SELECTED":
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    headers: {
                        ...state.credentials.headers,
                        "acl-customer": payload.selectedCustomer,
                        // "acl-shipto": payload.selectedShipTo ?? ""
                    },
                },
                ...payload
            }

        case "WARD-SELECTED":
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    headers: {
                        ...state.credentials.headers,
                        "acl-ward": payload.selectedWard
                    },
                },
                ...payload
            };

        case "CLEAR-CUSTOMER-SELECTION":
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    headers: {
                        ...state.credentials.headers,
                        "acl-customer": "",
                        "acl-ward": "",
                    }
                },
                selectedCustomer: null,
                selectedWard: null,
                customerDetail: null,
            }

        case "API-CALLED":
            return {
                ...state,
                loading: true,
            }

        case "API-SUCCESS":
            return {
                ...state,
                loading: false
            }

        case "API-ERROR":
            return {
                ...state,
                loading: false,
                error: {
                    heading: "Error contacting the API",
                    message: JSON.stringify(payload)
                }
            }

        case "POST-SUCCESS-MESSAGE":
            return {
                ...state,
                success: payload
            }

        case "SHOW-ERROR":
            return {
                ...state,
                error: {
                    ...payload
                }
            }

        case "CLEAR-ALERTS":
            return {
                ...state,
                error: null,
                success: null
            }

        case "LOAD-CONSIGNED-STOCK":
            return {
                ...state,
                loadConsignedStock: true
            }

        case "UPDATE-AUTHORISATION-COUNT":
            return {
                ...state,
                authorisationCount: payload.authorisationCount
            }

        case "LOADED-CONSIGNED-STOCK":
            return {
                ...state,
                loadConsignedStock: false
            }

        case "CONSIGNMENT-PRODUCTS-SELECTED":
            return {
                ...state,
                consignmentProductsSelected: {
                    ...payload
                },
            }

        case "CLEAR-CONSIGNMENT-PRODUCTS":
            let { consignmentProductsSelected, ...rest } = state;

            return rest;

        case "LOGINPAGE-LOADED":
            return {
                ...state,
                loginPageLoaded: true,
            }

        case "APPROVE-RENTAL":
            return {
                ...state,
                toApprove: payload,
            }

        case 'RENTAL-APPROVED':
            let { toApprove, ...remainingState } = state;
            return remainingState;

        case 'INVOICESUMMARY-LINE-SELECTED':
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedInvoiceLevel: payload.selectedInvoiceLevel,
                    selectedInvoiceNo: payload.selectedInvoiceNo,
                    selectedInvoiceDistrict: payload.selectedInvoiceDistrict,
                    selectedInvoiceFacility: payload.selectedInvoiceFacility,
                    selectedInvoiceDivision: payload.selectedInvoiceDivision,
                    selectedInvoiceWard: payload.selectedInvoiceWard,
                    selectedInvoiceCostCentre: payload.selectedInvoiceCostCentre
                }
            }

        default:
            return state;
    }
}

export default GlobalReducer;