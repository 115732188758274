import React, { useState } from "react";

const usePopover = () => {
    const [show, setShow] = useState(false);

    const open = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }

    return [show, open, close];
}

export default usePopover;