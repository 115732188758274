import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableContainer, TableRow, TextareaAutosize, TextField, Typography } from '@material-ui/core';

import Popover from '../../components/Popover';
import useSetStopQuestions from '../../hooks/useSetStopQuestions';
import { api, Context } from '../../Portal';
import DisabledTextField from './DisabledTextField';
import TitleRow from './TitleRow';
import { LabelCell, StyledTableCell, StyledTableHeader, StyledTableRow } from './table-styling';
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
		paper: {
				marginBottom: 24,
				padding: 24,
				...theme.body,
				backgroundColor: 'transparent',
				border: '0',
		},
		table: {
				border: "0px solid " + theme.palette.primary.dark,
				marginBottom: 0,
		},
		tableRow: {
				background: "#fff",
		},
		button: {
				width: "64px",
		},
		cancel: {
				width: "64px",
				marginLeft: 42,
		},
		required: {
				color: "red",
		},
		requiredBorder: {
				border: "1px solid red",
		},
		default: {
				color: theme.palette.text.primary
		},
		defaultRadio: {
				"&:checked": {
						color: "green",
				}
		},
		defaultBorder: {
				border: "1px solid rgb(118, 118, 118)"
		},
		tableHeader: {
				backgroundColor: '#016895',
				color: '#FFFFFF',
				fontSize: '16pt',
				lineHeight: '24pt',
		},
		tableTitleRow: {
				color: '#FFFFFF',
		},
		okButton: {
				width: '100%',
		},
		cancelButton: {
				backgroundColor: '#AEAEAE',
				color: '#2E2E2E',
				cursor: 'pointer',
				width: '100%',
				'&:hover': {
						backgroundColor: '#707070',
						color: '#FFFFFF',
				}
		},
}));

const url = "/api/v1/equipment/stop-hire";

const StopRental = ({ rows, show, onExited, onEscapeKeyDown, options, ...props }) => {
		const [state, dispatch] = useContext(Context);
		const [currentRow, setCurrentRow] = useState({});
		const [waitingRows, setWaitingRows] = useState([]);
		const [details, completed, onAnswered, reset] = useSetStopQuestions();
		const [showConfirmation, setShowConfirmation] = useState({ approved: false, declined: false });
		const utcOffset = Math.ceil(moment().utcOffset() / 60);
		const classes = useStyles();

		useEffect(() => {
				let [firstRow, ...remainingRows] = rows;

				setCurrentRow(firstRow);
				setWaitingRows(remainingRows);
		}, [rows])

		const handleOkClicked = () => {
				let equipmentDetails = {
						RENTALORDERNO: currentRow.rentalOrderNo,
						RENTALLINENO: currentRow.rentalLineNo,
						SERIALNO: currentRow.serialNo,
						BIOHAZARD: details.biohazard,
						NOTES: details.notes,
						CUSTOMER: currentRow.Customer,
						WARD: options ? state.selectedWard : currentRow.ward,
						UTCOFFSET: utcOffset
				};

				api.post(state.host + url, equipmentDetails, state.credentials)
						.then(({ data }) => {
								let [firstRow, ...remainingRows] = waitingRows;

								if (!firstRow) {
										onExited();
										return;
								}

								reset();
								setCurrentRow(firstRow);
								setWaitingRows(remainingRows);
						});
		}

		const handleCancelClicked = () => {
				setShowConfirmation({ approved: false, declined: true });
		}

		const onOk = () => {
				let [firstRow, ...remainingRows] = waitingRows;

				if (!firstRow) {
						onExited();
						return;
				}

				reset();
				setCurrentRow(firstRow);
				setWaitingRows(remainingRows);
		}

		return show && Boolean(currentRow) && (
				<Popover
						open={show}
						showTitle={true}
						onExited={onExited}
						onEscapeKeyDown={onEscapeKeyDown}
						showScrollbar
				>
						<TableContainer
								variant="outlined"
								component={Paper}
								className={classes.paper}
						>
								<Table
										size="small"
										className={classes.table}
								>
										<StyledTableHeader>
												<TitleRow
														colSpan={5}
														className={classes.tableHeader}
												>
														Stop Rental Order
												</TitleRow>
										</StyledTableHeader>

										<TableBody>
												<StyledTableRow>
														<LabelCell width={180}>Model</LabelCell>
														<DisabledTextField value={currentRow.model} width={300}/>
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell>Serial No</LabelCell>
														<DisabledTextField value={currentRow.serialNo} />
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell>Ward</LabelCell>
														<DisabledTextField value={currentRow.ward} />
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell>Bed</LabelCell>
														<DisabledTextField value={currentRow.bed} />
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell>Patient</LabelCell>
														<DisabledTextField value={currentRow.patientName} />
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell>Patient No</LabelCell>
														<DisabledTextField value={currentRow.patientNo} />
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell>Rental Start</LabelCell>
														<DisabledTextField value={currentRow.startDate} />
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell className={Boolean(details.cleaned) ? classes.default : classes.required}>
																Cleaned?
														</LabelCell>
														<StyledTableCell>
																<RadioGroup value={details.cleaned} onChange={(event) => onAnswered({ cleaned: event.target.value })}>
																		<FormControlLabel
																				value="Yes"
																				control={
																						<Radio
																								size="small"
																								className={Boolean(details.cleaned) ? classes.defaultRadio : classes.required}
																						/>
																				}
																				label={
																						<Typography variant="body2">
																								Yes
																						</Typography>
																				}
																		/>
																</RadioGroup>
														</StyledTableCell>
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell className={Boolean(details.pickup) ? classes.default : classes.required}>
																Immediate Pickup?
														</LabelCell>
														<StyledTableCell>
																<RadioGroup value={details.pickup} onChange={(event) => onAnswered({ pickup: event.target.value })}>
																		<FormControlLabel
																				value="Yes"
																				control={
																						<Radio
																								size="small"
																								className={Boolean(details.pickup) ? classes.default : classes.required}
																						/>
																				}
																				label={
																						<Typography variant="body2">
																								Yes
																						</Typography>
																				}
																		/>
																</RadioGroup>
														</StyledTableCell>
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell className={Boolean(details.biohazard) ? classes.default : classes.required}>
																Biohazard?
														</LabelCell>
														<StyledTableCell>
																<RadioGroup value={details.biohazard} onChange={(event) => onAnswered({ biohazard: event.target.value })} row>
																		<FormControlLabel
																				value="Yes"
																				control={
																						<Radio
																								size="small"
																								className={Boolean(details.biohazard) ? classes.default : classes.required}
																						/>
																				}
																				label={
																						<Typography variant="body2">
																								Yes
																						</Typography>
																				}
																		/>
																		<FormControlLabel
																				value="No"
																				control={
																						<Radio
																								size="small"
																								className={Boolean(details.biohazard) ? classes.default : classes.required}
																						/>
																				}
																				label={
																						<Typography variant="body2">
																								No
																						</Typography>
																				}
																		/>
																</RadioGroup>
														</StyledTableCell>
														<StyledTableCell colSpan={3} />
												</StyledTableRow>
												<StyledTableRow>
														<LabelCell className={details.biohazard === "No" ? classes.default : Boolean(details.notes) ? classes.default : classes.required}>
																Biohazard Notes
														</LabelCell>
														<StyledTableCell colspan={3}>
																<TextField
																		rows={4}
																		multiline
																		variant="outlined"
																		style={{ width: "100%" }}
																		value={details.notes}
																		onChange={(event) => onAnswered({ notes: event.target.value })}
																		className={details.biohazard === "No" ? classes.defaultBorder : Boolean(details.notes) ? classes.defaultBorder : classes.requiredBorder}
																/>
														</StyledTableCell>
														<StyledTableCell colSpan={1}/>
												</StyledTableRow>
										</TableBody>
								</Table>

								<Grid
										container
								>
										<Grid
												item
												xs={6}
										>
										<Button
												disabled={!completed}
														onClick={handleOkClicked}
														className={classes.okButton}
										>
														Ok
										</Button>
										</Grid>
										<Grid
												item
												xs={6}
										>
										<Button
												className={classes.cancelButton}
												onClick={handleCancelClicked}
										>
														Cancel
										</Button>
										</Grid>
										<ConfirmationDialog
												open={showConfirmation.declined}
												onSubmit={onOk}
												title={"STOP RENTAL CANCELLED"}
												showOkButton={true}
												okButtonText={"OK"}
												showCancelButton={false}
												cancelButtonText={""}
												variant={"info"}
												description={"Stop rental has been cancelled."}
										/>
								</Grid>
						</TableContainer>

						{waitingRows.length > 0 && (
								<TableContainer
										variant="outlined"
										component={Paper}
										className={classes.paper}
								>
										<Table
												size="small"
												className={classes.table}
										>
												<StyledTableHeader>
														<TitleRow colSpan={8}>
																Pending Stop Rental Orders
														</TitleRow>
														<TableRow>
																<StyledTableCell>Customer</StyledTableCell>
																<StyledTableCell>Ward</StyledTableCell>
																<StyledTableCell>Patient</StyledTableCell>
																<StyledTableCell>Patient No</StyledTableCell>
																<StyledTableCell>Rental Start</StyledTableCell>
																<StyledTableCell>Bed</StyledTableCell>
																<StyledTableCell>Model</StyledTableCell>
																<StyledTableCell>Serial No</StyledTableCell>
														</TableRow>
												</StyledTableHeader>

												<TableBody>
														{waitingRows.map(row => (
																<StyledTableRow>
																		<StyledTableCell>{row.customer}</StyledTableCell>
																		<StyledTableCell>{row.ward}</StyledTableCell>
																		<StyledTableCell>{row.patientName}</StyledTableCell>
																		<StyledTableCell>{row.patientNo}</StyledTableCell>
																		<StyledTableCell>{row.startDate}</StyledTableCell>
																		<StyledTableCell>{row.bed}</StyledTableCell>
																		<StyledTableCell>{row.model}</StyledTableCell>
																		<StyledTableCell>{row.serialNo}</StyledTableCell>
																</StyledTableRow>
														))}
												</TableBody>
										</Table>
								</TableContainer>
						)}

				</Popover>
		)
}

export default StopRental;