import { useContext, useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import { AppBar, Button, Container, FormControlLabel, Grid, IconButton, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { ConfirmationDialog } from "../../components/controls/ConfirmationDialog";
import { DeleteForeverTwoTone, LinkTwoTone } from "@material-ui/icons";
import uuid from "uuid-random";
import moment from 'moment';
import { api, Context } from '../../Portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Popover from "../../components/Popover";
import ProductCard from "../../components/ProductCard";
import datatableStylesAlt from "../../static/datatables-styles-alt";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: ".75rem",
        paddingLeft: ".75rem",
        paddingRight: ".75rem",
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    selectProductModal: {
        backgroundColor: '#DEEBF1',
    },
    categoryRow: {
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderTop: '1px solid #016895',
        borderBottom: '1px solid #016895',
        backgroundColor: 'transparent',
        height: '50px',
    },
    categoryList: {
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        fontSize: '15pt',
        paddingRight: '30px',
    },
    link: {
        color: '#016895',
        fontWeight: '700',
        fontSize: '11pt',
        paddingLeft: "1rem",
        paddingRight: "1rem",
        cursor: 'pointer',
        "&:link": {
            color: '#016895',
            textDecoration: "none",
        },
        "&:hover": {
            color: "#26A9E1",
            textDecoration: "none",
        },
        "&:visited": {
            color: '#016895',
            textDecoration: "none",
        },
        "&:active": {
            color: "#26A9E1",
            textDecoration: "none",
        },
    },
    linkActive: {
        color: '#26A9E1',
        fontWeight: '700',
        fontSize: '11pt',
        paddingLeft: "1rem",
        paddingRight: "1rem",
        "&:hover": {
            color: "#26A9E1",
            textDecoration: "none",
        },
    },
    activeCategory: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        marginBottom: '20px',
    },
    inactiveCategory: {
        display: 'flex',
        flexFlow: 'row wrap',
        width: '100%',
        marginBottom: '20px',
    },
    products: {
        display: "flex",
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: "100%",
    },
    left: {
        paddingTop: "2em",
        paddingBottom: "1em",
        height: "70vh",
        width: '70%',
        overflowY: "auto",
    },
    dividerColumn: {
        width: '5%',
    },
    right: {
        paddingTop: "2em",
        paddingBottom: "1em",
        width: '30%'
    },
    productItem: {
        paddingRight: '10px',
        paddingBottom: '10px',
    },
    list: {
        border: "0px solid " + theme.palette.primary.dark

    },
    buttonContainer: {
        padding: "0",
        width: '100%',
    },
    confirmButton: {
        backgroundColor: '#016895',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    cancelButton: {
        backgroundColor: '#26A9E1',
        color: '#FFFFFF',
        cursor: 'pointer',
        width: '100%',
        '&:hover': {
            backgroundColor: '#26A9E1',
            color: '#FFFFFF',
        }
    },
    deleteIcon: {
        color: '#016895',
        cursor: 'pointer',
        width: '20px',
    },
    categoryDivider: {
        width: '100%',
        marginBottom: '8px',
        color: '#FFFFFF',
        backgroundColor: '#016895',
        padding: '5px 0',
    },
}));

const initialState = {
    products: [],
    previousProducts: []
}

const url = "/api/v1/equipment";

const reducer = (state, action) => {
    switch (action.type) {
        case "add-product":
            return {
                products: [
                    ...state.products,
                    {
                        ...action.product,
                        serialNo: "",
                        onChange: (product, serialNo) => { product.serialNo = serialNo; }
                    }
                ]
            }

        case "remove-product":
            state.products.splice(action.index, 1);
            return {
                products: [...state.products]
            }
        case "reset-products":
            state.products = [...initialState.previousProducts];
            return {
                products: [...state.products]
            }
        case "set-products":
            if (action.products) {
                initialState.previousProducts = [...action.products];
                return {
                    products: [...action.products]
                }
            }
            return {
                products: []

				}
			default:
				return { products: [] }
    }
}

const SelectProducts = ({ columns, models, show, onExited, selected, ...props }) => {
    const [state, dispatch] = useContext(Context);
    const [localState, localDispatch] = useReducer(reducer, initialState)
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cells, setCells] = useState([]);
		const [activeCategory, setActiveCategory] = useState('');
		const [loaded, setLoaded] = useState(false);

    const classes = useStyles();

	useEffect(() => {
			if (!loaded) {
				setCells(buildCells(columns));
				setDialogOpen(false);
				setOpen(show);
				localDispatch({ type: "set-products", products: selected });
				setLoaded(true);
			}
    }, [columns, show, selected]);

    const onProductSelected = (model) => {
        const utcOffset = Math.ceil(moment().utcOffset() /60);
			api.get(state.host + `${url}/${model.MODEL}/delivery/${utcOffset}`, state.credentials)
				.then(({ data }) => {
					let line = {
						...model,
						REQBYDATE: data.date,
						REQBYTIME: data.time,
						MINREQBYDATE: data.date,
						REQSTARTDATE: data.startDate,
						REQSTARTTIME: data.startTime,
					}
					localDispatch({ type: "add-product", product: line });

				});
    }

    const removeProduct = (row, index) => {
        localDispatch({ type: "remove-product", index: index });
    }

    const resetProducts = async () => {
        if (localState.products.length !== initialState.previousProducts.length) {
            handleDialogClickOpen();
        } else {
            await localDispatch({ type: "reset-products" });
            setOpen(false);
        }
    }

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };

    const handleStayClick = () => {
        setDialogOpen(false);
    };
    const handleOkClick = () => {
        closeSelectProducts();
    }

    const closeSelectProducts = async () => {
        await localDispatch({ type: "reset-products" });
        setDialogOpen(false);
        setOpen(false);
    }

    const buildCells = (columns) => {
        let cells = [];
        cells.push({
            name: columns[2].caption,
            selector: columns[2].fieldID,
            compact: true,
            cell: (row, index) => {
                if (row.highEnd === "Yes") {
                    return (
                        <Tooltip
                            title={
                                <Typography
                                    variant="body2"
                                >
                                    Authorise
                                </Typography>
                            }
                        >
                            <div
                                style={{
                                    backgroundColor: "#B4E8D6",
                                    borderRadius: "50%",
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </Tooltip>
                    )
                }
                return null;
            },
            width: "12px",
        });
        cells.push({
            name: columns[0].caption,
            selector: columns[0].fieldID,
            sortable: true
        });
        cells.push({
            name: columns[1].caption,
            selector: columns[1].fieldID,
            sortable: true,
            wrap: true,
            width: '200px',
        });

        cells.push({
            title: "",
            sortable: false,
            compact: true,
            cell: (row, index) => (
                <div
                    className={classes.deleteIcon}
                >
                    <FontAwesomeIcon icon={faTimesCircle}
                        color="primary"
                        onClick={() => removeProduct(row, index)}
                    >
                        <DeleteForeverTwoTone />
                    </FontAwesomeIcon>
                </div>
            ),
            width: '20px',
        });

        return cells;
    }

    return (
        <Popover
            open={open}
            onExited={() => onExited(localState.products)}
            onEscapeKeyDown={() => onExited(localState.products)}
            showTitle={true}
            tall
            {...props}
        >
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                justify="flex-start"
                item
                xs={12}
                className={classes.selectProductModal}
            >
                <AppBar
                    position="static"
                    elevation={0}
                    className={classes.categoryRow}
                >
                    <Grid
                        className={classes.categoryList}
                    >
                        {models.map((categories, index) => {
                            if (index === 0 && activeCategory === '') { setActiveCategory(categories[0].category); }
                            return (
                                <div key={uuid()}>
                                    <Link
                                        className={classes.link}
                                        key={uuid()}
                                    >
                                        { index === 0 ? '' : '|'} 
                                    </Link>
                                    <Link
                                        href={`#${categories[0].category}`}
                                        onClick={(event) => { setActiveCategory(categories[0].category) }}
                                        className={activeCategory === categories[0].category ? classes.linkActive : classes.link}
                                        key={uuid()}
                                        >
                                        {categories[0].category}
                                    </Link>
                                </div>
                            )
                        })}
                            <Link
                                className={classes.link}
                                key={uuid()}
                            >
                                {'|'}
                            </Link>
                            <Link
                                onClick={() => resetProducts()}
                                className={classes.link}
                                key={uuid()}
                            >
                                CLOSE
                            </Link>
                    </Grid>
                </AppBar>

                <Container className={classes.products}>
                    <Grid
                        item
                        className={classes.left}
                    >
                        <Grid
                            container
                        >
                            {models.map((categories) => {
                                return (
                                    <Grid
                                        item
                                        key={uuid()}
                                        className={activeCategory == categories[0].category ? classes.activeCategory : classes.inactiveCategory}
                                    >
                                        <div
                                            className={classes.categoryDivider}
                                            id={`${categories[0].category}`}
                                            key={uuid()}
                                        >
                                            <FormControlLabel
                                                id={categories[0].category}
                                                control={<LinkTwoTone style={{ marginLeft: 12 }} />}
                                                labelPlacement="start"
                                                label={
                                                    <Typography
                                                    >
                                                        {categories[0].category}
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                        {categories.map((product, index) => {
                                            return (
                                                <Grid
                                                    key={uuid()}
                                                    item
                                                    xs={4}
                                                    className={classes.productItem}
                                                >
                                                    <ProductCard
                                                        product={product}
                                                        index={index}
                                                        onChange={onProductSelected}
                                                        key={uuid()}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                )
                            })}
                            <Grid
                                item
                                xs={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classes.dividerColumn}
                        />
                    <Grid
                        item
                        className={classes.right}
                    >
                        <Grid
                            container
                            direction="column nowrap"
                            className={classes.list}
                        >
                            <DataTable
                                columns={cells}
                                data={localState.products}
                                persistTableHead
                                noHeader={false}
                                title="PRODUCT SELECTION SUMMARY"
                                striped
                                customStyles={datatableStylesAlt}
                            />
                            <Grid
                                container
                                direction="row"
                                className={classes.buttonContainer}
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Button
                                        onClick={() => setOpen(false)}
                                        className={classes.confirmButton}
                                    >
                                            CONFIRM
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Button
                                        className={classes.cancelButton}
                                        onClick={() => resetProducts(initialState.products.length !== initialState.previousProducts.length)}
                                    >
                                            CANCEL
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>
            </Grid>
            <ConfirmationDialog
                open={dialogOpen}
                title={"UNSAVED CHANGES"}
                variant={"info"}
                showOkButton={true}
                okButtonText={"YES, I AGREE"}
                showCancelButton={true}
                cancelButtonText={"CANCEL"}
                description={"You have unsaved changes are you sure you want to close?"}
                onSubmit={handleOkClick}
                onCancel={handleStayClick}
            />
        </Popover>
    )
}

export default SelectProducts;