import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { StyledTableCell } from './table-styling';

const useStyles = makeStyles((theme) => ({
    width2: {
        width: "27.5%",
    },
    width4: {
        width:  "75%"        
    },
}));

const DisabledTextField = ({fourColumn, children, width,  ...props}) => {
    const classes = useStyles();

    return (
        <StyledTableCell width={width}>
            <TextField
                disabled
                variant="outlined"
                // className={fourColumn ? classes.width4 : classes.width2}
                fullWidth
                {...props}
            />
            {children}
        </StyledTableCell>
    )
}

export default DisabledTextField;