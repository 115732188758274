const datatableStyles = {
    header: {
        style: {
            backgroundColor: "#B4D7E8",
            color: "#212F41",
        }
    },
    subHeader: {
        style: {
            backgroundColor: "#B4D7E8",
            color: "#212F41",
        }
    },
    headRow: {
        style: {
            backgroundColor: "#B4D7E8",
        }
    },
    headCells: {
        style: {
            color: "#212F41",
        }
    }
}

export default datatableStyles;