import { useContext, useEffect, useState } from "react";
import { Grid, makeStyles, Slide, Card, List, ListItem, CardContent, ListItemIcon, Checkbox, ListItemText } from "@material-ui/core";

import { Context } from "../../Portal";
import SetCustomer from "../login/set-customer";

const useStyles = makeStyles((theme) => ({
    heading: {
        ...theme.title,
        marginLeft: -theme.spacing(2),
        width: `calc(100% + ${theme.spacing(4)}px)`,
        overflow: "hidden"
    },
    root: {
        display: 'flex',
        alignItems: "center",
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    card: {
        marginTop: theme.spacing(2)
    }

}));

const Profile = () => {
    const [state, dispatch] = useContext(Context);
    const [open, setOpen] = useState(true);
    const [wards, setWards] = useState([]);

    useEffect(() => {
        setWards(state.user.assignedWards.find(ward => ward.customerNumber === state.selectedCustomer.no).ward);
    }, [state.user, state.selectedCustomer]);

    let classes = useStyles();

    let { user } = state;

    return (
        <Slide in={open}>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Card variant="outlined" className={classes.card}>
                        <CardContent>
                            <Grid item xs={12} alignItems="stretch">
                            <SetCustomer show="true" onExit={() => {}} xs={8} />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card variant="outlined" className={classes.card}>
                        <CardContent>
                            <List>
                                {wards.map((ward) => {
                                    return (
                                        <ListItem
                                            key={ward.description}
                                            button
                                        >
                                            <ListItemText primary={ward.description} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Slide>
    )
}

export default Profile;