import { useContext, useEffect, useState } from "react";
import { api, Context } from "../Portal";

const customerConsignedstockUrl = "/api/v1/equipment/consigned-stock";

const useGetConsignmentStock = () => {
    const [state, dispatch] = useContext(Context);
    const [stock, setStock] = useState([]);

    useEffect(() => {
        if (state.loadConsignedStock && state.selectedCustomer) {
            api.get(state.host + customerConsignedstockUrl, state.credentials)
            .then(({ data }) => {
                setStock(data);
                dispatch({ type: "LOADED-CONSIGNED-STOCK" });
            })
        }
    }, [state.loadConsignedStock, state.selectedCustomer]);

    return stock;
}

export default useGetConsignmentStock;