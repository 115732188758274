import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse, Container, Grid } from '@material-ui/core';
import { ExpandMoreTwoTone } from '@material-ui/icons';

import { api, Context } from '../../Portal';
import Section from '../../components/controls/Section';
import DropdownField from '../../components/controls/DropdownField';
import { extractControl, buildDeliveryForm } from '../../components/controls/buildForm';
import ProductGrid from '../../components/controls/ProductGrid';
import FieldGroup from '../../components/controls/FieldGroup';
import Authorisation from './Authorisation';
import moment from 'moment';
import useCustomerInformation from '../../hooks/useCustomerInformation';
import Success from './Success';
import useRedirect from '../../hooks/useRedirect';

const additionalSectionUrl = "/views/v1/create-order/additional";
const url = "/api/v1/rentalorders";

const useStyles = makeStyles((theme) => ({
    delivery: {
        borderBottom: "0px solid " + theme.palette.primary.dark,
        borderLeft: "0px solid " + theme.palette.primary.dark,
        borderRight: "0px solid " + theme.palette.primary.dark,
        width: "100%",
        margin: "0",
        maxWidth: "100%",
        padding: "1rem",
        backgroundColor: '#F4F4F6',
        borderWidth: '0px',
    },
    deliveryBar: {
        ...theme.titleBar,
        border: "0px solid " + theme.palette.primary.dark,
        marginLeft: "4px",
        marginRight: "4px",
        backgroundColor: '#E3E4E6',
    },
    deliveryOpening: {
        ...theme.titleBar,
        borderTop: "0px solid " + theme.palette.primary.dark,
        borderLeft: "0px solid " + theme.palette.primary.dark,
        borderRight: "0px solid " + theme.palette.primary.dark,
        marginLeft: "4px",
        marginRight: "4px",
        backgroundColor: '#E3E4E6',
    },
    collapse: {
        width: "100%",
        maxWidth: "100%",
        marginLeft: "4px",
        marginRight: "4px",
    },
    defaultBorder: {
        "& fieldset": {
            border: "1px solid rgba(1, 104, 149, .5)", 
        },
        "& input:disabled fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(1, 104, 149, 1)",
        },
        '& hover': {
            border: "1px solid rgba(1, 104, 149, .5)",
        },
    },
    disabledBorder: {
        "& fieldset": {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
        "& label": {
            color: "rgba(0, 0, 0, .5)",
        },
        '& hover': {
            border: "1px solid rgba(0, 0, 0, .38)",
        },
    }
}));

const unifyShippingAddress = (customer, shipTo) => {
    if (!shipTo || Object.entries(shipTo).length === 0) {
        return {
            name: customer.name,
            address: customer.address,
            address2: "",
            city: customer.city,
            postCode: customer.postCode
        }
    }

    return {
        name: shipTo.name,
        address: shipTo.address,
        address2: "",
        city: shipTo.city,
        postCode: shipTo.postCode
    }
}

const transformModels = (models) => {
	if (models) {
		return models.map(categories => (
			categories.map(product => ({
				category: product.category,
				MODEL: product.model,
				MODELDESCRIPTION: product.description,
				SHORTDESCRIPTION: product.shortDescription,
				REQBYDATE: "",
				REQBYTIME: "",
				image: product.image,
				highEnd: product.highEnd
			}))
		));
	}
}

let form = {};

const CreateOrder = () => {
    const [state, dispatch] = useContext(Context);
    const [information, costCentreRequired, customers, wards, costCentres, onCustomerSelected, onWardSelected, onCostCentreSelected] = useCustomerInformation();

    const [communityDelivery, setCommunityDelivery] = useState(false);
    const [row1, setRow1] = useState({ height: 0, groups: [] });
    const [row2, setRow2] = useState({ height: 0, groups: [] });
    const [delivery, setDelivery] = useState([]);
    const [comment, setComment] = useState([]);
    const [contactDetails, setContactDetails] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [models, setModels] = useState([]);

    const [postCodes, setPostCodes] = useState([]);

    const [productsOrdered, setProductsOrdered] = useState([]);
    const [showAddress, setShowAddress] = useState(false);
    const [shippingAddress, setShippingAddress] = useState({});

    const [showApproval, setShowApproval] = useState(false);
    const [autoApproval, setAutoApproval] = useState(false);
    const [staff, setStaff] = useState({ authorisers: [], requestors: [], contacts: [] });
    const [contact, setContact] = useState({
        name: '',
        surname: '',
        firstNames: ''
    });
    const [requestor, setRequestor] = useState({
        name: '',
        surname: '',
        firstNames: ''
    });
    const [showSuccess, setShowSuccess] = useState(false);

    const formMethods = useForm();
    const { handleSubmit, reset } = formMethods;
    const { redirectToHome } = useRedirect();

    const classes = useStyles();

    useEffect(() => {
        form = {};
        return () => {
            setDefaults();
            dispatch({ type: "CLEAR-CUSTOMER-SELECTION" })
        }
    }, []);

    useEffect(() => {
        if (!Boolean(information.WARD)) {
            setDefaults();
        }
    }, [information.WARD]);

    useEffect(() => {
        if (Boolean(state.selectedWard)) {
            getFormData();
            dispatch({ type: "LOAD-CONSIGNED-STOCK" });
        }

        let wardPermissions = state.user
            .permissions
            .find(permission => permission.customerNumber === state.selectedCustomer && permission.ward === state.selectedWard);

        setDefaults();

        if (wardPermissions && wardPermissions.permissionSet.includes("Authorize Rental Transaction")) {
            setAutoApproval(true);
            return;
        }

        if (state.customerDetail?.rentalAuthorizationType === "No Authorization") {
            setAutoApproval(true);
            return;
        }

        setAutoApproval(false);
    }, [state.selectedWard]);

    useEffect(() => {
				if (Boolean(state.consignmentProductsSelected)) {
            if (state.consignmentProductsSelected && state.selectedCustomer) {
                let products = state.consignmentProductsSelected.products;
                let ward = products[0].ward;
                let singleWard = true;

                for (let index = 1; index < products.length; index++) {
                    if (products[index].ward !== ward) {
                        singleWard = false;
                        break;
                    }
                }

                if (singleWard) {
                    onCustomerSelected({ target: { value: state.consignmentProductsSelected.customer } }, ward);
                    return;
                }
            }
						dispatch({ type: "CONSIGNMENT-PRODUCTS-SELECTED" });
            onCustomerSelected({ target: { value: state.consignmentProductsSelected.customer } });
        }
    }, [state.consignmentProductsSelected]);

    const setDefaults = () => {
        setCommunityDelivery(false);
        setRow1({ height: 0, groups: [] });
        setRow2({ height: 0, groups: [] });
        setDelivery([]);
        setComment([]);
        setContactDetails([]);
        setModels([]);
        setPostCodes([]);
        //setProductsOrdered([]);- don't set so that it does not clear the list
        setShowAddress(false);
        setShippingAddress({});
        setGridColumns([]);
        setStaff({ authorisers: [], requestors: [], contacts: [] });
        setContact({
            name: '',
            surname: '',
            firstNames: ''
        });
        setRequestor({
            name: '',
            surname: '',
            firstNames: ''
        });
    }

    const getFormData = () => {
        api.get(state.host + additionalSectionUrl, state.credentials)
            .then(({ data }) => {
                setShippingAddress(unifyShippingAddress(state.customerDetail, state.selectedShipTo));

                setCommunityDelivery(data.communityDelivery);
                setStaff(data.staff);
                setRow1({ height: data.row1Height, groups: data.groupsRow1 });
                setRow2({ height: data.row2Height, groups: data.groupsRow2 });
                setDelivery(data.delivery);
                setComment(data.comment);
                setContactDetails(data.contactDetails);
                setModels(transformModels(data.models));
                setPostCodes(data.postCodes.map(({ postCode1, city, county }) => ({ value: postCode1, description: `${postCode1} ${city} ${county}` })));
                setShowAddress(data.communityDelivery);
                setGridColumns(data.grid);
            });
    }

    const onProductsSelected = (selectedProducts) => {
        setProductsOrdered(selectedProducts);
    }

    const onProductsUpdated = (updatedProducts) => {
        setProductsOrdered(updatedProducts);
    }

    const handleAddressCollapse = () => {
        setShowAddress(!showAddress);
    }

    const onApproval = (status, people) => {
        setShowApproval(false);
        if (status === "cancelled") {
            return false;
        }

        let requestApproved = autoApproval;

        if (status === "not approved") {
            requestApproved = false;
        }

        if (status === "approved") {
            requestApproved = true;
        }

        let {CUSTOMER, WARD, COSTCENTRE, REQBYDATE, REQBYTIME, ...rest} = form;
        const utcOffset = Math.ceil(moment().utcOffset() / 60);
        let data = {
            USEREMAIL: state.user.username,
            CUSTOMER: CUSTOMER,
            SHIPTOCODE: state.selectedShipTo?.shipToCode ?? "",
            WARD: WARD,
            COSTCENTRE: COSTCENTRE,
            CONTACTFIRSTNAMES: contact.firstNames,
            CONTACTSURNAME: contact.surname,
            REQNURSEFIRSTNAMES: requestor.firstNames,
            REQNURSESURNAME: requestor.surname,
            APPROVER: people?.APPROVER ?? state.user.username,
            UTCOFFSET: utcOffset,
            ...rest,
            PRODUCTS: productsOrdered.map((product) => {
                let { category, highEnd, serialNo, onChange, onUpdate, image, ...rest } = product;
                return {
                    ...rest,
                    SERIALNO: serialNo
                }
            }),
            APPROVED: requestApproved,
        };

        api.post(state.host + url, data, state.credentials)
            .then(({ data }) => {
                if (Boolean(data.errorMessage)) {
                    dispatch({ type: "SHOW-ERROR", payload: { heading: "Creating Rental Order", message: data.errorMessage } });
                    return;
                }
                setProductsOrdered([]);
                setContact("");
                setRequestor("");
                reset();
            });
    }

    const onSubmit = (data) => {
        if (contact.name === "" || requestor.name === "") {
            dispatch({ type: "SHOW-ERROR", payload: { heading: "Contact Information", message: "Contact information is not complete" } });
            return;
        }
        if (productsOrdered.length === 0) {
            dispatch({ type: "SHOW-ERROR", payload: { heading: "No equipment", message: "Please select equipment for this rental order" } });
            return;
        }

        form = data;

        if (autoApproval) {
            onApproval("auto-approve");
            return;
        }

        let highEndOrdered = Boolean(productsOrdered.find(product => product.highEnd === "Yes"));

        if (highEndOrdered) {
            setShowApproval(true);
            return;
        }

        if (state.customerDetail.rentalAuthorizationType === "All Authorization") {
            setShowApproval(true);
            return;
        }

        onApproval("auto-approve")
    }

    const onSuccess = (action) => {
        setShowSuccess(false);

        if (action === "new") {
            setProductsOrdered([]);
            setContact("");
            setRequestor("");
            reset();
            return;
        }

        redirectToHome();
    }

    return (
        <FormProvider {...formMethods}>
            <form>
                <Grid
                    container
                    direction="row"
                    item
                    spacing={1}
                >
                    <FieldGroup
                        fields={[{ columnCaption: "Customer Information" }]}
                        height={row1.height}
                        classes={classes}
                    >
                        <DropdownField
                            id="select-customer"
                            label="Customer"
                            onChange={onCustomerSelected}
                            name="CUSTOMER"
                            required
                            value={information.CUSTOMER}
                            options={customers.map(customer => ({ value: customer.no, description: customer.name }))}
                            xs={12}
                            className={classes.defaultBorder}
                        />
                        <DropdownField
                            id="select-ward"
                            label="Ward"
                            onChange={onWardSelected}
                            name="WARD"
                            required
                            value={information.WARD}
                            options={wards.map(ward => ({ value: ward.description, description: ward.description }))}
                            xs={12}
                            className={classes.defaultBorder}
                        />
                        {(costCentreRequired || (costCentres.length > 0 && !costCentreRequired)) && (
                            <DropdownField
                                id="select-costcentre"
                                label="Cost Center"
                                onChange={onCostCentreSelected}
                                name="COSTCENTRE"
                                required={costCentreRequired}
                                value={information.COSTCENTRE}
                                options={costCentres.map(costCentre => ({ value: costCentre.costCentreCode, description: costCentre.costCentreCode }))}
                                xs={12}
                                className={classes.defaultBorder}
                            />
                        )}
                    </FieldGroup>

					{row1.groups && row1.groups.length > 0 && row1.groups.map(group => (
                        <FieldGroup
                            fields={group}
                            height={row1.height}
                            staff={staff}
                            contact={contact}
                            requestor={requestor}
                            onContactChanged={setContact}
                            onRequestorChanged={setRequestor}
                            classes={classes}

                        />
                    ))}
                    {row2.groups && row2.groups.length > 0 && row2.groups.map(group => (
                        <FieldGroup
                            fields={group}
                            height={row2.height}
                            staff={staff}
                            contact={contact}
                            requestor={requestor}
                            onContactChanged={setContact}
                            onRequestorChanged={setRequestor}
                            classes={classes}
                        />
                    ))}

						{gridColumns.length > 0 && (
							<ProductGrid consignmentProductsSelected={state.consignmentProductsSelected} columns={gridColumns} data={productsOrdered} models={models} onChange={onProductsSelected} onUpdate={onProductsUpdated} editable={true} />
                    )}

                    {delivery.length > 0 && (
                        <Section
                            title={delivery[0].caption}
                            onClick={handleAddressCollapse}
                            iconRight={<ExpandMoreTwoTone />}
                            className={showAddress ? classes.deliveryOpening : classes.deliveryBar}
                        >
                            <Collapse
                                in={showAddress}
                                className={classes.collapse}
                            // todo: use onExit to set the section border-bottom to show
                            >
                                <Container
                                    className={classes.delivery}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                    >
                                        {delivery.map(field => (buildDeliveryForm(field, communityDelivery, postCodes, shippingAddress, classes)))}

                                        {contactDetails.map(field => (extractControl(field, classes)))}
                                    </Grid>
                                </Container>
                            </Collapse>
                        </Section>
                    )}

                    {comment.length > 0 && (
                        <FieldGroup
                            fields={function () {
                                let field = comment[1];
                                field.caption = "";
                                field.columnCaption = "Comments";
                                return [field];
                            }()}
                            xs={12}
                            style={{ marginTop: 5 }}
                            classes={classes}
                        />
                    )}

                    {row1.groups.length > 0 && (
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Create Rental Order
                            </Button>

                            <Authorisation
                                show={showApproval}
                                onExited={onApproval}
                                staff={staff}
                                contact={contact}
                                requestor={requestor}
                            />

                            <Success
                                show={showSuccess}
                                onExited={onSuccess}
                            />

                        </Grid>
                    )}
                </Grid>
            </form >
        </FormProvider >
    )
}

export default CreateOrder;