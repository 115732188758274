import "date-fns";
import DateFNSUtils from "@date-io/date-fns";

import { Chip, TableCell, Typography } from "@material-ui/core";
import uuid from "uuid-random";

import Section from "./Section";
import BigtextField from "./BigtextField";
import DropdownField from "./DropdownField";
import InputField from "./InputField";
import InputFieldAlt from "./InputFieldAlt";
import NameSelectEntry from "./NameSelectEntry";
import SerialNumber from "./SerialNumber";

export const extractControl = ({ fieldType, type, editable, ...field }, classes, xs) => {
    let size = xs ? { xs: xs } : {};
    let disabled = editable === "No";
    

    switch (type) {
        case "Group":
            return (
                <Section title={field.caption} />
            )

        case "Field":
        case "GridField":
            let required = field.mandatory === "Yes";

            switch (fieldType) {
                case "Text":
                    return (
                        <InputField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            type="text"
                            value={field.fieldValueAsText}
                            disabled={disabled}
                            maxlength={field.length}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                            {...size}
                        />
                    )

                case "Date":
                    let dateValue = field.fieldValueAsText;
                    if (!disabled && !Boolean(dateValue)) {
                        var date = new Date();
                        dateValue = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
                            .toISOString()
                            .split("T")[0];
                    }

                    return (
                        <InputField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            type="date"
                            defaultValue={dateValue}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                            value={dateValue}
                            disabled={disabled}
                            maxlength={field.length}
                            {...size}
                        />
                    )

                case "Integer":
                    return (
                        <InputField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            type="number"
                            value={field.fieldValueAsText}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                            disabled={disabled}
                            maxlength={field.length}
                            {...size}
                        />
                    )

                case "Time":
                    return (
                        <InputField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            type="time"
                            value={field.fieldValueAsText}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                            disabled={disabled}
                            maxlength={field.length}
                            {...size}
                        />
                    )

                case "Bigtext":
                    return (
                        <BigtextField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            xs={6}
                            rows={3}
                            maxlength={field.length}
                            value={field.fieldValueAsText}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                            disabled={disabled}
                        />
                    )

                case "Code":
                case "Option":
                    return (
                        <DropdownField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            value={field.fieldValueAsText}
                            disabled={disabled}
                            options={field.optionString
                                .split("|")
                                .map(item => ({ value: item, description: item }))
                            }
                            {...size}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                        />
                    )

                case "Person":
                    return (
                        <InputField
                            name={field.fieldID}
                            label={field.caption}
                            required={required}
                            type="text"
                            value={field.fieldValueAsText}
                            disabled={disabled}
                            {...size}
                            maxlength={field.length}
                            className={disabled ? classes.disabledBorder : classes.defaultBorder}
                        />
                    )
            }
    }
}

export const buildRentalForm = (field, xs, staff, contact, onContactChange, requestor, onRequestorChange, classes) => {
    let required = field.mandatory === "Yes";
    let disabled = field.editable === "No";
    let size = xs ? { xs: xs } : {};
    

    switch (field.fieldID) {
        case "CONTACTPERSON":
            if (disabled) {
                return extractControl(field, classes, xs);
            }

            return (
                <NameSelectEntry
                    people={staff.contacts}
                    label={field.caption}
                    name={field.fieldID}
                    required={required}
                    value={contact}
                    onChange={onContactChange}
                    key={uuid()}
                    className={classes.defaultBorder}
                    {...size}
                />
            );
        case "REQNURSE":
            if (disabled) {
                return extractControl(field, classes, xs);
            }

            return (
                <NameSelectEntry
                    people={staff.requestors}
                    label={field.caption}
                    name={field.fieldID}
                    required={required}
                    value={requestor}
                    onChange={onRequestorChange}
                    key={uuid()}
                    className={classes.defaultBorder}
                    {...size}
                />
            );

        default:
            return extractControl(field, classes, xs);
    }
}

export const buildDeliveryForm = (field, communityDelivery, postCodes, shipTo, classes) => {
    let required = field.mandatory === "Yes";
    let xs = {};
    switch (field.caption) {
        case "Delivery Information":
            return null;

        case "Delivery Name":
            if (communityDelivery) {
                return extractControl(field, classes, xs);
            }

            return (
                <InputField
                    name={field.fieldID}
                    label={field.caption}
                    type="text"
                    disabled
                    maxlength={field.length}
                    value={shipTo?.name ?? field.fieldValueAsText}
                    className={classes.disabledBorder}
                />
            )

        case "Delivery Address":
            if (communityDelivery) {
                return extractControl(field, classes, xs);
            }

            return (
                <InputField
                    name={field.fieldID}
                    label={field.caption}
                    type="text"
                    disabled
                    maxlength={field.length}
                    value={shipTo?.address ?? field.fieldValueAsText}
                    className={classes.disabledBorder}
                />
            )

        case "Delivery Address2":
            if (communityDelivery) {
                return extractControl(field, classes, xs);
            }

            return (
                <InputField
                    name={field.fieldID}
                    label={field.caption}
                    type="text"
                    disabled
                    maxlength={field.length}
                    value={shipTo?.address2 ?? field.fieldValueAsText}
                    className={classes.disabledBorder}
                />
            )

        case "Delivery City":
            if (communityDelivery) {
                return extractControl(field, classes, xs);
            }

            return (
                <InputField
                    name={field.fieldID}
                    label={field.caption}
                    type="text"
                    disabled
                    maxlength={field.length}
                    value={shipTo?.city ?? field.fieldValueAsText}
                    className={classes.disabledBorder}
                />
            )

        case "Delivery Post Code":
            if (communityDelivery) {
                return (
                    <DropdownField
                        label={field.caption}
                        name={field.fieldID}
                        required={required}
                        options={postCodes}
                        className={classes.defaultBorder}
                    />
                )
            }

            return (
                <InputField
                    name={field.fieldID}
                    label={field.caption}
                    type="text"
                    disabled
                    maxlength={field.length}
                    value={shipTo?.postCode ?? field.fieldValueAsText}
                    className={classes.disabledBorder}
                />
            )

        case "Delivery Service Zone":
            return null;

        default:
            return extractControl(field, classes, xs);
    }

}

export const buildProductRow = (field, data, serialNumbers, editable, transfer, onUpdate) => {
    let required = field.mandatory === "Yes";
    switch (field.fieldID) {
        case "MODEL":
            return (
                <TableCell>
                    <Typography variant="body2">
                        {data.MODEL}
                    </Typography>
                </TableCell>
            )
        case "MODELDESCRIPTION":
            return (
                <TableCell>
                    <Typography variant="body2">
                        {data.MODELDESCRIPTION}
                    </Typography>
                </TableCell>
            )
        case "highEnd":
            return (
                <TableCell padding="none" align="center">
                    {data.highEnd === "Yes" && (
                        <Chip
                            label="Authorise"
                            size="small"
                            style={{ backgroundColor: "#B4E8D6", color: "#216B51" }}
                        />
                    )}
                </TableCell>
            )
        case "serialNo":
            return (
                <TableCell
                    width={350}
                >
                { Boolean(transfer) && (
                        <Typography variant="body2">
                            {data.serialNo}
                        </Typography>
                    )
                }
                {!Boolean(transfer) && (

                    editable && (
                        <SerialNumber
                            field={field}
                            product={data}
                            serialNumbers={serialNumbers}
                            required={required}
                            onUpdate={onUpdate}
                        />
                    )
                )
                }
                {!Boolean(transfer) && (
                    !editable && (
                    <SerialNumber
                        field={field}
                        product={data}
                        serialNumbers={serialNumbers}
                        required={required}
                        disabled
                        onUpdate={onUpdate}
                        />
                        )
                    )
                }
                </TableCell>
            )

        case "REQBYDATE":
            return (
                <TableCell
                    width={200}
                >
                    {editable &&
                        <InputFieldAlt
                            name={field.fieldID}
                            required={required}
                            xs={12}
                            type="date"
                            guid={data.guid}
                            model={data.MODEL}
                            min={data.MINREQBYDATE}
                            defaultValue={data.REQBYDATE}
                            passedValue={data.REQBYDATE}
                            onUpdate={onUpdate}
                            style={{ border: "0px" }}
                        />
                    }
                    {!editable &&
                        <InputFieldAlt
                            name={field.fieldID}
                            required={required}
                            xs={12}
                            type="date"
                            guid={data.guid}
                            model={data.MODEL}
                            min={data.iREQBYDATE}
                            defaultValue={data.REQBYDATE}
                            passedValue={data.REQBYDATE}
                            onUpdate={onUpdate}
                            disabled
                            style={{ border: "0px" }}
                        />
                    }
                </TableCell>
            )
        case "REQBYTIME":
            return (
                <TableCell
                    width={200}
                >
                    {editable &&
                        <InputFieldAlt
                            name={field.fieldID}
                            disabled
                            required={required}
                            type="time"
                            xs={12}
                            defaultValue={data.REQBYTIME}
                            value={data.REQBYTIME}
                            style={{ border: "0px" }}
                        />
                    }
                    {!editable &&
                        <InputFieldAlt
                            name={field.fieldID}
                            disabled
                            required={required}
                            type="time"
                            xs={12}
                            disabled
                            defaultValue={data.REQBYTIME}
                            value={data.REQBYTIME}
                            style={{ border: "0px" }}
                        />
                    }
                </TableCell>
            )
    }
}

export default extractControl;