import { useContext, useEffect, useState } from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, makeStyles } from "@material-ui/core";
import { AllInclusive, DeleteForeverTwoTone } from "@material-ui/icons";
import uuid from "uuid-random";
import { buildProductRow } from "./buildForm";
import SelectProducts from "./select-products";
import useGetConsignmentStock from '../../hooks/useGetConsignmentStock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import { api, Context } from '../../Portal';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: "4px 3px 8px 3px"
    },
    table: {
        border: "0px solid " + theme.palette.primary.dark,
    },
    titleBar: {
        ...theme.titleBar,
        fontSize: "10pt",
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: '#016895',
        border: '1px solid #016895',
    },
    subTitleBar: {
        fontSize: "10pt",
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: '#EDEDEF',
    },
    button: {
        marginLeft: "5px",
    },
    deleteIcon: {
        color: '#016895',
        paddingLeft: '40px',
        cursor: 'pointer',
    }
}));

const url = "/api/v1/equipment";



const onProductSelected = (model) => {
    
}

const buildColumns = (columns) => {
    let cells = [...columns];

    cells.splice(2, 0,
        {
            caption: "",
            fieldID: "highEnd",
            no: "98"
        },
        {
            caption: "Consignment Serial No",
            fieldID: "serialNo",
            no: "99",
        }
    );

    return cells;
}

const ProductGrid = ({ consignmentProductsSelected, columns, data, models, onChange, onUpdate, editable, transfer, ...props }) => {
    const [state, dispatch] = useContext(Context);
		const [productData, setProductData] = useState([]);
		const [consignmentProducts, setConsignmentProducts] = useState([]);
    const [openProductSelection, setOpenProductSelection] = useState(false);
    const [productsSelected, setProductsSelected] = useState([]);
    const [cells, setCells] = useState([]);
    const stock = useGetConsignmentStock();

    const classes = useStyles();

	useEffect(() => {
				if (consignmentProductsSelected) {
					state.consignmentProductsSelected = consignmentProductsSelected;
				}
        setProductData(data);
        setCells(buildColumns(columns))
    }, [data, columns, consignmentProductsSelected, state]);

		useEffect(() => {
			if (!Boolean(state.consignmentProductsSelected)) {
				return;
			}

			getProducts(state.consignmentProductsSelected.products)
				.then((products) => {
					setProductData(products);
					onChange(products);
					dispatch({ type: "CLEAR-CONSIGNMENT-PRODUCTS" });
				});
		}, [state.consignmentProductsSelected])

    const getProducts = async (products) => {
        return await Promise.all(products
            .map(async (product) => {
                return await transformConsignedStock(product, models)
            }
        ));
    }

    const transformConsignedStock = async (item, models) => {

        let model = models.map(categories => (categories.find(x => x.MODEL === item.model)))
            .find(x => Boolean(x));

        const utcOffset = Math.ceil(moment().utcOffset() / 60);
        return await api.get(state.host + `${url}/${model.MODEL}/delivery/${utcOffset}`, state.credentials)
            .then(({ data }) => {
                model = {
                    ...model,
                    REQBYDATE: data.date,
                    REQBYTIME: data.time,
                    iREQBYDATE: data.date,
                    iREQBYTIME: data.time,
										MINREQBYDATE: item.serialNumber ? data.startDate : data.date,
										REQSTARTDATE: data.startDate,
										REQSTARTTIME: data.startTime,
                    guid: uuid(),
                }
                return {
                    ...model,
                    serialNo: item.serialNumber,
                    storeCode: item.storeCode,
                    onChange: (product, serialNo, storeCode, guid) => { product.serialNo = serialNo; product.storeCode = storeCode; product.guid = guid; }
                }
            });
    }

    const removeProduct = (product) => {
        let products = [...productData];

        let found = products.findIndex(item => item.MODEL === product.MODEL);
        if (found >= 0) {
            products.splice(found, 1);
        }

        setProductData(products);
        onChange(products);
    }

    const onProductSelectionConfirmation = (selectedProducts) => {
        // TODO: add action parameter for "cancel" to not add selectedProducts - or copy from productsSelected as set in handleAddProducts
        selectedProducts.map((product) => {
            if (!product.iREQBYDATE) {
                product.iREQBYDATE = product.REQBYDATE;
            }
            if (!product.iREQBYTIME) {
                product.iREQBYTIME = product.REQBYTIME;
            }
            if (!product.guid) {
                product.guid = uuid();
            }
        });
        setProductData(selectedProducts);
        setOpenProductSelection(false);

        onChange(selectedProducts);
    }

    const handleAddProducts = () => {
        setProductsSelected([...productData]);
        setOpenProductSelection(true);
    }

    const handleProductUpdated = (product) => {
		window.event.preventDefault();
		let found = {}
		let products = [...productData];
		if (product.currentTarget) {
			found = products.findIndex(item => item.guid === product.currentTarget.attributes.guid.value);
			if (found >= 0) {
				if (product.currentTarget.value !== products[found].iREQBYDATE) {
					products[found].REQBYTIME = '10:00';
					products[found].REQBYDATE = product.currentTarget.value;
				} else {
					products[found].REQBYDATE = product.currentTarget.value;
					products[found].REQBYTIME = products[found].iREQBYTIME;
				}
			}
		} else {
			found = products.findIndex(item => item.guid === product.guid);
			products[found] = product;
		}
		
		setProductData(products);
		onUpdate(productData);
    }

    return (
        <>
            {openProductSelection && (
                <SelectProducts
                    columns={cells}
                    models={models}
                    show={openProductSelection}
                    onExited={onProductSelectionConfirmation}
                    selected={productData}
                />
            )}

            <TableContainer
                variant="outlined"
                component={Paper}
                className={classes.paper}
            >
                <Table
                    stickyHeader
                    size="small"
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                padding="none"
                                className={classes.titleBar}
                                colSpan={10}
                            >
                                    <Button
                                    onClick={handleAddProducts}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={classes.button}
                                    disabled={!editable}
                                    >
                                        Select Products
                                    </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {cells.map((cell, index) => {
                                return (
                                    <TableCell
                                        key={cell.no}
                                        className={classes.subTitleBar}
                                    >
                                        {cell.caption}
                                    </TableCell>
                                )
                            })}
                            <TableCell
                                className={classes.subTitleBar}
                            >
                                Remove
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productData.map((product, index) => {
                            return (
                                <TableRow
                                    key={`row-${product.guid}`}
                                >
                                    {cells.map((cell) => {
                                        return (
                                            buildProductRow(cell, product, stock[product.MODEL], editable, transfer, handleProductUpdated)
                                        )
                                    })}
                                    <TableCell
                                        className={classes.deleteIcon}
                                    >
                                        {editable &&
                                            <FontAwesomeIcon icon={faTimesCircle}
                                                color="primary"
                                                onClick={() => removeProduct(product)}
                                            >
                                                <DeleteForeverTwoTone />
                                            </FontAwesomeIcon>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ProductGrid;