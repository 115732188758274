import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '../../components/Popover';
import { Button, Grid, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: "2em",
    },
    actions: {
        marginTop: "2em"
    }
}));

const Success = ({ show, onExited, ...props }) => {
    const [open, setOpen] = useState(false);
    const [exitState, setExitState] = useState("");

    const classes = useStyles();

    useEffect(() => {
        setOpen(show);

    }, [show]);

    const handleCreateAnother = () => {
        setExitState("new");
        setOpen(false);
    }

    const handleReturnHome = () => {
        setExitState("dashboard");
        setOpen(false);
    }

    return (
        <Popover
            open={open}
            showTitle={true}
            onExited={() => onExited(exitState)}
            ignoreWidth
        >
            <Typography
                component="p"
            >
                Rental Order has been created successfully.
            </Typography>
            <Typography
                component="p"
                style={{ marginTop: "2em" }}
            >
                What would you like to do next?
            </Typography>

            <Grid
                className={classes.actions}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateAnother}
                    className={classes.button}
                >
                    Create another
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReturnHome}
                >
                    Return to dashboard
                </Button>
            </Grid>
        </Popover>
    )
}

export default Success;